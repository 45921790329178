import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Button from '../../../../components/button';
import { loadLabels, editLabel, toggleEditLabel } from '../../projectsSlice';
import EditLabel from './editLabel';
import LabelItem from './labelItem';

const LabelSettings = () => {
    const { t } = useTranslation();
    const labelsList = useSelector(loadLabels);
    const editingLabel = useSelector(editLabel);
    const [addNew, setAddNew] = useState(false);
    const emptyLabel = { name: "", color: "#34c3eb" };
    return (<>
        <div>
            <p>
                {t('projects.projectsLabels')}
            </p>
            {labelsList && labelsList.map(label => label.guid != null && !label.isFixed &&
                <div>
                    {editingLabel?.guid == label.guid ? <EditLabel label={label}/> :  <LabelItem label={label } />}
                </div>
            )}
            {editingLabel && !editingLabel.guid && <EditLabel label={editingLabel} />}
            <Button id="addLabelButton" type="add" help={t('projects.addLabel')} action={() => toggleEditLabel(emptyLabel) } />
        </div>
    </>);
}
export default LabelSettings;