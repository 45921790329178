import * as React from 'react';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Input } from 'reactstrap';
import Button from '../../../components/button';
import CheckEnter from '../../../functions/controls';
import { formatDateFromShortDate } from '../../../functions/functions';
import { saveEvent } from '../../calendar/calendarSlice';
import { setMessage } from '../../common/commonSlice';
import { saveTask, toggleEditTask, toggleExtendedEditTask } from '../tasksSlice';

export default function EditTask(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isCalendar = props.isCalendar;
    var [task, setTask] = useState(props.task);
    var isAllowSave = true;

    const saveEditTask = async () => {
        if (!task.name) {
            dispatch(setMessage({
                message: isCalendar ? t('tasks.warningEmptyEvent') : t('tasks.warningEmtyTask'),
                status: 2, isVisible: true
            }));
            return;
        }
        if (!isAllowSave) {
            return;
        }
        isAllowSave = false;
        if (isCalendar) {
            var event = {
                name: task.name,
                taskGuid: task.guid ? task.guid : null,
                date: formatDateFromShortDate(task.date)
            };
            await dispatch(saveEvent(event));
        }
        else {
            await dispatch(saveTask(task));
        }
        setTask({ ...task, name: "" });
    };
    const cancelEditTask = () => {
        setTask({ ...task, name: "" });
        dispatch(toggleEditTask(task));
    };

    var saveButton = <Button guid={task ? task.guid ? task.guid : task.projectGuid : props.task.projectGuid} type="ok" action={saveEditTask} help={t('controls.modal.save')}
        className="small-button" />;
    return (
        <div className="task-item task-edit white-background" >
            <Input value={task.name} className="task-edit-name white-background" type="text" onChange={event => setTask({ ...task, name: event.target.value })}
                onKeyPress={event => CheckEnter(event, saveEditTask)} autoFocus={props.task.guid ? true : false}
            />
            {task.guid ? <div className="action-button-group">
                {saveButton}
                <Button guid={task.guid ? task.guid : task.projectGuid} type="cancel" action={cancelEditTask} help={t('controls.modal.cancel')}
                    className="small-button" />
            </div> :
                <>
                    {saveButton}
                </>}
        </div>
    );
}

//<Button key={"extendedTaskSettings_" + task.guid} id={"extendedTaskSettings_" + task.guid} params={task} type="settings"
//    action={toggleExtendedEditTask} help={t('tasks.taskExtendedEdit')} />