import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import isEmpty from '../../functions/functions';

export const getCurrentUser = createAsyncThunk(
    'user/getCurrentUser',
    async () => {
        const data = await fetch('/Base/GetCurrentUser',
            {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    'Accept': 'application/json',
                },
            });
        const json = await data.json();
        return json;
    }
);

export const getCurrentUserData = createAsyncThunk(
    'user/getCurrentUserData',
    async () => {
        const data = await fetch('/Base/getCurrentUserData',
            {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    'Accept': 'application/json',
                },
            });
        const json = await data.json();
        return json;
    }
);

export const getUserSettings = createAsyncThunk(
    'user/getUserSettings',
    async () => {
        const data = await fetch('/UserData/GetUserSettings',
            {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    'Accept': 'application/json',
                },
            });
        const json = await data.json();
        return json;
    }
);

export const saveUser = createAsyncThunk(
    'user/saveUser',
    async (user: any) => {
        console.log(user);
        const data = await fetch('/UserData/SaveUser',
            {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    'Accept': 'application/json',
                },
                body: JSON.stringify(user)
            });
        const json = await data.json();
        return json;
    }
);

export const saveUserLanguage = createAsyncThunk(
    'user/saveUserLanguage',
    async (lang: string) => {
        const data = await fetch('/UserData/SaveUserLanguage?' + new URLSearchParams({
            lang: lang,
        }));
        const json = await data.json();
        return json;
    }
);

export const changeUserPassword = createAsyncThunk(
    'user/changeUserPassword',
    async (model: any) => {
        const data = await fetch('/UserData/ChangeUserPassword',
            {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    'Accept': 'application/json',
                },
                body: JSON.stringify(model)
            });
        const json = await data.json();
        return json;
    }
);

export const userLogout = createAsyncThunk(
    'user/userLogout',
    async (user) => {
        const data = await fetch('/Account/UserLogout',
            {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    'Accept': 'application/json',
                },
                body: JSON.stringify(user)
            });
        const json = await data.json();
        return json;
    });

export const saveGroup = createAsyncThunk(
    'group/saveGroup',
    async (group: any) => {
        const data = await fetch('/Group/SaveGroup',
            {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    'Accept': 'application/json',
                },
                body: JSON.stringify(group)
            });
        const json = await data.json();
        return json;
    }
);

export const deleteGroup = createAsyncThunk(
    'group/deleteGroup',
    async (guid: string) => {
        const data = await fetch("/Group/DeleteGroup?" + new URLSearchParams({
            guid: guid,
        }));
        const json = await data.json();
        return json;
    }
);

export const getUserGroups = createAsyncThunk(
    'group/getUserGroups',
    async () => {
        const data = await fetch('/Group/GetUserGroups',
            {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    'Accept': 'application/json',
                },
            });
        const json = await data.json();
        return json;
    }
);

export const getGroupUsers = createAsyncThunk(
    'group/getGroupUsers',
    async (guid: string) => {
        const data = await fetch("/Group/GetGroupUsers?" + new URLSearchParams({
            guid: guid,
        }));
        const json = await data.json();
        return json;
    }
);

//export const deleteUserFromGroup = createAsyncThunk(
//    'group/deleteUserFromGroup',
//    async (obj: any) => {
//        const data = await fetch("/Group/DeleteUserFromGroup?" + new URLSearchParams({
//            groupGuid: obj.groupGuid,
//            userGuid: obj.userGuid
//        }));
//        const json = await data.json();
//        return json;
//    }
//);

export const searchUsers = createAsyncThunk(
    'user/searchUsers',
    async (search: string) => {
        const data = await fetch("/UserData/SearchUsers?" + new URLSearchParams({
            search: search,
        }));
        const json = await data.json();
        return json;
    }
);

export const checkUser = createAsyncThunk(
    'user/checkUser',
    async (search: string) => {
        const data = await fetch("/UserData/CheckUser?" + new URLSearchParams({
            search: search,
        }));
        const json = await data.json();
        return json;
    }
);
//export const confirmGroupInvitation = createAsyncThunk(
//    'user/confirmGroupInvitation',
//    async (groupGuid: string) => {
//        const data = await fetch("/Group/ConfirmGroupInvitation?" + new URLSearchParams({
//            groupGuid: groupGuid,
//        }));
//        const json = await data.json();
//        return json;
//    }
//);

export const userSlice = createSlice({
    name: "user",
    initialState: {
        name: null,
        isAuthorized: false,
        roles: [],
        isLoading: false,
        user: {},
        message: {},
        groups: new Array(),
        isOpenEditGroup: false,
        editGroup: {} as any,
        isNeedUpdateGroups: false,
        searchedUsers: {},
        selectedUser: {} as any,
        allowUser: false,
        groupUsers: [],
    },
    reducers: {
        uClearMessage: (state, action) => {
            state.message = {};
        },
        toggleOpenEditGroup: (state, action) => {
            state.isOpenEditGroup = !state.isOpenEditGroup;
            state.editGroup = isEmpty(action.payload) ? {} : action.payload;
        },
        setSelectedUser: (state, action) => {
            state.selectedUser = action.payload;
        },
        addUserToGroup: (state, action) => {
            var selectedUser = action.payload;
            if (state.editGroup && state.editGroup.users && state.editGroup.users.length > 0 && current(state.editGroup).users.find(x => x.userId == selectedUser.key)) {
                state.message = { message: "Пользователь уже в списке!", isVisible: true, status: 2 };
            }
            else {
                if (isEmpty(state.editGroup.users)) {
                    state.editGroup.users = new Array();
                }
                state.editGroup.users.push({ userId: selectedUser.key, name: selectedUser.value });
            }
        },
        setAllowUserFalse: (state, action) => {
            state.allowUser = false;
        },
        deleteUserFromGroup: (state, action) => {
            state.editGroup.users = current(state.editGroup.users).filter(x => x.userId != action.payload.userId);
        },
        unsetEditGroup: (state, action) => {
            state.editGroup = {};
        }
    },
    extraReducers:
        (builder) => {
            builder
                .addCase(getCurrentUser.pending, (state) => {
                })
                .addCase(getCurrentUser.fulfilled, (state, action) => {

                    state.name = action.payload;
                    state.isAuthorized = action.payload != undefined && action.payload != null ? true : false;
                    if (!state.isAuthorized) {
                        window.location.href = window.location.origin + "/Identity/Account/Login";
                    }
                })
                .addCase(getCurrentUser.rejected, (state, action) => {
                    state.name = null;
                    state.isAuthorized = false;
                    window.location.href = window.location.origin + "/Identity/Account/Login";
                })
                .addCase(getCurrentUserData.pending, (state) => {
                })
                .addCase(getCurrentUserData.fulfilled, (state, action) => {
                    state.name = action.payload.data.userId;
                    state.roles = action.payload.data.roles;
                    state.isAuthorized = action.payload != undefined && action.payload != null ? true : false;
                    if (!state.isAuthorized) {
                        window.location.href = window.location.origin + "/Identity/Account/Login";
                    }
                })
                .addCase(getCurrentUserData.rejected, (state, action) => {
                    state.name = null;
                    state.isAuthorized = false;
                    window.location.href = window.location.origin + "/Identity/Account/Login";
                })
                .addCase(userLogout.pending, (state) => {
                    state.isLoading = true;
                })
                .addCase(userLogout.fulfilled, (state, action) => {
                    state.name = null;
                    state.isAuthorized = false;
                    window.location.href = window.location.origin + "/Identity/Account/Login";
                })
                .addCase(userLogout.rejected, (state, action) => {
                    state.isAuthorized = false;
                    window.location.href = window.location.origin + "/Identity/Account/Login";
                })

                .addCase(getUserSettings.pending, (state) => {
                    state.isLoading = true;
                })
                .addCase(getUserSettings.fulfilled, (state, action) => {
                    state.isLoading = false;
                    state.user = action.payload.data;
                })
                .addCase(getUserSettings.rejected, (state, action) => {
                    state.isLoading = false;
                })

                .addCase(saveUser.fulfilled, (state, action) => {
                    if (action.payload.data) {
                        state.user = action.payload.data;
                    }
                    if (action.payload.message) {
                        state.message = { message: action.payload.message, isVisible: true, status: action.payload.status };
                    }
                })

                .addCase(saveUserLanguage.fulfilled, (state, action) => {
                    if (action.payload.data) {
                        state.user = action.payload.data;
                    }
                })

                .addCase(changeUserPassword.fulfilled, (state, action) => {
                    if (action.payload.message) {
                        state.message = { message: action.payload.message, isVisible: true, status: action.payload.status };
                    }
                })

                .addCase(getUserGroups.fulfilled, (state, action) => {
                    if (action.payload.data) {
                        state.groups = action.payload.data;
                    }
                    if (action.payload.message) {
                        state.message = { message: action.payload.message, isVisible: true, status: action.payload.status };
                    }
                })
                .addCase(saveGroup.fulfilled, (state, action) => {
                    if (action.payload.data) {
                        if (current(state.groups).some(x => x.guid == action.payload.data.guid)) {
                            state.groups = state.groups.map(gr => gr.guid == action.payload.data.guid ? action.payload.data : gr);
                        }
                        else {
                            state.groups.push(action.payload.data);
                        }
                        state.isOpenEditGroup = false;
                        state.editGroup = { };
                    }
                    if (action.payload.message) {
                        state.message = { message: action.payload.message, isVisible: true, status: action.payload.status };
                    }
                })
                .addCase(deleteGroup.fulfilled, (state, action) => {
                    if (action.payload.data) {
                        state.groups = state.groups.filter(gr => gr.guid != action.payload.data);
                    }
                    if (action.payload.message) {
                        state.message = { message: action.payload.message, isVisible: true, status: action.payload.status };
                    }
                })
                .addCase(getGroupUsers.fulfilled, (state, action) => {
                    if (action.payload.data) {
                        state.editGroup = { ...state.editGroup, users: action.payload.data };
                        state.groupUsers = action.payload.data;
                    }
                    if (action.payload.message) {
                        state.message = { message: action.payload.message, isVisible: true, status: action.payload.status };
                    }
                })
                //.addCase(deleteUserFromGroup.fulfilled, (state, action) => {
                //    if (action.payload.data) {
                //        state.editGroup.users = state.editGroup.users.filter(gr => gr.userGuid != action.payload.data);
                //        if (state.editGroup.users.length == 0) {
                //            state.isNeedUpdateGroups = true;
                //            state.isOpenEditGroup = false;
                //        }
                //    }
                //    if (action.payload.message) {
                //        state.message = { message: action.payload.message, isVisible: true, status: action.payload.status };
                //    }
                //})
                .addCase(searchUsers.fulfilled, (state, action) => {
                    if (action.payload.data) {
                        state.searchedUsers = action.payload.data;
                    }
                })
                .addCase(checkUser.fulfilled, (state, action) => {
                    state.allowUser = action.payload.status == 1 ? true : false;
                    state.selectedUser = action.payload.data ? action.payload.data : state.selectedUser;
                    if (action.payload.message) {
                        state.message = { message: action.payload.message, isVisible: true, status: action.payload.status };
                    }
                })
        },
});

export const currentUser = (state) => state.user.name;
export const currentUserRoles = (state) => state.user.roles;
export const userIsLoading = (state) => state.user.isLoading;
export const uMessage = (state) => state.user.message;
export const loadsIsOpenEditGroup = (state) => state.user.isOpenEditGroup;
export const loadedGroups = (state) => state.user.groups;
export const loadEditGroup = (state) => state.user.editGroup;
export const loadIsNeedUpdateGroups = (state) => state.user.isNeedUpdateGroups;
export const loadSearchedUsers = (state) => state.user.searchedUsers;
export const loadSelectedUser = (state) => state.user.selectedUser; 
export const loadAllowUser = (state) => state.user.allowUser;
export const loadGroupUsers = (state) => state.user.groupUsers;

export const loadUser = (state) => state.user.user;

export const { uClearMessage, toggleOpenEditGroup, setSelectedUser, addUserToGroup,
    deleteUserFromGroup, setAllowUserFalse, unsetEditGroup } = userSlice.actions;

export default userSlice.reducer;