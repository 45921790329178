import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import UserGroupSettings from './settingTabs/userGroupSettings';
import UserMainSettings from './settingTabs/userMainSettings';

const User = () => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState(1);

    return (<>
        <div className="user-panel">
            <Nav tabs>
                <NavItem>
                    <NavLink
                        className={activeTab == 1 ? "active" : ""}
                        onClick={() => setActiveTab(1)}
                    >
                        {t('users.settings.main')}
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={activeTab == 2 ? "active" : ""}
                        onClick={() => setActiveTab(2)}
                    >
                        {t('users.settings.groups')}
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId={1}>
                    <Row>
                        <Col sm="12">
                            <UserMainSettings />
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId={2}>
                    <Row>
                        <Col sm="12">
                            <UserGroupSettings />
                        </Col>
                    </Row>
                </TabPane>
            </TabContent>
        </div>
    </>);
}

export default User;