import * as React from 'react';
import { useState } from 'react';
import { Tooltip } from 'reactstrap';

export default function CustomTooltip(props) {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    return (
        <Tooltip
            isOpen={tooltipOpen}
            flip
            trigger="hover focus"
            placement="bottom"
            target={props.refButton ? props.refButton : props.id}
            toggle={() => { setTooltipOpen(!tooltipOpen) }}>
            {props.text}
        </Tooltip>
    );
}