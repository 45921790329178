import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { formatDateToShortDateWithNameAndTime } from '../../functions/functions';
import { weekDaysNames } from '../calendar/calendarSlice';
import { changeLetterStatus, letterLink, loadUpdatedLetter } from './lettersSlice';

const Letter = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const wDaysNames = useSelector(weekDaysNames);
    const upLetter = useSelector(loadUpdatedLetter);
    const [letter, setLetter] = useState(props.letter);
    const [open, setOpen] = useState(false);
    const isOutgoing = letter.guid == '00000000-0000-0000-0000-000000000000';

    const openLetter = () => {
        setOpen(!open);
        if (!open && letter.letterStatus == 0 && !isOutgoing) {
            setLetter({ ...letter, letterStatus: 1 });
            dispatch(changeLetterStatus({ ...letter, letterStatus: 1 }));
        }
    }

    useEffect(() => {
        if (letter.guid == upLetter.guid) {
            setLetter({
                ...letter,
                letterStatus: upLetter.letterStatus,
                letterStatusText: upLetter.letterStatusText
});
        }
    }, [upLetter]);

    return (<>
        <div className={"letter-item " + (isOutgoing ? "letter-item-outgoing " : "") + (open ? "letter-open" : "")}>
            <div className="letter-top">
                <div className="letter-header" onClick={() => openLetter()}>
                    {letter.header}
                </div>
                <div className="letter-author" onClick={() => openLetter()}>
                    {isOutgoing ?
                        <div>{t('letters.to')} {letter.recipientList}</div>
                        :
                        <>
                            <div>{t('letters.from')} {letter.authorName}</div>
                            <div className="letter-status">[{letter.letterStatusText}]</div>
                        </>
                    }
                </div>
            </div>
            <div className="letter-content">
                <div className="letter-date">
                    {formatDateToShortDateWithNameAndTime(letter.dateTime, wDaysNames)}:
                </div>
                {letter.content}
                {letter.standartMessage!=0 &&
                    <div className="group-invitation-actions">
                        <Button onClick={() => dispatch(letterLink(letter.link))}>{t('letters.acceptInvitation')}</Button>
                    </div>
                }
            </div>
        </div>
    </>);
}

export default Letter;