import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/button';
import { deleteGroup, toggleOpenEditGroup } from '../userSlice';

const GroupItem = (props) => {
    const { t } = useTranslation();
    const group = props.group;
    return (<>
        <div className="group-item"><div className="group-name">{group.name}</div>
            <div className="action-button-group inline-button-group">
                <Button key={"edit_" + group.guid} type="edit" className="small-button" params={group} action={toggleOpenEditGroup}
                    help={t('groups.editGroup')} />
                <Button key={"delete_" + group.guid} type="delete" className="small-button" guid={group.guid} action={deleteGroup}
                    help={t('groups.deleteGroup')} confirmText={t('groups.confirmDeleteGroup')} />
            </div>
        </div>
    </>);
}

export default GroupItem;