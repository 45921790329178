import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, DropdownMenu, DropdownToggle, Input } from 'reactstrap';
import Button from './button';
import { checkUser, loadAllowUser, loadSearchedUsers, loadSelectedUser, searchUsers, setAllowUserFalse, setSelectedUser } from '../features/user/userSlice';
import { useTranslation } from 'react-i18next';

const SearchUserDropDown = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const items = useSelector(loadSearchedUsers);
    const selectedUser = useSelector(loadSelectedUser);
    const allowUser = useSelector(loadAllowUser);
    const defaultSelect = { key: 0, value: "" };
    const [selectString, setSelectString] = useState(defaultSelect);

    const [isOpen, setOpen] = useState(false);
    const toggleOpen = (item) => {
        setOpen(!isOpen);
        if (item) {
            setSelectString(item);
        }
    }

    useEffect(() => {
        //dispatch(searchUsers(selectString.value));
        dispatch(setSelectedUser(selectString));
    }, [selectString]);

    const check = async () => {
        await dispatch(checkUser(selectString.value));
    }

    const addUser = async () => {
        await dispatch(props.action(selectedUser));
        setSelectString(defaultSelect);
        dispatch(setAllowUserFalse(null));
    };

    useEffect(() => {
        if (allowUser) {
            addUser();
        }
    }, [allowUser]);

    return (<>
        <div className="add-search-user">
            <Dropdown className="drop-down search-dropdwn" toggle={() => setOpen(true)} isOpen={isOpen}>
                <DropdownToggle caret>
                    <Input id="searchString" type="text" value={selectString.value}
                        placeholder="Email"
                        onFocus={() => setOpen(true)}
                        onChange={ev => { setSelectString({ ...selectString, value: ev.target.value }); }}
                        onBlur={() => setOpen(false)}
                    />
                </DropdownToggle>
                <DropdownMenu container="body" className="search-dropdown-items">
                    {items && items.length > 0 && items.map(x =>
                        <div key={"dropDownItems_" + x.key} className="drop-down-item" onMouseDown={() => toggleOpen(x)}
                            data-key={x.key}>
                            {x.value}
                        </div>
                    )}
                </DropdownMenu>
            </Dropdown>
            <Button id="addUserButton" type="add" help={t('controls.button.add')} params={null} action={() => check()} />
        </div>
    </>);
}

export default SearchUserDropDown;