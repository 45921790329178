import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from 'reactstrap';
import DropDown from '../../../../components/dropDown';
import { loadEditingProject, loadLabels, loadProject, loadSelectedLabels, setEditProject } from '../../projectsSlice';

export default function EditProjectMain() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    var labels = useSelector(loadLabels);
    var project = useSelector(loadProject);
    var editProject = useSelector(loadEditingProject);
    //var currentLabels = useSelector(loadSelectedLabels);
    var [keyValueLabels, setKeyValueLabels] = useState(labels ? labels.filter(x => !x.isFixed).map(x => ({ key: x.guid, value: x.name })) : null);
    const [Name, setName] = useState(project?.name);
    var [Label, setLabel] = useState(keyValueLabels?.find(x => x.key == (project?.labelGuid)));
    const [ShortName, setShortName] = useState(project?.shortName);

    useEffect(() => {
        setKeyValueLabels(labels ? labels.filter(x => !x.isFixed).map(x => ({ key: x.guid, value: x.name })) : null)
    }, [labels]);

    useEffect(() => {
        if (project) {
            setLabel(keyValueLabels?.find(x => x.key == project?.labelGuid));
            setName(project.name);
            setShortName(project.shortName);
        }
    }, [project]);

    useEffect(() => {
        dispatch(setEditProject({
            ...editProject,
            name: Name,
            labelGuid: Label ? Label.key : null,
            shortName: ShortName,
            //IsCopyTasksWhenMovingInto: isCopy
        }));
    }, [Name, Label, ShortName]);

    //const toggleCopySetting = () => {
    //    setCopy(!isCopy);
    //}

    return (
        <div>
            <Input autoFocus={true}
                id={"project-save-name"}
                placeholder={t('projects.projectName')}
                onChange={event => setName(event.target.value)}
                defaultValue={Name}
            />
            {/*onKeyPress={event => CheckEnter(event, saveEditProject)}*/}
            {keyValueLabels && keyValueLabels.length > 0 &&
                <div className="edit-project-line">
                    {t('projects.projectLabel')}
                    <DropDown items={keyValueLabels} selected={Label ? Label : keyValueLabels[0]} action={setLabel} />
                </div>
            }
            <div className="edit-project-line">
                <Input
                    id="project-short-name"
                    placeholder={t('projects.projectShortName')}
                    onChange={event => setShortName(event.target.value)}
                    defaultValue={ShortName}
                />
            </div>
        </div>);
};