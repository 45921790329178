import * as React from 'react';
import { useSelector } from 'react-redux';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    BrowserRouter,
    Redirect
} from "react-router-dom";
import UserLogoutButton  from '../features/user/userSettingsButton';
import HelpProject from '../features/help/HelpProject';
import { currentUserRoles } from '../features/user/userSlice';
import Projects from '../features/project/Projects';
import AdminButton from './navMenuButtons/adminButtons/AdminButton';
import NavMenuButton from './navMenuButtons/navMenuButton';
import Users from '../features/admin/Users';
import Logs from '../features/admin/Logs';
import Wiki from '../features/admin/Wiki';
import Articles from '../features/article/Articles';
import Kanban from '../features/project/modes/kanban';
import Weight from '../features/weight/Weight';
import ProjectButton from './navMenuButtons/ProjectButton';
import NotFound from './notFound';
import Calendar from '../features/calendar/calendar';
import User from '../features/user/user';
import Letters from '../features/letter/letters';
import { Badge } from 'reactstrap';
import LettersButton from './navMenuButtons/LettersButton';
import { useTranslation } from 'react-i18next';
import Sendings from '../features/admin/Sendings';

export default function NavMenu() {
    const { t } = useTranslation();
    var userRoles = useSelector(currentUserRoles);
    if (localStorage.getItem('isAdmin') === "true" && userRoles.some(x => x === "Admin")) {
        return (
            <Router>
                <div>
                    <header id="mainPanel" className="main-top-panel white-background">
                        <Link to="/Users"><NavMenuButton help="Пользователи" type="users" /></Link>
                        <Link to="/Logs"><NavMenuButton help="Логи" type="logs" /></Link>
                        <Link to="/Wiki"><NavMenuButton help="База знаний" type="wiki" /></Link>
                        <Link to="/Sendings"><NavMenuButton help="Рассылки" type="letters" /></Link>
                        <Link to="/"><AdminButton /></Link>
                    </header>
                    <div className="main-panel admin-main-panel">
                        <Switch>
                            <Route path="/Users">
                                <Users />
                            </Route>
                            <Route path="/Logs">
                               <Logs />
                            </Route>
                            <Route path="/Wiki">
                                <Wiki />
                            </Route>
                            <Route path="/Adminka">
                            </Route>
                            <Route path="/Sendings">
                                <Sendings />
                            </Route>
                            <Route render={() => <Redirect to="/Adminka" />} />
                        </Switch>
                    </div>
                </div>
            </Router>
        );
    }
    return (
        <Router>
            <div>
                <header id="mainPanel" className="main-top-panel white-background">
                    <UserLogoutButton help={t('nav.help.exit')} />
                    <Link to="/"><ProjectButton help={t('nav.help.projects')} /></Link>
                    <Link to="/Calendar"><NavMenuButton id="calendarButton" help={t('nav.help.calendar')} type="calendar" /></Link>
                  {/*  <EditProjectButton guid={null} isEdit={false} class="big-button" />*/}
                    <Link to="/Kanban"><NavMenuButton id="projectsBoardButton" help={t('nav.help.kanban')} type="kanban" /></Link>
                    <Link to="/Weight"><NavMenuButton id="weightButton" help={t('nav.help.weight')} type="weight" /></Link>
                    <Link to="/Articles"><NavMenuButton help={t('nav.help.wiki')} type="articles" position="right" /></Link>
                    <Link to="/Help"><NavMenuButton type="help" help={t('nav.help.help')} position="right" /></Link>
                    <Link to="/Letters"><LettersButton type="letters" help={t('nav.help.letters')} position="right" /></Link>
                    <Link to="/User"><NavMenuButton help={t('nav.help.user')} type="user" position="right" /></Link>
                    {userRoles.some(x => x == "Admin") && <Link to="/Adminka"><AdminButton /></Link>}
                </header>
                <div className="main-panel">
                    <Switch>
                        <Route exact path="/">
                            <Projects />
                        </Route>
                        <Route exact path="/Kanban">
                            <Kanban />
                        </Route>
                        <Route exact path="/Calendar/Month">
                            <Calendar mode={1} />
                        </Route>
                        <Route exact path="/Calendar/Week">
                            <Calendar mode={2} />
                        </Route>
                        <Route exact path="/Calendar/Day">
                            <Calendar mode={3} />
                        </Route>
                        <Route exact path="/Calendar">
                            <Calendar mode={2} />
                        </Route>
                        <Route exact path="/Weight">
                            <Weight />
                        </Route>
                        <Route path="/Articles">
                            <Articles />
                        </Route>
                        <Route exact path="/Help">
                            <HelpProject />
                        </Route>
                        <Route exact path="/User">
                            <User />
                        </Route>
                        <Route exact path="/Letters">
                            <Letters />
                        </Route>
                        <Route path="*">
                           <NotFound />
                        </Route>
                        <Route render={() => <Redirect to="/" />} />
                    </Switch>
                </div>
            </div>
        </Router>
    );
}