import * as React from 'react';
import Button from '../../../../components/button';
import { deleteLabel, saveLabel, toggleEditLabel } from '../../projectsSlice';

const LabelItem = (props) => {
    const label = props.label;
    return (<>
        <div className="label-item">
            <div className="label-color" style={{ backgroundColor: label.color }}></div>
            <div className="label-name">{label.name}</div>
            <div className="action-button-group inline-button-group">
                <Button type="edit" className="small-button" params={label} action={toggleEditLabel} />
                <Button type="delete" className="small-button" guid={label.guid} action={deleteLabel} />
            </div>
        </div>
    </>);
}

export default LabelItem;