import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../components/button';
import { formatDateInput } from '../../functions/functions';
import { deleteEvent, toggleDeleteWindow, toggleEditEvent, toggleEventDescription } from './calendarSlice';


const EventActionsBlock = (props) => {
    const { t } = useTranslation();
    var event = props.event;
    return (<>
        <div className="action-button-group calendar-button-group event-button-group">
            <Button type="edit" className="smallest-button" params={{ ...event, date: formatDateInput(event.date) }}
                action={toggleEditEvent} help={t('calendar.editEvent')} />
            {
                event.settingGuid ?
                    <Button type="delete" className="smallest-button" params={{ event: event, isAll: false }}
                        action={toggleDeleteWindow} help={t('calendar.deleteEvent')} />
                    :
                    <Button type="delete" className="smallest-button" params={{ guid: event.guid, isAll: false }}
                        action={deleteEvent} help={t('calendar.deleteEvent')} confirmText={t('calendar.confirmDelete')} />
            }
            {
                props.isDesc && 
                <Button type="hide" className="smallest-button" params={null}
                    action={toggleEventDescription} help={t('controls.button.hide')} />
            }
        </div>
    </>);
}

export default EventActionsBlock;