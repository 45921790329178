import * as React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Input } from 'reactstrap';
import Button from '../../../../components/button';
import { closeEditLabel, saveLabel, toggleEditLabel } from '../../projectsSlice';

const EditLabel = (props) => {
    const [label, setLabel] = useState(props.label);
    return (<>
        <div className="edit-label">
            <Input
                autoFocus={true}
                onChange={event => setLabel({ ...label, name: event.target.value })}
                value={ label.name}
            />
            <Input
                id="newLabelColor"
                name="color"
                placeholder="color placeholder"
                type="color"
                onChange={event => setLabel({ ...label, color: event.target.value })}
                value={label.color}
            />
            <div className="action-button-group inline-button-group">
                <Button type="ok" className="small-button" params={label} action={saveLabel} />
                <Button type="cancel" className="small-button" params={null} action={closeEditLabel} />
            </div>
        </div>
    </>);
}

export default EditLabel;