import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { formatDateDefaultTime } from '../../functions/functions';

export const saveHeader = createAsyncThunk(
    'weight/saveHeader',
    async (header: any) => {
        const data = await fetch("/WeightControll/SaveHeader",
            {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    'Accept': 'application/json',
                },
                body: JSON.stringify(header)
            });
        return await data.json();
    }
);

export const deleteHeader = createAsyncThunk(
    'weight/deleteHeader',
    async (guid: string) => {
        const data = await fetch("/WeightControll/DeleteHeader?" + new URLSearchParams({ guid: guid }));
        return await data.json();
    }
);

export const deleteValue = createAsyncThunk(
    'weight/deleteValue',
    async (params: { guid: string, date: string }) => {
        const data = await fetch("/WeightControll/DeleteValue?" + new URLSearchParams({ guid: params.guid, date: params.date }));
        return await data.json();
    }
);

export const clearTable = createAsyncThunk(
    'weight/clearTable',
    async () => {
        const data = await fetch("/WeightControll/ClearTable",{
            method: "GET",
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Accept': 'application/json',
            },
        });
        return await data.json();
    }
);

export const saveValue = createAsyncThunk(
    'weight/saveValue',
    async (value: {}) => {
        const data = await fetch("/WeightControll/SaveValue",
            {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    'Accept': 'application/json',
                },
                body: JSON.stringify(value)
            });
        return await data.json();
    }
);

export const deleteDate = createAsyncThunk(
    'weight/deleteDate',
    async (date: any) => {
        const data = await fetch("/WeightControll/DeleteDate?" + new URLSearchParams({ date: date.date }));
        return await data.json();
    }
);

export const saveDate = createAsyncThunk(
    'weight/saveDate',
    async (dates: { oldDate, newDate}) => {
        const data = await fetch("/WeightControll/SaveDate?" + new URLSearchParams({ oldDate: dates.oldDate, newDate: dates.newDate }));
        return await data.json();
    }
);

export const getData = createAsyncThunk(
    'weight/getData',
    async () => {
        const data = await fetch("/WeightControll/GetData", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Accept': 'application/json',
            },
        });
        return await data.json();
    }
);

export const weightSlice = createSlice({
    name: "weight",
    initialState: {
        data: {
            data: new Array(),
            headers: new Array(),
        },
        editableHeader: {},
        editableCell: {},
        editableDate: {},
        isNeedUpdate: true,
        message: {},
        isLoadingWeight: false,
        isShowTable: true,
    },
    reducers: {
        addNewColumn: (state, action) => {
            //state.headers
        },
        setEditableHeader: (state, action) => {
            state.editableCell = {};
            state.editableDate = {};
            state.editableHeader = { guid: action.payload };
        },
        setEditableCell: (state, action) => {
            state.editableHeader = {};
            state.editableDate = {};
            state.editableCell = action.payload;
        },
        setEditableDate: (state, action) => {
            state.editableHeader = {};
            state.editableCell = {};
            state.editableDate = action.payload.date;
        },
        addNewDateLine: (state, action) => {
            var newLine = new Array();
            var currentDate = new Date();
            var isCurrentDateExists = current(state.data.data)
                .find(x => new Date(x.date).toLocaleDateString() == currentDate.toLocaleDateString()) ? true : false;
            current(state.data.headers).map(l => newLine.push({ guid: l.guid, value: 0 }));
            state.data.data.push({ date: isCurrentDateExists ? "" : formatDateDefaultTime(currentDate), columns: newLine });
            state.editableDate = isCurrentDateExists ? "" : {};
        },
        wClearMessage: (state, action) => {
            state.message = {};
        },
        toggleShowTable: (state, action) => {
            state.isShowTable = action.payload;
        }
    },
    extraReducers:
        (builder) => {
            builder
                /*header -->*/
                .addCase(saveHeader.fulfilled, (state, action) => {
                    if (action.payload.message) {
                        state.message = { message: action.payload.message, isVisible: true, status: action.payload.status };
                    }
                    if (action.payload.data) {
                        if (current(state.data.headers).some(x => x.guid == action.payload.data.header.guid)) {
                            state.data.headers = state.data.headers.map(pr => pr.guid == action.payload.data.header.guid ? action.payload.data.header : pr);
                        }
                        else {
                            state.data.headers.unshift(action.payload.data.header);
                            state.data.data.map(x => x.columns.unshift({ guid: action.payload.data.header.guid, value: 0 }));
                        }
                        state.editableHeader = action.payload.data.header.isEdit ? action.payload.data.header : {};
                    }
                })

                .addCase(deleteHeader.pending, (state, action) => {
                })
                .addCase(deleteHeader.fulfilled, (state, action) => {
                    if (current(state.data.headers).some(x => x.guid == action.payload.data)) {
                        state.data.headers = state.data.headers.filter(pr => pr.guid != action.payload.data);
                    }
                    state.editableHeader = {};
                    state.isNeedUpdate = true;
                })
                .addCase(deleteHeader.rejected, (state, action) => {
                })
                /*<-- header*/

                .addCase(getData.pending, (state, action) => {
                    state.isLoadingWeight = true;
                })
                .addCase(getData.fulfilled, (state, action) => {
                    state.data = action.payload.data;
                    state.editableHeader = {};
                    //state.settings = action.payload.data;
                    state.isNeedUpdate = false;
                    state.isLoadingWeight = false;
                })
                .addCase(getData.rejected, (state, action) => {
                    state.isLoadingWeight = false;
                })
               

                /*date -->*/
                .addCase(saveDate.pending, (state, action) => {
                })
                .addCase(saveDate.fulfilled, (state, action) => {
                    if (action.payload.message) {
                        state.message = { message: action.payload.message, isVisible: true, status: action.payload.status };
                    }
                    else {
                        state.editableDate = {};
                        state.isNeedUpdate = true;
                    }
                })
                .addCase(saveDate.rejected, (state, action) => {
                })

                .addCase(deleteDate.pending, (state, action) => {
                })
                .addCase(deleteDate.fulfilled, (state, action) => {
                    state.editableDate = {};
                    state.isNeedUpdate = true;
                })
                .addCase(deleteDate.rejected, (state, action) => {
                })
            /*<--date*/

            /*value -->*/
                .addCase(saveValue.fulfilled, (state, action) => {
                    state.data.data = current(state.data.data).map(x => x.date != action.payload.data.date ? x : { date: x.date, columns: x.columns.map(c => c.guid != action.payload.data.weightGuid ? c : { value: action.payload.data.value, cellGuid: action.payload.data.guid, guid: action.payload.data.weightGuid }) });
                    state.isNeedUpdate = true;
                })
                .addCase(deleteValue.fulfilled, (state, action) => {
                    state.data.data = current(state.data.data).map(x => x.date != action.payload.data.date ? x : { date: x.date, columns: x.columns.map(c => c.cellGuid != action.payload.data.guid ? c : { ...c, value: 0, cellGuid: null }) });
                    state.isNeedUpdate = true;
                })
                .addCase(clearTable.fulfilled, (state, action) => {
                    state.data.data = action.payload.data;
                    state.isNeedUpdate = true;
                })
            /*<-- value*/
        }
});

export const loadData = (state) => state.weight.data;
export const editHeader = (state) => state.weight.editableHeader;
export const editCell = (state) => state.weight.editableCell;
export const editDate = (state) => state.weight.editableDate;
export const needUpdate = (state) => state.weight.isNeedUpdate;
export const wMessage = (state) => state.weight.message;
export const wIsLoading = (state) => state.weight.isLoadingWeight;
export const loadIsShowTable = (state) => state.weight.isShowTable;

export const { setEditableHeader, setEditableCell, setEditableDate, addNewDateLine, wClearMessage, toggleShowTable } = weightSlice.actions;

export default weightSlice.reducer;