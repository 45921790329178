import * as React from 'react';
import { useEffect, useState } from 'react';

export default function ScrollableText(props) {
    const maxLength = props.maxLength ? props.maxLength : 25;
    const fieldWidth = props.width ? props.width : '230px';
    const [isHover, toggleHover] = useState(false);
    const [style, changeStyle] = useState({animation: '', overflow: 'hidden', width: fieldWidth });
    const [parentStyle, changeParentStyle] = useState({ overflow: 'none' });
    const nameLength = props.text ? props.text.length : 0;
    

    function inside() {
        toggleHover(true);
    }
    function outside() {
        toggleHover(false);
    }
    useEffect(() => {
        if (nameLength > maxLength) {
            changeStyle({
                animation: isHover && nameLength ? nameLength / 8 + 's linear backwards move' : '',
                overflow: isHover && nameLength ? 'visible' : 'hidden',
                width: isHover && nameLength ? 'auto' : fieldWidth,
            });
            changeParentStyle({ overflow: isHover && nameLength ? 'hidden' : 'visible' });
        }
    }, [isHover]);

    return (
        <div className={props.className + " scrollable-text"} style={parentStyle}>
            <span style={style} onMouseEnter={inside} onMouseLeave={outside} >{props.text}</span>
        </div>
    );
}