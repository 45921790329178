import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { toggleProjectSettings, isOpenProjectSettings } from '../projectsSlice';
import LabelSettings from './settings/labelSettings';
import MainSettings from './settings/mainSettings';


const ProjectsSettings = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isOpen = useSelector(isOpenProjectSettings);
    const [activeTab, setActiveTab] = useState(1);

    return (
        <div>
            <Modal autoFocus={false}
                centered
                toggle={() => dispatch(toggleProjectSettings(null))}
                backdrop="static"
                isOpen={isOpen}>
                <div className="white-background">
                    <ModalHeader toggle={function noRefCheck() { }}>
                        {"Настройки"}
                    </ModalHeader>
                    <ModalBody>

                        <div className="project-settings-panel">
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        className={activeTab == 1 ? "active" : ""}
                                        onClick={() => setActiveTab(1)}
                                    >
                                        {t('projects.mainEdit')}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={activeTab == 2 ? "active" : ""}
                                        onClick={() => setActiveTab(2)}
                                    >
                                        {t('projects.labelEdit')}
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId={1}>
                                    <Row>
                                        <Col sm="12">
                                            <MainSettings />
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId={2}>
                                    <Row>
                                        <Col sm="12">
                                            <div>
                                                <LabelSettings />
                                            </div>
                                        </Col>
                                    </Row>
                                </TabPane>
                            </TabContent>
                        </div>

                    </ModalBody>
                    <ModalFooter>
                        {' '}
                        <Button onClick={() => dispatch(toggleProjectSettings(null))}>
                            {t('controls.modal.close')}
                        </Button>
                    </ModalFooter>
                </div>
            </Modal>
        </div>);
}

export default ProjectsSettings;