import * as React from 'react';
import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toggleConfirmWindow } from '../features/common/commonSlice';
import Icon from './icon';
import Tooltip from './toolTip';

const Button = (props) => {
    const dispatch = useDispatch();
    const ref = useRef(null);

    const guid = props.guid;
    const buttonType = props.type;
    const id = props.id ? props.id : buttonType + props.target + "_" + guid;
    const action = props.action;
    const help = props.help;
    const confirmText = props.confirmText;
    const needConfirm = props.needConfirm || confirmText != null && confirmText != "";
    const text = props.text;
    var className = props.className || "small-button red-button";
    className += text == null ? "" : " button-with-text";
    className += " noselect";
    const params = props.params || guid;
 
    return (
        <>
            <button key={props.pkey} id={id} ref={ref} className={className} 
                onClick={async () => {
                if (needConfirm) { dispatch(toggleConfirmWindow({ action, params, confirmText })); }
                else {
                    ref.current.setAttribute("disable", true);
                    await dispatch(action(params));
                }
            }}>
                <Icon image={"/images/" + buttonType + ".svg"} guid={guid} imageName={buttonType} alt={help} />
                <div>{text}</div>
            </button>
            {help &&
                <Tooltip id={id} text={help} refButton={ref } />
            }
        </>
    );
};
export default Button;