import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Input } from 'reactstrap';
import Button from '../../../components/button';
import CheckEnter from '../../../functions/controls';
import { setEditableHeader, getData, saveHeader } from '../weightSlice';

const EditHeader = (props: any) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [header, setHeader] = useState(props.header);

    const saveEditHeader = async () => {
        await dispatch(saveHeader(header));
        //dispatch(getData());
    }
    //const saveEditHeader = () => {
    //    dispatch(saveHeader(header));
    //    //dispatch(getData());
    //}

    const cancelEditHeader = async () => {
        await dispatch(setEditableHeader({}));
        dispatch(getData());
    }
    return (<>
        <div className="weight-edit">
            <Input value={header.name} className="weight-edit-name" type="textarea" onChange={event => setHeader({ ...header, name: event.target.value })}
                onKeyPress={event => CheckEnter(event, saveEditHeader)} autoFocus={header.guid ? true : false} />
            <Button key={"saveHeader_" + header.guid} guid={header.guid} type="ok" action={saveEditHeader} help={t('controls.button.save')}
                className="small-button" />
            <Button guid={header.guid} type="cancel" action={cancelEditHeader} help={t('controls.button.cancel')}
                className="small-button" />
        </div>
    </>);
};

export default EditHeader;