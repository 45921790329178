import * as React from 'react';
import { useDispatch } from 'react-redux';
import { toggleEventDescription } from './calendarSlice';

const MobileEvent = (props) => {
    const dispatch = useDispatch();
    const event = props.event;
    return (<>
        <button className={("mobile-event " + (event.taskGuid ? "task-event" : ""))}
            onClick={() => dispatch(toggleEventDescription(event))}
        />
    </>);
}

export default MobileEvent;