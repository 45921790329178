import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../components/button';
import Checkbox from '../../../components/checkbox';
import ScrollableText from '../../../components/scrollableText';
import { changeStatus, loadTask, toggleExtendedEditTask } from '../tasksSlice';
import DeleteTaskButton from './DeleteTaskButton';
import EditTask from './editTask';
import EditTaskButton from './EditTaskButton';

export default function Task(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const loadedTask = useSelector(loadTask);
    const [isEditMode, toogleIsEdit] = useState(false);
    const [task, setTask] = useState(props.task);

    const id = "task_" + task.guid;
    useEffect(() => {
        if (loadedTask && loadedTask.guid == task.guid) {
            toogleIsEdit(loadedTask.isEditMode);
            setTask(loadedTask);
        }
    }, [loadedTask, isEditMode]);

    const setTaskInWork = () => {
        var newStatus = task.status == 3 ? 1 : 3;
        setTask({ ...task, status: newStatus }); 
        dispatch(changeStatus({ guid: task.guid, status: newStatus, parentGuid: task.projectGuid }));
    };

    if (!isEditMode) {
        return (

            <li className={"task-item noselect " + (task.status == 3 ? " task-active" : " white-background ")} key={task.guid} id={ id} onDoubleClick={() => { setTaskInWork() }}>
                <Checkbox key={"checkBox_" + task.guid} guid={task.guid} action={changeStatus} isChecked={task.status == 4}
                    parentGuid={task.projectGuid} isTask={true} />
                {
                    task.name.length > 65?
                    <ScrollableText className="task-item-name" text={task.name} width="230px" maxLength={25} />
                        : <div className="task-item-name">{task.name}</div>
                }

                <div className="action-button-group task-button-group">
                    <EditTaskButton task={task} />
                    <Button key={"extendedTaskSettings_" + task.guid} id={"extendedTaskSettings_" + task.guid} params={task} type="settings"
                        action={toggleExtendedEditTask} help={t('tasks.taskExtendedEdit')} />
                    <DeleteTaskButton guid={task.guid} projectGuid={task.projectGuid} />
                </div>
            </li>
        );
    }
    else {
        return (
            <li className={"task-item white-background "} key={task.guid} >
                <EditTask task={task} />
            </li>
        );
    }
}