import * as React from 'react';
import { useRef } from 'react';
import Tooltip from './toolTip';

export default function IconLabel(props) {
    const id = "icon_" + props.imageName + "_" + props.guid;
    const ref = useRef(null);

    return (
        <>
            <img id={id} src={props.image + "?v=1"} ref={ref} className={"svg " + props.className} />
            {props.help &&
                <Tooltip id={id} text={props.help} refButton={ref} />
            }
        </>
    );
}