import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';


const methodData = {
    method: "GET",
    headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Accept': 'application/json',
    },
};


export const switchAdmin = createAsyncThunk(
    'admin/switchAdmin',
    async () => {
        const data = await fetch('/Admin/Index', methodData);
        return await data.json();
    }
);

//Users page -->
export const getUsers = createAsyncThunk(
    'admin/getUsers',
    async () => {
        const data = await fetch('/Admin/GetUsers', methodData);
        return await data.json();
    }
);

export const deleteUser = createAsyncThunk(
    'admin/deleteUser',
    async (guid: string) => {
        const data = await fetch("/Admin/DeleteUser?" + new URLSearchParams({
            guid: guid,
        }));
        return await data.json();
    });

export const loginAsUser = createAsyncThunk(
    'admin/loginAsUser',
    async (guid: string) => {
        const data = await fetch("/Admin/LoginAsUser?" + new URLSearchParams({
            guid: guid,
        }));
        return await data.json();
    });
//Users page <--

//Logs page --> 
export const getLogs = createAsyncThunk(
    'admin/getLogs',
    async (props: any) => {
            const data = await fetch("/Admin/GetLogs?" + new URLSearchParams({
            startIndex: props.startIndex,
            take: props.take,
            dateFrom: props.dateFrom,
            dateTo: props.dateTo,
            actionName: props.action? props.action: null,
            user: props.user?  props.user : null,
            isHideAdmin: props.hideAdmin,
            isHideEmpty: props.hideEmpty,
        }));
        return await data.json();
    }
);

export const deleteLogs = createAsyncThunk(
    'admin/deleteLogs',
    async () => {
        const data = await fetch('/Admin/DeleteLogs', methodData);
        return await data.json();
    }
);
//Logs page <--

//Article page -->
export const saveArticle = createAsyncThunk(
    "admin/saveArticle",
    async (article: any) => {
        const data = await fetch('/Admin/SaveArticle',
            {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    'Accept': 'application/json',
                },
                body: JSON.stringify(article)
            });

        const json = await data.json();
        return json;
    }
);

export const getArticle = createAsyncThunk(
    'admin/getArticle',
    async (guid: string) => {
        const data = await fetch("/Admin/GetArticle?" + new URLSearchParams({
            guid: guid,
        }));
        const json = await data.json();
        return json;
    });

export const deleteArticle = createAsyncThunk(
    'admin/deleteArticle',
    async (guid: string) => {
        const data = await fetch("/Admin/DeleteArticle?" + new URLSearchParams({
            guid: guid,
        }));
        const json = await data.json();
        return json;
    });

export const getArticles = createAsyncThunk(
    'admin/getArticles',
    async () => {
        const data = await fetch("/Admin/GetArticles");
        const json = await data.json();
        return json;
    });
//Article page <--

//Sending page -->
export const scheduleSendings = createAsyncThunk(
    'admin/scheduleSendings',
    async () => {
        const data = await fetch("/Admin/ScheduleSendings");
        const json = await data.json();
        return json;
    });
//Sending page <--


export const adminSlice = createSlice(
    {
        name: "admin",
        initialState: {
            users: new Array(),
            logs: new Array(),
            logsTotalCount: 0,
            logsCurrentPage: 1,

            articlesList: new Array(),
            article: {},
            isOpenEditArticle: false,

            isLoading: false
            //logsFilter: {}
        },
        reducers: {
            toggleEditArticle: (state, action) => {
                state.isOpenEditArticle = !state.isOpenEditArticle;
                state.article = { guid: action.payload };
            }
        },
        extraReducers:
            (builder) => {
                builder
                    //SwitchAdmin
                    .addCase(switchAdmin.pending, (state) => {
                        state.isLoading = true;
                    })
                    .addCase(switchAdmin.fulfilled, (state, action) => {
                        if (action.payload.status == 1) {
                            localStorage.setItem('isAdmin', localStorage.getItem('isAdmin') == "true" ? "false" : "true");
                            window.location.href = window.location.href;
                        }
                        state.isLoading = false;
                    })
                    .addCase(switchAdmin.rejected, (state, action) => {
                        state.isLoading = false;
                        // localStorage.setItem('isAdmin', 'false');
                        //window.location.href = window.location.origin + "/Identity/Account/Login";
                    })

                    //GetUsers
                    .addCase(getUsers.pending, (state) => {
                        state.isLoading = true;
                    })
                    .addCase(getUsers.fulfilled, (state, action) => {
                        state.users = action.payload.data;
                        state.isLoading = false;
                        //  localStorage.setItem('isAdmin', 'false');
                        // window.location.href = window.location.origin;
                    })
                    .addCase(getUsers.rejected, (state, action) => {
                        state.users = new Array();
                        localStorage.setItem('isAdmin', 'false');
                        window.location.href = window.location.origin;
                        state.isLoading = false;
                    })

                    //DeleteUsers
                    .addCase(deleteUser.pending, (state) => {
                        state.isLoading = true;
                    })
                    .addCase(deleteUser.fulfilled, (state, action) => {
                        state.users = current(state.users).filter(u => u.id != action.payload.data);
                        state.isLoading = false;
                    })
                    .addCase(deleteUser.rejected, (state, action) => {
                        state.isLoading = false;
                    })

                    //LoginAsUser
                    .addCase(loginAsUser.pending, (state) => {
                        state.isLoading = true;
                    })
                    .addCase(loginAsUser.fulfilled, (state, action) => {
                        localStorage.setItem('isAdmin', 'false');
                        window.location.href = window.location.origin;
                        state.isLoading = false;
                        // state.users = current(state.users).filter(u => u.id != action.payload.data);
                    })
                    .addCase(loginAsUser.rejected, (state, action) => {
                        state.isLoading = false;
                    })


                    //GetLogs
                    .addCase(getLogs.pending, (state) => {
                        state.isLoading = true;
                    })
                    .addCase(getLogs.fulfilled, (state, action) => {
                        state.logs = action.payload.data.logsList;
                        state.logsTotalCount = action.payload.data.totalCount;
                        state.logsCurrentPage = action.payload.data.currentPage;
                        state.isLoading = false;
                    })
                    .addCase(getLogs.rejected, (state, action) => {
                        state.logs = new Array();
                        state.isLoading = false;
                    })

                    .addCase(deleteLogs.pending, (state) => {
                        state.isLoading = true;
                    })
                    .addCase(deleteLogs.fulfilled, (state, action) => {
                        state.isLoading = false;
                    })

                    //Save article
                    .addCase(saveArticle.pending, (state) => {
                        state.isLoading = true;
                    })
                    .addCase(saveArticle.fulfilled, (state, action) => {
                        if (current(state.articlesList).some(x => x.guid == action.payload.data.guid)) {
                            state.articlesList = state.articlesList.map(pr => pr.guid == action.payload.data.guid ? action.payload.data : pr);
                        }
                        else {
                            state.articlesList.push(action.payload.data);
                        }
                        state.isLoading = false;
                    })
                    .addCase(saveArticle.rejected, (state, action) => {
                        state.isLoading = false;
                    })

                    //Get article
                    .addCase(getArticle.pending, (state) => {
                        state.isLoading = true;
                    })
                    .addCase(getArticle.fulfilled, (state, action) => {
                        state.article = action.payload.data;
                        state.isLoading = false;
                    })
                    .addCase(getArticle.rejected, (state, action) => {
                        state.isLoading = false;
                    })

                    //Get articles
                    .addCase(getArticles.pending, (state) => {
                        state.isLoading = true;
                    })
                    .addCase(getArticles.fulfilled, (state, action) => {
                        state.articlesList = action.payload.data;
                        state.isLoading = false;
                    })
                    .addCase(getArticles.rejected, (state, action) => {
                        state.isLoading = false;
                    })

                    //Delete article
                    .addCase(deleteArticle.pending, (state) => {
                        state.isLoading = true;
                    })
                    .addCase(deleteArticle.fulfilled, (state, action) => {
                        state.articlesList = state.articlesList.filter(x => x.guid != action.payload.data);
                        state.isLoading = false;
                    })
                    .addCase(deleteArticle.rejected, (state, action) => {
                        state.isLoading = false;
                    })

                //ScheduleSendings
                    .addCase(scheduleSendings.fulfilled, (state, action) => {
                        console.log("Sendings scheduled");
                    })
            }
    }
);

export const usersList = (state) => state.admin.users;
export const logsList = (state) => state.admin.logs;
export const logsTotal = (state) => state.admin.logsTotalCount;
export const currentPage = (state) => state.admin.logsCurrentPage;
export const loadArticle = (state) => state.admin.article;
export const loadArticles = (state) => state.admin.articlesList;
export const isArticleEditOpen = (state) => state.admin.isOpenEditArticle;
export const aLoading = (state) => state.admin.isLoading;


export const { toggleEditArticle } = adminSlice.actions

export default adminSlice.reducer;