import * as React from 'react';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { UncontrolledAlert } from 'reactstrap';
import { message, setMessage } from '../features/common/commonSlice';

export default function Alert() {
    const dispatch = useDispatch();
    const messageData = useSelector(message);
    const [isMessageVisible, changeMessageVisibility] = useState(false);

    useEffect(() => {
        let timeout;
        changeMessageVisibility(messageData.isVisible);
        if (isMessageVisible) {
            clearTimeout(timeout);
            timeout = null;
        } 
        timeout = window.setTimeout(() => {
            closeMessage();
        }, 3000);
        return () => {
            clearTimeout(timeout);
        };
    }, [messageData]);

    const closeMessage = () => {
        changeMessageVisibility(false);
        dispatch(setMessage(null));
    };
    return (
        <UncontrolledAlert className="overlay-alert" color={messageData.type == "error" ? "danger" : messageData.type} isOpen={isMessageVisible} toggle={closeMessage}>
            {messageData.message}
        </UncontrolledAlert >
        );
}