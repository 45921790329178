export const en = {
    translation: {
        nav: {
            help: {
                calendar: 'Calendar',
                projects: 'Projects',
                kanban: 'Kanban board',
                weight: 'Weight control',
                wiki: 'Wiki',
                user: 'User',
                help: 'Help',
                letters: 'Messages',
                exit: 'Logout',
            }
        },
        controls: {
            button: {
                add: 'Add',
                delete: 'Delete',
                edit: 'Edit',
                showall: 'Show all',
                hideall: 'Hide all',
                show: 'Show',
                hide: 'Hide',
                cancel: 'Cancel',
                save: 'Save',
            },
            modal: {
                cancel: 'Cancel',
                yes: 'Yes',
                confirmation: 'Confirmation',
                save: 'Save',
                saveandclose: 'Save and close',
                close: 'Close',
            }
        },
        errors: {
            404: 'Not found!',
        },
        cookie: 'By continuing to use the site, you consent to the processing of cookies',
        articles: {
            select: 'Click on the section icon in the diagram below to learn more about it:',
        },
        calendar: {
            month: 'Month',
            week: 'Week',
            day: 'Day',
            taskSwitcher: {
                all: 'All',
                tasks: 'Tasks',
                events: 'Events',
            },
            events: 'Events: ',
            back: 'Back',
            forward: 'Forward',
            modal: {
                delete: {
                    deleteRepeatQuestion: 'This is a repeating event, do you want to delete the entire setting or just this particular event?',
                    answerDeleteSetting: 'Delete setting',
                    answerDeleteCurrent: 'Delete current event',
                },
                edit: {
                    emptyWarning: 'Can not save empty event!',
                    edit: 'Event editing',
                    create: 'Event creating',
                    name: 'Name',
                    repeatEvent: 'Repeat event:',
                },
            },
            addEvent: 'Add Event',
            editEvent: 'Edit event',
            deleteEvent: 'Delete Event',
            confirmDelete: 'Are you sure you want to delete the event?',
        },
        help: {
            freeText: 'The project is absolutely free and is under development. Here you can help in its evolution.',
            yandexMoney: 'Yoomoney - ',
            form: 'or via the form: ',
            suggestions: 'Suggestions and ideas for improvement, as well as bug reports, can be sent to: '
        },
        letters: {
            create: 'Create letter',
            definition: 'Letter creation (Internal mail - only on the site)',
            header: 'Header',
            text: 'Text',
            send: 'Send',
            to: 'to: ',
            from: 'from: ',
            acceptInvitation: 'Accept invitation',
            search: 'Search',
        },
        projects: {
            deleteConfirmation: 'Are you sure you want to delete the project?',
            edit: 'Edit project',
            create: 'Create project',
            group: {
                withoutGroup: 'All',
                projectGroup: 'Project group:',
                projectParticipants: 'Project participants:',
            },
            creatingProject: 'Project creation',
            editingProject: 'Project editing',
            projectName: 'Project name',
            projectShortName: 'Short name',
            projectLabel: 'Project label:',
            projectsLabels: 'Project labels:',
            addLabel: 'Add label',
            mainSettings: 'Main settings:',
            daysAsProjects: 'Show calendar days as projects',
            warningEmpty: 'You cannot save an empty project!',
            mainEdit: 'Main',
            groupEdit: 'Group',
            labelEdit: 'Labels',
            kanban: {
                unassigned: 'Unassigned',
                participants: 'Participants',
                created: 'Created',
                inProgress: 'In progress',
                done: 'Done',
                taskExtendedEdit: 'Расширенная настройка задачи',
            },
            settings: 'Settings',
            labelMaxError: 'Maximum number of labels exceeded',
        },
        tasks: {
            deleteEvent: 'Delete event',
            confirmDeleteEvent: 'Are you sure you want to delete the event?',
            deleteTask: 'Delete event',
            confirmDeleteTask: 'Are you sure you want to delete the task?',
            editEvent: 'Edit event',
            taskCreation: 'Create task',
            teskEditing: 'Edit task',
            name: 'Name',
            executor: 'Executor',
            warningEmtyTask: 'You cannot save an empty task!',
            warningEmptyEvent: 'You cannot save an empty event!',
            taskExtendedEdit: 'Advanced task settings',
        },
        groups: {
            editGroup: 'Edit group',
            deleteGroup: 'Delete group',
            confirmDeleteGroup: 'Are you sure you want to delete the group?',
            confirmed: 'Confirmed',
            awaitingConfirmation: 'Awaiting confirmation',
            deleteFromGroup: 'Remove from group',
            deleteParticipantConfirmation: 'Are you sure you want to remove a member?',
            settings: 'Group settings',
            name: 'Name',
            groupMembers: 'Group members',
            cancel: 'Cancel',
            addParticipant: 'Add member',
            userGroups: 'User groups',
            addGroup: 'Add group',
        },
        users: {
            userSettings: 'User settings',
            userName: 'User name',
            name: 'Name',
            currentPassword: 'Current password',
            password: 'Password',
            repeatPassword: 'Repeat password',
            sendEventNotificationsEmail: 'Send notifications about calendar events by email',
            settings: {
                main: 'Main',
                groups: 'Groups',
            }
        },
        weight: {
            deleteColumnConfirmation: 'Are you sure you want to delete the entire column of data?',
            table: 'Table',
            chart: 'Chart',
            actions: 'Actions',
            addQuantity: 'Add quantitative indicator',
            addLine: 'Add line',
            cleanTable: 'Clear table',
            cleanTableConfirmation: 'Are you sure you want to delete all data?',
            date: 'Date',
            dateEdit: 'Edit date',
        },
        constances: {
            all: "All",
            errorMessage: "An error occurred while accessing the service! Please try again later.",
            weekDays: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
            weekDaysShort: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        }
    }
};
export default en;