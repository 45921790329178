export const other = {
    translation: {
        nav: {
            help: {
                calendar: '',
                projects: '',
                kanban: '',
                weight: '',
                wiki: '',
                user: '',
                help: '',
                letters: '',
                exit: '',
            }
        },
        controls: {
            button: {
                add: '',
                delete: '',
                edit: '',
                showall: '',
                hideall: '',
                show: '',
                hide: '',
                cancel: '',
            },
            modal: {
                cancel: '',
                yes: '',
                confirmation: '',
                save: '',
                saveandclose: '',
                close: '',
                save: '',
            }
        },
        errors: {
            404: '',
        },
        cookie: '',
        articles: {
            select: '',
        },
        calendar: {
            month: '',
            week: '',
            day: '',
            taskSwitcher: {
                all: '',
                tasks: '',
                events: '',
            },
            events: ' ',
            back: '',
            forward: '',
            modal: {
                delete: {
                    deleteRepeatQuestion: '',
                    answerDeleteSetting: '',
                    answerDeleteCurrent: '',
                },
                edit: {
                    emptyWarning: '',
                    edit: '',
                    create: '',
                    name: '',
                    repeatEvent: ':',
                },
            },
            addEvent: '',
            editEvent: '',
            deleteEvent: '',
            confirmDelete: '?',
        },
        help: {
            freeText: '',
            yandexMoney: '',
            form: ': ',
            suggestions: ': '
        },
        letters: {
            create: '',
            definition: '',
            header: '',
            text: '',
            send: '',
            to: ': ',
            from: ': ',
            acceptInvitation: '',
            search: '',
        },
        projects: {
            deleteConfirmation: '',
            edit: '',
            create: '',
            group: {
                withoutGroup: '',
                projectGroup: '',
                projectParticipants: '',
            },
            creatingProject: '',
            editingProject: '',
            projectName: '',
            projectShortName: '',
            projectLabel: ':',
            projectsLabels: ':',
            addLabel: '',
            mainSettings: ':',
            daysAsProjects: '',
            warningEmpty: '',
            mainEdit: '',
            groupEdit: '',
            labelEdit: '',
            kanban: {
                unassigned: '',
                participants: '',
                created: '',
                inProgress: '',
                done: '',
                taskExtendedEdit: '',
            },
            settings: '',
            labelMaxError: '',
        },
        tasks: {
            deleteEvent: '',
            confirmDeleteEvent: '?',
            deleteTask: '',
            confirmDeleteTask: '?',
            editEvent: '',
            taskCreation: '',
            teskEditing: '',
            name: '',
            executor: '',
            warningEmtyTask: '!',
            warningEmptyEvent: '!',
            taskExtendedEdit: '',
        },
        groups: {
            editGroup: '',
            deleteGroup: '',
            confirmDeleteGroup: '?',
            confirmed: '',
            awaitingConfirmation: '',
            deleteFromGroup: '',
            deleteParticipantConfirmation: '?',
            settings: '',
            name: '',
            groupMembers: '',
            cancel: '',
            addParticipant: '',
            userGroups: '',
            addGroup: '',
        },
        users: {
            userSettings: '',
            userName: '',
            name: '',
            currentPassword: '',
            password: '',
            repeatPassword: '',
            sendEventNotificationsEmail: '',
            settings: {
                main: '',
                groups: '',
            }
        },
        weight: {
            deleteColumnConfirmation: '?',
            table: '',
            chart: '',
            actions: '',
            addQuantity: '',
            addLine: '',
            cleanTable: '',
            cleanTableConfirmation: '?',
            date: '',
            dateEdit: '',
        },
        constances: {
            all: "",
            errorMessage: "",
            weekDays: ["", "", "", "", "", "", ""],
            weekDaysShort: ["", "", "", "", "", "", ""],
        }
    }
};
export default other;