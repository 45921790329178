import * as React from 'react';
import Button from '../../components/button';
import { scheduleSendings } from './adminSlice';

const Sendings = () => {
    return (<>
        <Button type="ok" text="Schedule sendings" action={scheduleSendings} params={null} />
    </>);
}

export default Sendings;