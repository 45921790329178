import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pickTextColorBasedOnBgColorSimple } from '../../../functions/functions';
import { loadProjects, loadSelectedProjects, setSelectedProjectsGuids } from '../projectsSlice';

const ProjectBlock = () => {
    const dispatch = useDispatch();
    var projects = useSelector(loadProjects);
    var selectedGuids = useSelector(loadSelectedProjects);
    const changeSelectedProjects = (guid) => {
        dispatch(setSelectedProjectsGuids( selectedGuids ? (selectedGuids.indexOf(guid) >= 0 ? selectedGuids.filter(x => x != guid)
            : selectedGuids.concat(guid)) : new Array(guid)));
    }

    return (<>
        <div className="labels-block">
            {
                projects && projects.map(x =>
                    <div key={"project_" + x.guid }
                        className={"noselect label-link" + (selectedGuids && selectedGuids.indexOf(x.guid)>=0 ? " selected" : "")}
                      //  style={{ backgroundColor: x.color, color: pickTextColorBasedOnBgColorSimple(x.color, "white", "black") }}
                        onClick={() => changeSelectedProjects(x.guid)}>
                        {x.name}
                    </div>
                )}
        </div>
    </>);
}

export default ProjectBlock;