import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/button';
import { calendarBack, calendarForward } from '../projectsSlice';

const ProjectCalendarBackForward = () => {
    const { t } = useTranslation();
    return (<>
        <div className="calendar-back-forward">
            <Button type="left" className="small-button"
                action={calendarBack}
                help={t('calendar.back')} />
            <Button type="right" className="small-button"
                action={calendarForward}
                help={t('calendar.forward')} />
        </div>
    </>);
}

export default ProjectCalendarBackForward;