import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Button from './button';

const ShowHideButton = (props) => {
    const { t } = useTranslation();
    return (<>
        <Button id={"showHideButton_" + props.id ? props.id : props.guid}
            type={props.hidden ? "show" : "hide"}
            guid={props.guid}
            className="small-button" action={props.action} 
            help={props.hidden ? t('controls.button.show') : t('controls.button.hide')}
        />
    </>);
}

export default ShowHideButton;