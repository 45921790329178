import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Input } from 'reactstrap';
import Button from '../../../components/button';
import CheckEnter from '../../../functions/controls';
import { getData, saveValue, setEditableCell } from '../weightSlice';

const EditValue = (props: any) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [value, setValue] = useState(props.cell);

    const saveEditValue = async () => {
        await dispatch(saveValue(value));
        await dispatch(setEditableCell({}));
    }

    const cancelEditValue = async () => {
        await dispatch(setEditableCell({}));
        dispatch(getData());
    }

    const setValueInput = (val: any) => {
        val = val.toString().replace(",", ".");
        if (!isNaN(val)) {
            setValue({ ...value, value: val });
        }
    }
    return (<>
        <div className="weight-edit">
            <Input value={value.value} className="weight-edit-name" type="text"
                onChange={event => setValueInput(event.target.value)}
                onKeyPress={event => CheckEnter(event, saveEditValue)} autoFocus={true} />
            <div className="action-button-group weight-button-group">
                <Button guid={value.guid} type="ok" action={saveEditValue} help={t('controls.button.save')}
                    className="small-button" />
                <Button guid={value.guid} type="cancel" action={cancelEditValue} help={t('controls.button.cancel')}
                    className="small-button" />
            </div>
        </div>
    </>);
};

export default EditValue;