import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { calendarMode } from './calendarSlice';

const CalendarSwitcher = () => {
    const { t } = useTranslation();
    const mode = useSelector(calendarMode);

    return (<>
        <div className="calendar-switcher">
            <a href="/Calendar/Month"><div className={"calendar-switcher-item noselect " + (mode == "1" ? "calendar-switcher-selected" : "")}
            >{t('calendar.month')}</div></a>
            <a href="/Calendar/Week"><div className={"calendar-switcher-item noselect " + (mode == "2" ? "calendar-switcher-selected" : "")}
            >{t('calendar.week')}</div></a>
            <a href="/Calendar/Day"><div className={"calendar-switcher-item noselect " + (mode == "3" ? "calendar-switcher-selected" : "")}
            >{t('calendar.day')}</div></a>
        </div>
    </>);
}

export default CalendarSwitcher;