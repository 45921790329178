import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { isConfirmOpen, actionConfirmed, confirmText, toggleConfirmWindow } from '../features/common/commonSlice';

export default function ConfirmModal(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    var isConfirm = useSelector(isConfirmOpen);
    var action = useSelector(actionConfirmed);
    var confirm = useSelector(confirmText);
    return (
        <Modal animation={false} isOpen={isConfirm}>
            <div className="white-background">
                <ModalHeader>
                    {t('controls.modal.confirmation')}
                </ModalHeader>
                <ModalBody>
                    {confirm}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => { dispatch(action.action(action.params)); dispatch(toggleConfirmWindow(isConfirm)); }}>
                        {t('controls.modal.yes')}
                    </Button>
                    {' '}
                    <Button onClick={() => dispatch(toggleConfirmWindow(isConfirm))}>
                        {t('controls.modal.cancel')}
                    </Button>
                </ModalFooter>
            </div>
        </Modal>
    );
}