import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../button';
import { loadHidden, toggleShowHideAll } from '../../features/project/projectsSlice';
import { toggleShowHideCalendarAll } from '../../features/calendar/calendarSlice';
import { useTranslation } from 'react-i18next';

const ShowHideButton = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const hiddenAll = useSelector(loadHidden);
    const toggleShowHide = () => {
        dispatch(toggleShowHideAll(null));
        dispatch(toggleShowHideCalendarAll(null));
    }
    return (<>
        <Button id="showHideButton" type={hiddenAll ? "show" : "hide"} className="big-button" action={toggleShowHide}
            help={hiddenAll ? t('controls.button.showall') : t('controls.button.hideall')}
        />
    </>);
}

export default ShowHideButton;