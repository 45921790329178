import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { loadedGroups } from '../../user/userSlice';
import { loadSelectedGroup, setSelectedGroup } from '../projectsSlice';

const GroupBlock = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const groups = useSelector(loadedGroups);
    var selectedGroup = useSelector(loadSelectedGroup);

    const changeSelectedGroup = (guid) => {
        dispatch(setSelectedGroup(guid));
    }

    return (<>
        <div className="groups-block">
            {groups &&
                <div key={"group_none"}
                    className={"noselect group-link" + (selectedGroup == null ? " selected" : "")}
                    onClick={() => changeSelectedGroup(null)}>
                    {t('projects.group.withoutGroup')}
                </div>
            }
            {groups && groups.map(x =>
                <div key={"group_" + x.guid}
                    className={"noselect group-link" + (x.guid == selectedGroup ? " selected" : "")}
                    onClick={() => changeSelectedGroup(x.guid)}>
                    {x.name}
                </div>
            )}
        </div>
    </>);
}

export default GroupBlock;