import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Button from '../../../components/button';
import DropDown from '../../../components/dropDown';
import isEmpty, { formatDateFromShortDate } from '../../../functions/functions';
import { saveEvent } from '../../calendar/calendarSlice';
import { setMessage } from '../../common/commonSlice';
import { getProjectUsers, loadProjectUsers } from '../../project/projectsSlice';
import { loadSettingsOpen, loadTask, saveTask, toggleEditTask, toggleExtendedEditTask } from '../tasksSlice';

export default function EditTaskModal(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isCalendar = props.isCalendar;
    const users = useSelector(loadProjectUsers);
    const isOpen = useSelector(loadSettingsOpen);
    const loadedTask = useSelector(loadTask);
    var [task, setTask] = useState(loadedTask);
    const [executor, setExecutor] = useState({} as any);

    useEffect(() => {
        setTask(loadedTask);
        if (loadedTask && loadedTask.projectGuid) {
            dispatch(getProjectUsers(loadedTask.projectGuid));
        }
    }, [loadedTask]);

    useEffect(() => {
        if (task && task.executorId) {
            var search = users.find(x => x.userId == task.executorId);
            if (search) {
                setExecutor({ key: search.userId, value: search.name });
            }
        }
        else {
            setExecutor(null);
        }
    }, [users, task]);

    //useEffect(() => {
    //    console.log(isOpen);
    //    debugger;
    //    if (isOpen && !isEmpty(task)) {
    //        console.log("getUsers");
    //        dispatch(getProjectUsers(task.projectGuid));
    //    }
    //}, [isOpen]);

    const saveEditTask = async () => {
        if (!task.name) {
            dispatch(setMessage({
                message: isCalendar ? t('tasks.warningEmptyEvent') : t('tasks.warningEmtyTask'),
                status: 2, isVisible: true
            }));
            return;
        }
        if (isCalendar) {
            debugger;
            var event = {
                name: task.name,
                taskGuid: task.guid ? task.guid : null,
                date: formatDateFromShortDate(task.date)
            };
            await dispatch(saveEvent(event));
        }
        else {
            await dispatch(saveTask({ ...task, taskStatus: task.status }));
        }
        setTask({});
        if (isOpen) {
            dispatch(toggleExtendedEditTask(null));
        }
    };
    const cancelEditTask = () => {
        setTask({ });
        dispatch(toggleExtendedEditTask(null));
    };

    const selectExecutor = (item) => {
        setTask({ ...task, executorId: item.key });
    }
    return (<>
        <div>
            <Modal
                isOpen={isOpen}
                autoFocus={false}
                centered
                toggle={() => dispatch(toggleEditTask(null))}>
                <div className="white-background">
                    <ModalHeader toggle={function noRefCheck() { }}>
                        {task == null || task.guid == null ? t('tasks.taskCreation') : t('tasks.teskEditing')}
                    </ModalHeader>
                    <ModalBody>
                        <div>
                            <Input autoFocus={true}
                                id={"project-save-name"}
                                placeholder={t('tasks.name')}
                                onChange={event => setTask({ ...task, name: event.target.value })}
                                defaultValue={task != null ? task.name : ""}
                            />
                            {users && users.length > 1 &&
                                <>
                                <div className="subheader">{t('tasks.executor')}</div>
                                    <DropDown items={users.map(x => ({ key: x.userId, value: x.name }))}
                                        selected={executor}
                                        defaultValue={{ key: null, value: "нет" }} action={selectExecutor} />
                                </>
                            }
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="action-button-group">
                            <Button guid={!isEmpty(task) ? task.guid ? task.guid : task.projectGuid : null} type="ok" action={saveEditTask} help={t('controls.modal.save')}
                                className="small-button" />
                            <Button type="cancel" action={cancelEditTask} params={null} help={t('controls.modal.cancel')}
                                className="small-button" />
                        </div>
                    </ModalFooter>
                </div>
            </Modal>
        </div>
    </>);
};