import * as React from 'react';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isLoading } from '../features/project/projectsSlice';
import { articlesIsLoading } from '../features/article/articleSlice';
import { cIsLoading } from '../features/calendar/calendarSlice';
import { aLoading } from '../features/admin/adminSlice';
import { wIsLoading } from '../features/weight/weightSlice';
import { userIsLoading } from '../features/user/userSlice';
import { tIsLoading } from '../features/task/tasksSlice';


export default function Loading(props) {
    const project_loading = useSelector(isLoading);
    const article_loading = useSelector(articlesIsLoading);
    const calendar_loading = useSelector(cIsLoading);
    const weight_loading = useSelector(wIsLoading);
    const a_loading = useSelector(aLoading);
    const user_loading = useSelector(userIsLoading);
    const task_loading = useSelector(tIsLoading);
    //useEffect(() => {

    //}, [project_loading]);
    var className = "";
    var isShow = props.isForse || project_loading || article_loading || calendar_loading || a_loading || weight_loading
        || user_loading || task_loading;

    if (isShow) {
        className = "loading-central-panel";
    }
    if (isShow) {
        return (
            <div className={"loading-block " + className}>
                <div className="hypnotic"></div>
            </div>
        );
    }
    else {
        return (<></>);
    }
}