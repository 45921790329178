import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import i18n from '../../i18n';

export interface messageType {
    message: string,
    isVisible: boolean,
    type: string
}

/*projects*/
export const saveProject = createAsyncThunk(
    'projects/saveProject',
    async (project: object) => {
        const data = await fetch('/Projects/SaveProject',
            {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    'Accept': 'application/json',
                },
                body: JSON.stringify(project)
            });

        const json = await data.json();
        return json;
    });

export const changeProjectPosition = createAsyncThunk(
    'projects/changeProjectPosition',
    async (props: any) => {
        const { guid, position } = props;
        const data = await fetch("/Projects/ChangeProjectPosition?" + new URLSearchParams({
            guid: props.guid,
            position: position
        }));
        const json = await data.json();
        return json;
    });

export const deleteProject = createAsyncThunk(
    'projects/deleteProject',
    async (guid: string) => {
        const data = await fetch("/Projects/DeleteProject?" + new URLSearchParams({
            guid: guid,
        }));

        const json = await data.json();
        return json;
    });

export const getProjects = createAsyncThunk(
    'projects/getProjects',
    async (props: any) => {
        const data = await fetch("/Projects/GetProjects?" + new URLSearchParams({
            labelGuids: props && props.labelGuids,
            groupGuid: props && props.groupGuid,
        }));
        const json = await data.json();
        return json;
    });

export const getProject = createAsyncThunk(
    'projects/getProject',
    async (guid: string) => {
        const data = await fetch("/Projects/GetProject?" + new URLSearchParams({
            guid: guid,
        }));
        const json = await data.json();
        return json;
    });

/*labels*/
export const getLabels = createAsyncThunk(
    'projects/getLabels',
    async () => {
        const data = await fetch("/Projects/GetLabels",
            {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    'Accept': 'application/json',
                },
            });
        const json = await data.json();
        return json;
    });

export const saveLabel = createAsyncThunk(
    'projects/saveLabel',
    async (label: object) => {
        const data = await fetch('/Projects/SaveLabel',
            {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    'Accept': 'application/json',
                },
                body: JSON.stringify(label)
            });

        const json = await data.json();
        return json;
    });

export const deleteLabel = createAsyncThunk(
    'projects/deleteLabel',
    async (guid: string) => {
        const data = await fetch("/Projects/DeleteLabel?" + new URLSearchParams({
            guid: guid,
        }));
        const json = await data.json();
        return json;
    });

/*settings*/
export const getSettings = createAsyncThunk(
    'projects/getSettings',
    async () => {
        const data = await fetch("/Projects/GetSettings",
            {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    'Accept': 'application/json',
                },
            });
        const json = await data.json();
        return json;
    });

export const saveSettings = createAsyncThunk(
    'projects/saveSettings',
    async (settings: object) => {
        const data = await fetch('/Projects/SaveSettings',
            {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    'Accept': 'application/json',
                },
                body: JSON.stringify(settings)
            });

        const json = await data.json();
        return json;
    });

/*group*/
export const getProjectUsers = createAsyncThunk(
    'projects/getProjectUsers',
    async (guid: string) => {
        const data = await fetch("/Projects/GetProjectUsers?" + new URLSearchParams({
            guid: guid,
        }));
        const json = await data.json();
        return json;
    });


const setMessageData = (payload: { message: any; status: number; }) => {
    if (payload == null || !payload.message) {
        return {}
    }
    return {
        message: payload.message,
        status: payload.status,
        isVisible: payload.message ? true : false
    }
}

const setErrorMessageData = () => {
    return {
        message: i18n.t('constances.errorMessage'),
        type: "error",
        isVisible: true
    }
}

var ProjectList: any[] = [];
var Project: any = {};
var Labels: any[] = [];

export const projectsSlice = createSlice({
    name: "projects",
    initialState: {
        projectsList: ProjectList,
        project: Project,
        labels: new Array(),
        isLoadingProjects: false,
        isOpenEdit: false,
        isOpenSettings: false,
        messageData: {},
        hiddenAll: window.screen.width <= 500,
        selectedLabelGuids: [],
        selectedGroupGuid: null,
        selectedProjectsGuids: [],
        editingLabel: null,
        isNeedReload: false,
        settings: { isShowCalendarDays: false, IsExtendedTaskSettings: false, IsTaskNameInTwoStrings: false },
        editingProject: {} as any,
        users: [] as any[],
        calendarStep: 0,

    },
    reducers: {
        toggleEditProject: (state, action) => {
            state.isOpenEdit = !state.isOpenEdit;
            state.project = action.payload;
        },
        pClearMessage: (state, action) => {
            state.messageData = {};
        },
        setHiddenAllFalse: (state, action) => {
            state.hiddenAll = false;
        },
        toggleShowHideAll: (state, action) => {
            state.hiddenAll = !state.hiddenAll;
            state.projectsList = current(state.projectsList).map(pr => ({ ...pr, isHidden: state.hiddenAll }));
        },
        toggleShowHide: (state, action) => {
            state.hiddenAll = false;
            var guid = action.payload;
            state.projectsList = current(state.projectsList).map(pr => pr.guid == guid ? { ...pr, isHidden: !pr.isHidden } : pr);
        },
        toggleProjectSettings: (state, action) => {
            state.isOpenSettings = !state.isOpenSettings;
        },
        toggleEditLabel: (state, action) => {
            if (state.labels &&(!action.payload || action.payload.name == "") && current(state.labels).length >= 6) {
                state.messageData = setMessageData({ message: i18n.t('projects.labelMaxError'), status: 3 });
                return;
            }
            state.editingLabel = action.payload;
        },
        closeEditLabel: (state, action) => {
            state.editingLabel = null;
        },
        setSelectedLabels: (state, action) => {
            state.selectedLabelGuids = action.payload;
            state.selectedProjectsGuids = [];
        },
        setSelectedGroup: (state, action) => {
            state.selectedGroupGuid = action.payload;
            state.selectedProjectsGuids = [];
        },
        setSelectedProjectsGuids: (state, action) => {
            state.selectedProjectsGuids = action.payload;
        },
        setNeedReload: (state, action) => {
            state.isNeedReload = true;
        },
        setEditProject: (state, action) => {
            state.editingProject = action.payload;
        },
        calendarBack: (state, action) => {
            state.calendarStep = state.calendarStep - 1;
/*            state.isNeedUpdate = true;*/
        },
        calendarForward: (state, action) => {
            state.calendarStep = state.calendarStep + 1;
        },
    },
    extraReducers:
        (builder) => {
            builder
                .addCase(saveProject.pending, (state) => {
                })
                .addCase(saveProject.fulfilled, (state, action) => {
                    state.messageData = setMessageData(action.payload);
                    if (current(state.projectsList).some(x => x.guid == action.payload.data.guid)) {
                        state.projectsList = state.projectsList.map(pr => pr.guid == action.payload.data.guid ? action.payload.data : pr);
                    }
                    else {
                        if (state.selectedLabelGuids.indexOf(action.payload.data.labelGuid) >= 0 || state.selectedLabelGuids.length === 0 ) {
                            state.projectsList.push(action.payload.data);
                        }
                    }
                })
                .addCase(saveProject.rejected, (state, action) => {
                    state.messageData = setErrorMessageData();
                })
                .addCase(deleteProject.pending, (state) => {
                })
                .addCase(deleteProject.fulfilled, (state, action) => {
                    state.projectsList = current(state.projectsList).filter(pr => pr.guid != action.payload.data);
                    state.messageData = setMessageData(action.payload);
                })
                .addCase(deleteProject.rejected, (state, action) => {
                    state.messageData = setErrorMessageData();
                })
                .addCase(getProjects.pending, (state) => {
                    state.isLoadingProjects = true;
                    localStorage.setItem('isLoading', 'true');
                })
                .addCase(getProjects.fulfilled, (state, action) => {
                    state.isLoadingProjects = false;
                    state.projectsList = action.payload.data;
                    if (state.hiddenAll) {
                        state.projectsList = state.projectsList.map(x => ({ ...x, isHidden: true }));
                    }
                    state.messageData = setMessageData(action.payload);
                    state.isNeedReload = false;
                })
                .addCase(getProjects.rejected, (state, action) => {
                    state.isLoadingProjects = false;
                    state.messageData = setErrorMessageData();
                    localStorage.setItem('isLoading', 'false');
                    window.location.href = window.location.origin + "/Identity/Account/Login";
                })

                .addCase(getProject.pending, (state) => {
                    state.project = null;
                })
                .addCase(getProject.fulfilled, (state, action) => {
                    state.project = action.payload.data;
                
                    //state.projectsList = state.projectsList.map(x => x.guid == state.project.guid ?
                    //    state.project : x);

                    if (current(state.projectsList).some(x => x.guid == action.payload.data.guid)) {
                        state.projectsList = state.projectsList.map(pr => pr.guid == action.payload.data.guid ? action.payload.data : pr);
                    }
                    else {
                        state.projectsList.push(action.payload.data);
                    }
                })
                .addCase(getProject.rejected, (state, action) => {
                    state.project = null;
                })
                .addCase(changeProjectPosition.pending, (state) => {
                    state.isLoadingProjects = true;
                })
                .addCase(changeProjectPosition.fulfilled, (state, action) => {
                    state.isLoadingProjects = false;
                    state.projectsList = action.payload.data;
                })
                .addCase(changeProjectPosition.rejected, (state, action) => {
                    state.isLoadingProjects = false;
                    state.projectsList = [];
                })
                .addCase(getLabels.fulfilled, (state, action) => {
                    state.labels = action.payload.data;
                    if (state.labels && state.labels.length > 0) {
                        state.labels.unshift({ color: "#FFC80F", guid: null, name: i18n.t('constances.all')});
                    }
                })
                .addCase(saveLabel.fulfilled, (state, action) => {
                    if (action.payload.data) {
                        if (state.labels.some(x => x.guid == action.payload.data.guid)) {
                            state.labels = state.labels.map(pr => pr.guid == action.payload.data.guid ? action.payload.data : pr);
                        }
                        else {
                            state.labels.push(action.payload.data);
                        }
                        state.editingLabel = null;
                        if (!state.labels.find(x => x.guid == null )) {
                            state.labels.unshift({ color: "#1B91B1", guid: null, name: i18n.t('constances.all')});
                        }
                    }
                    state.messageData = setMessageData(action.payload);
                })
                .addCase(deleteLabel.fulfilled, (state, action) => {
                    if (action.payload.data) {
                        state.labels = current(state.labels).filter(pr => pr.guid != action.payload.data);
                    }
                    if (state.labels.length == 1) {
                        state.labels = [];
                    }
                    if (state.selectedLabelGuids.indexOf(action.payload.data) >= 0) {
                        state.selectedLabelGuids = state.selectedLabelGuids.filter(x => x != action.payload.data);
                    }
                    state.messageData = setMessageData(action.payload);
                })

                /*settings*/
                .addCase(getSettings.fulfilled, (state, action) => {
                    state.settings = action.payload.data;
                    console.log(state.settings);
                })
                .addCase(saveSettings.fulfilled, (state, action) => {
                    if (action.payload.data) {
                        state.settings = action.payload.data;
                    }
                    state.messageData = setMessageData(action.payload);
                })
                /*group*/
                .addCase(getProjectUsers.fulfilled, (state, action) => {
                    if (action.payload.data) {
                        state.users = action.payload.data;
                    }
                    state.messageData = setMessageData(action.payload);
                })
        },
});

export const loadProjects = (state: { project: { projectsList: any; }; }) => state.project.projectsList;
export const isLoading = (state: { project: { isLoadingProjects: any; }; }) => {
    return state.project.isLoadingProjects;
}

export const isOpenEditProject = (state: { project: { isOpenEdit: any; }; }) => state.project.isOpenEdit;
export const isOpenProjectSettings = (state) => state.project.isOpenSettings;

export const pMessage = (state: { project: { messageData: any; }; }) => state.project.messageData;

export const loadProject = (state: { project: { project: any; }; }) => state.project.project;

export const loadLabels = (state) => state.project.labels;

export const loadHidden = (state) => state.project.hiddenAll;

export const loadProjectHidden = (state) => state.project.hidden;

export const editLabel = (state) => state.project.editingLabel;
export const loadSelectedLabels = (state) => state.project.selectedLabelGuids;
export const loadSelectedGroup = (state) => state.project.selectedGroupGuid;
export const loadSelectedProjects = (state) => state.project.selectedProjectsGuids;

export const loadNeedReload = (state) => state.project.isNeedReload;
export const projectsSettings = (state) => state.project.settings;

export const loadEditingProject = (state) => state.project.editingProject;

export const loadCalendarStep = (state) => state.project.calendarStep;

export const loadProjectUsers = (state) => state.project.users;
export const { toggleEditProject, pClearMessage, toggleShowHideAll, toggleShowHide,
    toggleProjectSettings, toggleEditLabel, closeEditLabel, setSelectedLabels,
    setNeedReload, setHiddenAllFalse, setEditProject, setSelectedGroup, setSelectedProjectsGuids, calendarBack, calendarForward } = projectsSlice.actions

export default projectsSlice.reducer;