import * as React from 'react';
import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from '../../../functions/functions';
import EditTaskModal from '../../task/components/editExtendedTaskModal';
import { changeStatus, getTasks, loadTasksList, saveTask } from '../../task/tasksSlice';
import { currentUser, getGroupUsers, getUserGroups, loadGroupUsers } from '../../user/userSlice';
import GroupBlock from '../components/groupBlock';
import LabelBlock from '../components/labelBlock';
import ProjectBlock from '../components/projectBlock';
import { getLabels, getProjects, loadNeedReload, loadSelectedGroup, loadSelectedLabels, loadSelectedProjects } from '../projectsSlice';
import Task from './task';

const Kanban = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    var tasks = useSelector(loadTasksList);
    const isNeedReload = useSelector(loadNeedReload);
    const selectedLabelGuids = useSelector(loadSelectedLabels);
    const selectedGroupGuid = useSelector(loadSelectedGroup);
    var selectedProjectGuids = useSelector(loadSelectedProjects);
    const loadedUsers = useSelector(loadGroupUsers);
    var user = useSelector(currentUser);
    const [users, setUsers] = useState(loadedUsers);

    const [data, setData] = useState({
        createdTasks: new Array,
        inProgressTasks: new Array,
        doneTasks: new Array
    });

    useEffect(() => {
        dispatch(getTasks({ labelGuids: selectedLabelGuids, groupGuid: selectedGroupGuid, projectGuids: selectedProjectGuids }));
        dispatch(getLabels());
        dispatch(getUserGroups());
        dispatch(getProjects({ labelGuids: selectedLabelGuids, groupGuid: selectedGroupGuid }));
        if (selectedGroupGuid) {
            dispatch(getGroupUsers(selectedGroupGuid));
        }
        else {
            setUsers([{userId: user, name: "" }]);
        }
    }, [dispatch, selectedLabelGuids, isNeedReload, selectedGroupGuid, selectedProjectGuids]);

    useEffect(() => {
        if (selectedGroupGuid) {
            setUsers([{ name: t('projects.kanban.unassigned'), userId: "undefined" }, ...loadedUsers]);
        }
    }, [loadedUsers]);


    const updateFunc = (guid, status) => {
        dispatch(changeStatus({ guid: guid, status: status, parentGuid: "" }));
    }

    const findStatusName = (parentId) => {
        return parentId.indexOf("inProgress") >= 0 ? 3 : parentId.indexOf("done") >= 0 ? 4 : 1;
    };

    useEffect(() => {
        if (tasks && tasks.length && users && users.length) {
            var ctasks = new Array, ptasks = new Array, dtasks = new Array;
            var aloneUser = users.length == 1;
            for (var i = 0; i < users.length; i++) {
                var userName = users[i].userId == undefined ? "undefined" : users[i].userId;
                var check = users[i].userId == "undefined" ? null : users[i].userId;
                ctasks.push({ userId: users[i].userId, tasks: tasks.filter(x => (x.executorId == check || aloneUser) && (x.status == 0 || x.status == 1)) });
                ptasks.push({ userId: users[i].userId, tasks: tasks.filter(x => (x.executorId == check || aloneUser) && x.status == 3) });
                dtasks.push({ userId: users[i].userId, tasks: tasks.filter(x => (x.executorId == check || aloneUser) && x.status == 4) })
            }
            setData({
                createdTasks: ctasks ,
                inProgressTasks: ptasks,
                doneTasks: dtasks
            });
        }
        else {
            setData({
                createdTasks: [],
                inProgressTasks: [],
                doneTasks: []
            });
        }
    }, [tasks, users]);

    //useEffect(() => {
    //     dnd(updateFunc, findStatusName, onDragEnd);
    //}, [data]);

    const removeFromList = (list, index) => {
        const result = Array.from(list);
        const [removed] = result.splice(index, 1);
        return [removed, result];
    }


    const addToList = (list, index, element) => {
        const result = Array.from(list);
        result.splice(index, 0, element);
        return result;
    }

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        var listName = result.source.droppableId.substring(0, result.source.droppableId.indexOf("_"));
        var sUser = result.source.droppableId.substring(result.source.droppableId.indexOf("_") + 1, result.source.droppableId.length);
        var listCopy = { ...data };

        const sourceList = listCopy[listName].find(x => x.userId == sUser)?.tasks;
        const [removedElement, newSourceList]: any = removeFromList(sourceList, result.source.index);
        listCopy[listName] = listCopy[listName].map(x => x.userId == sUser ? {...x, tasks: newSourceList} : x);
        var dListName = result.destination.droppableId.substring(0, result.destination.droppableId.indexOf("_"));
        var dUser = result.destination.droppableId.substring(result.destination.droppableId.indexOf("_") + 1, result.destination.droppableId.length);
        const destinationList = listCopy[dListName].find(x => x.userId == dUser)?.tasks;
        var temp = addToList(destinationList, result.destination.index, removedElement);
        listCopy[dListName] = listCopy[dListName].map(x => x.userId === dUser ? { ...x, tasks: temp } : x);

        setData(listCopy);
        if (sUser != dUser && listName != dListName) {
            dispatch(saveTask({ ...removedElement, executorId: dUser == "undefined" ? null : dUser, taskStatus: findStatusName(dListName) }));
           //  dispatch(changeStatus({ guid: result.draggableId, status: findStatusName(result.destination.droppableId), parentGuid: "" }));
        }
        else if (sUser != dUser) {
            dispatch(saveTask({ ...removedElement, executorId: dUser == "undefined" ? null : dUser }));
        }
        else if (listName != dListName) {
            dispatch(changeStatus({ guid: result.draggableId, status: findStatusName(dListName), parentGuid: "" }));
        }        
    };

    return (<>
        <div>
            <LabelBlock />
            <GroupBlock />
            <ProjectBlock />

            <div className="kanban-board parent-droppable">
                <DragDropContext onDragEnd={onDragEnd}>

                    <div className="kanban-line">
                        {selectedGroupGuid &&
                            <div className="kanban-column kanban-column-members">
                                <div className="kanban-column-title">{t('projects.kanban.participants') }</div>
                            </div>
                        }

                        <div className="kanban-column">
                            <div className="kanban-column-title">{t('projects.kanban.created')}</div>
                        </div>

                        <div className="kanban-column">
                            <div className="kanban-column-title">{t('projects.kanban.inProgress')}</div>
                        </div>

                        <div className="kanban-column">
                            <div className="kanban-column-title">{t('projects.kanban.done')}</div>
                        </div>
                    </div>


                    {users && users.map(x => (
                        <div className="kanban-line">
                            {selectedGroupGuid &&
                                <div className="kanban-column kanban-column-members">
                                    <div id="kanbanMembersColumn" className="kanban-column-content">
                                        <div>
                                            {x.name}
                                        </div>
                                    </div>
                                </div>
                            }

                            <div className="kanban-column">
                                <div id="kanbanCreatedColumn" className="kanban-column-content droppable">
                                    <Droppable droppableId={("createdTasks_" + x.userId)} >
                                        {(provided, snapshot) => (
                                            <div className="droppable-kanban"
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                            >
                                                {!isEmpty(data.createdTasks) && data.createdTasks.find(t => t.userId == x.userId)?.tasks.map((task, index) => (
                                                    <Draggable key={task.guid} draggableId={task.guid} index={index}>
                                                        {(provided, snapshot) => (
                                                            <div className="draggable-free"
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                            >
                                                                <Task task={task} />
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </div>
                            </div>

                            <div className="kanban-column">
                                <div id="kanbanInWorkColumn" className="kanban-column-content droppable">
                                    <Droppable droppableId={("inProgressTasks_" + x.userId)} >
                                        {(provided, snapshot) => (
                                            <div className="droppable-kanban"
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                            >
                                                {!isEmpty(data.inProgressTasks) && data.inProgressTasks.find(t => t.userId == x.userId)?.tasks.map((task, index) => (
                                                    <Draggable key={task.guid} draggableId={task.guid} index={index}>
                                                        {(provided, snapshot) => (
                                                            <div className="draggable-free"
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                            >
                                                                <Task task={task} />
                                                                {/*<div>{task.name}</div>*/}
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </div>
                            </div>


                            <div className="kanban-column">
                                <div id="kanbanDoneColumn" className="kanban-column-content droppable">
                                    <Droppable droppableId={("doneTasks_" + x.userId)} >
                                        {(provided, snapshot) => (
                                            <div className="droppable-kanban"
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                            >
                                                {!isEmpty(data.doneTasks) && data.doneTasks.find(t => t.userId == x.userId)?.tasks.map((task, index) => (
                                                    <Draggable key={task.guid} draggableId={task.guid} index={index}>
                                                        {(provided, snapshot) => (
                                                            <div className="draggable-free"
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                            >
                                                                <Task task={task} />
                                                                {/*<div>{task.name}</div>*/}
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </div>
                            </div>
                        </div>
                    ))}
                </DragDropContext>
            </div>
            <EditTaskModal isCalendar={false} />
        </div>
    </>);
}
export default Kanban;