import * as React from 'react';
import { useDispatch } from 'react-redux';
import Icon from '../../components/icon';
import Tooltip from '../../components/toolTip';
import { userLogout } from './userSlice';

const UserLogoutButton = (props) => {
    const dispatch = useDispatch();
    const logout = () => {

        dispatch(userLogout());
    };
    const id = "mainSettings";
    return (
        <>
            <button id={id} className="right-button big-button " onClick={() => logout()}>
                <Icon image={"/images/exit.svg"} guid={"logout"} imageName="exit" />
            </button>
            <Tooltip id={id} text={props.help} />
        </>
    );
}

export default UserLogoutButton;