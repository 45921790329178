import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import Button from '../../components/button';
import Switcher from '../../components/switcher';
import isEmpty, { formatDateToVeryShortDate, formatDateToVeryShortDateWithName } from '../../functions/functions';
import { weekDaysNames } from '../calendar/calendarSlice';
import Cell from './components/cell';
import DeleteHeader from './components/deleteHeader';
import EditDate from './components/editDate';
import EditHeader from './components/editHeader';
import WeightChart from './components/weightChart';
import {
    getData, loadData, editHeader, saveHeader, setEditableHeader, editCell,
    addNewDateLine, editDate, needUpdate, setEditableDate, deleteDate, wIsLoading, toggleShowTable, loadIsShowTable, clearTable
} from './weightSlice';

const Weight = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isShowTable = useSelector(loadIsShowTable);
    const isNeedUpdate = useSelector(needUpdate);
    const loadedData = useSelector(loadData);
    const isLoading = useSelector(wIsLoading);
    const wDaysNames = useSelector(weekDaysNames);
    const eH = useSelector(editHeader);
    const eD = useSelector(editDate);
    const [editableHeader, setHeader] = useState(eH);
    const [editableDate, setDate] = useState(eD);
    const [data, setData] = useState(loadedData);

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);

    useEffect(() => {
        if (isNeedUpdate) {
            dispatch(getData());
        }
    }, [isNeedUpdate]);

    useEffect(() => {
        setData(loadedData);
    }, [loadedData]);

    useEffect(() => { setHeader(eH); }, [eH]);
    useEffect(() => { setDate(eD); }, [eD]);

    const saveNewHeader = () => {
        dispatch(saveHeader({ guid: null, name: "" }));
    }
    const isMobile = window.screen.width <= 500;
    const switchItems = [{ Name: t('weight.table'), Action: toggleShowTable(true) }, { Name: t('weight.chart'), Action: toggleShowTable(false) }];

    const bottomref = useRef<any>();
    const handleScroll = () => {
        bottomref?.current?.scrollIntoView({ behavior: 'instant' });
    };

    useEffect(() => {
        handleScroll();
    }, [data]);
   
    return (<>
        <div className="weight-left-part">
            {isShowTable &&
                <div className="weight-actions">
                    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                        <DropdownToggle caret>{t('weight.actions')}</DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem>
                                <Button pkey={"weightAddColumn"} id="weightAddColumn" type="add" className="text-image-button"
                                    action={saveNewHeader} text={t('weight.addQuantity')} />
                            </DropdownItem>
                            <DropdownItem>
                                <Button pkey={"weightAddLine"} id="weightAddLine" type="add" className="text-image-button"
                                    action={addNewDateLine} text={t('weight.addLine')} />
                            </DropdownItem>
                            <DropdownItem>
                                <Button pkey={"weightClear"} id="weightClear" type="delete" className="text-image-button red-button"
                                    action={clearTable} text={t('weight.cleanTable')} confirmText={t('weight.cleanTableConfirmation')} />
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </div>
            }
            {isMobile &&
                <div className="weight-switcher">
                    <Switcher items={switchItems} active={switchItems[0].Name} />
                </div>
            }
            {!isLoading && isShowTable &&

                <div className="weight-panel">
                    <div className="weight-border-table">
                        <table>
                            <thead>
                                <tr>
                                    <th className="gridCell"><div className="weight-header-content">{t('weight.date')}</div></th>
                                    {!isEmpty(data) && data.headers.length && data.headers.map((header) =>
                                        <th key={"column_" + header.guid} id={"column_" + header.guid}
                                            className={"gridCell " + (editableHeader && editableHeader.guid == header.guid ? "header-edit" : "")}>
                                            {(editableHeader && editableHeader.guid == header.guid) ?
                                                <EditHeader header={header} />
                                                :
                                                <div className="weight-header-content">
                                                    <div className="weight-header-text">
                                                        {header.name}
                                                    </div>
                                                    <div className="action-button-group weight-button-group">
                                                        <Button key={"editHeader_" + header.guid} type="edit" className="small-button" guid={header.guid} action={setEditableHeader} help={t('controls.button.edit')} />
                                                        {(data.headers.length > 1) && <DeleteHeader guid={header.guid} />}
                                                    </div>
                                                </div>
                                            }
                                        </th>
                                    )}
                                </tr>
                            </thead>
                        </table>
                        <div className="weight-scroll" >
                            <table>
                                <tbody>
                                    {!isEmpty(data) && data.data && data.data.map((line) =>
                                        <tr key={"tr_" + line.date}>
                                            <td key={"td_" + line.date}>
                                                {
                                                    (editableDate == line.date) ?
                                                        <EditDate date={(line.date && line.date != "") ? new Date(line.date) : null} />
                                                        :
                                                        <div key={"date_" + line.date} className="weight-date">
                                                            {/*{line.date && isMobile ? formatDateToVeryShortDateWithName(line.date, wDaysNames) : new Date(line.date).toLocaleDateString()}*/}
                                                            {line.date  ? formatDateToVeryShortDateWithName(line.date, wDaysNames) : ""}
                                                            <div className="action-button-group weight-button-group">
                                                                <Button key={"editDate_" + line.date} type="edit" className="small-button" params={{ date: line.date }}
                                                                    action={setEditableDate} help={t('weight.dateEdit')} />
                                                                {
                                                                    data.data.length > 1 &&
                                                                    <Button pkey={"delete_button_" + line.date} type="delete" className="small-button"
                                                                        params={{ date: line.date }} action={deleteDate} help={t('controls.button.delete')} />
                                                                }
                                                            </div>

                                                        </div>
                                                }
                                            </td>

                                            {line.columns.map((cell, index) =>
                                                <>
                                                <Cell key={"cell_" + cell.cellGuid} cell={cell} line={line} /></>
                                            )}
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            <div ref={bottomref}></div>
                        </div>
                    </div>
                </div>
            }
        </div>
        {(!isShowTable || !isMobile) &&
            <div className="weight-right-part">
                <WeightChart />
            </div>
        }
    </>);
};

export default Weight;