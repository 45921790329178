import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux'
import Button from '../../../components/button';
import Icon from '../../../components/icon';
import Tooltip from '../../../components/toolTip';
import { deleteEvent } from '../../calendar/calendarSlice';
import { deleteTask } from '../tasksSlice';

export default function DeleteCalendarTaskButton(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const id = "deleteCalendarTask_" + props.guid;
    return (
        <>
            <Button id={id} pkey={id} type="delete" action={deleteEvent} params={{ guid: props.guid, isAll: false }}
                help={t('tasks.deleteEvent')} confirmText={t('tasks.confirmDeleteEvent')}
            />
        </>
    );
}