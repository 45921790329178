import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from '../../../components/checkbox';
import ScrollableText from '../../../components/scrollableText';
import { formatDateInput, formatDateToShortDate } from '../../../functions/functions';
import { changeStatus, loadEvent } from '../../calendar/calendarSlice';
import DeleteCalendarTaskButton from './DeleteCalendarTaskButton';
import EditCalendarTaskButton from './EditCalendarTaskButton';

export default function CalendarTask(props) {
    const dispatch = useDispatch();
    const loadedEvent = useSelector(loadEvent);
    const [event, setEvent] = useState(props.event);
    useEffect(() => {
        if (loadedEvent.guid == event.guid) {
            setEvent(loadedEvent);
        }
    }, [loadedEvent]);

    const setTaskInWork = () => {
        var newStatus = event.status == 3 ? 1 : 3;
        setEvent({ ...event, status: newStatus });
        dispatch(changeStatus({ guid: event.guid, status: newStatus}));
    };

    //if (!isEditMode) {
        return (

            //<li className={"task-item " + (task.status == 3 ? " task-active" : " white-background")} key={task.guid} id={ id} onDoubleClick={() => { setTaskInWork() }}>
            //    <Checkbox key={"checkBox_" + task.guid} guid={task.guid} action={changeStatus} isChecked={task.status == 4}
            //        parentGuid={task.projectGuid} isTask={true} />
            //    <ScrollableText className="task-item-name" text={task.name} width="230px" maxLength={25} />
            //    <div className="action-button-group task-button-group">
            //        <EditTaskButton task={task} />
            //        <DeleteTaskButton guid={task.guid} projectGuid={task.projectGuid} />
            //    </div>
            //</li>
            <li className={"task-item noselect " + (event.status == 3 ? " task-active" : " white-background")} key={event.guid} id={event.guid} onDoubleClick={() => { setTaskInWork() }}>
                <Checkbox key={"checkBox_" + event.guid} guid={event.guid} action={changeStatus} isChecked={event.status == 4}
                    //parentGuid={task.projectGuid}
                    isTask={true} />
                <ScrollableText className="task-item-name noselect" text={event.name} width="230px" maxLength={25} />
                <div className="action-button-group task-button-group">
                    <EditCalendarTaskButton event={{ ...event, date: formatDateInput(event.date)}} />
                    <DeleteCalendarTaskButton guid={event.guid}  />
                </div>
            </li>
        );
    }
    //else {
    //    return (
    //        <li className={"task-item white-background "} key={task.guid} >
    //            {/*<EditTask task={task} />*/}
    //        </li>
    //    );
    //}