import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Button from '../../../components/button';
import isEmpty from '../../../functions/functions';
import { editCell, setEditableCell } from '../weightSlice';
import DeleteValue from './deleteValue';
import EditValue from './editValue';

const Cell = (props) => {
    const { t } = useTranslation();
    var cell = props.cell;
    const line = props.line;
    const eC = useSelector(editCell);
    const [editableCell, setCell] = useState(eC);

    useEffect(() => { setCell(eC); }, [eC]);
    /*className = { cell.difference && cell.difference != 0 ? (cell.difference > 0 ? " positive-difference" : "negative-difference") : "" }*/
    return (<>
        <td key={"td_" + cell.cellGuid ? cell.cellGuid : (cell.guid + line.date)}>
            {(!isEmpty(editableCell)
                && (editableCell.guid && editableCell.guid == cell.cellGuid
                    || editableCell.date == line.date && editableCell.weightGuid == cell.guid)) ?
                <EditValue cell={{ weightGuid: cell.guid, guid: cell.cellGuid, date: line.date, value: cell.value > 0? cell.value.toString().replace(".", ","):"" }} />
                : cell.cellGuid && cell.value > 0 ?
                    <div className="weight-data-cell">
                        <div className="weight-data-cell-text">
                            {cell.value != 0 && cell.value.toString().replace(".", ",")}
                        </div>
                        {cell.difference != 0 && cell.difference &&
                            <div className={"weight-difference " + (cell.difference > 0 ? "positive-difference" : "negative-difference")}>
                                <div className="difference-num">
                                    {Number((cell.difference).toFixed(1))}
                                </div>
                            </div>
                        }
                        <div className="action-button-group weight-button-group">
                            <Button key={"editCell_" + cell.cellGuid} pkey={"edit_button_" + cell.cellGuid} type="edit" className="small-button"
                                params={{ weightGuid: cell.guid, guid: cell.cellGuid, date: line.date }}
                                help={t('controls.button.edit')} action={setEditableCell} />
                            <DeleteValue params={{ guid: cell.cellGuid, date: line.date }} />
                        </div>
                    </div>
                    :
                    <div className="weight-data-cell">
                        <div className="action-button-group weight-button-group add-button-group">
                            <Button pkey={"add_button_" + cell.cellGuid} type="add" className="small-button"
                                params={{ weightGuid: cell.guid, guid: cell.cellGuid, date: line.date }}
                                help={t('controls.button.add')} action={setEditableCell} />
                        </div>
                    </div>
            }
        </td>
    </>);
}

export default Cell;