import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/button';
import { deleteTask } from '../tasksSlice';

export default function DeleteTaskButton(props) {
    const { t } = useTranslation();
    const id = "deleteTask_" + props.guid;
    const task = { guid: props.guid, projectGuid: props.projectGuid };
   
    return (
        <>
            <Button id={id} pkey={id} type="delete" action={deleteTask} params={task} 
                help={t('tasks.deleteTask')} confirmText={t('tasks.confirmDeleteTask')}
            />
        </>
    );
}