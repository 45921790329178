import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

const Paging = (props) => {
    const dispatch = useDispatch();
    const action = props.action;
    const linesPerPage = props.linesPerPage ?? 10; 
    var pagesCount = Math.floor(props.totalCount / linesPerPage) + (props.totalCount % linesPerPage > 0 ? 1 : 0);
    var pages = new Array();
    for (let i = 1; i <= pagesCount; i++) {
        pages.push(i);
    }
    const currentPage = props.currentPage;

    //const filterData = {
    //    startIndex: 0,
    //    take: linesPerPage,
    //    dateFrom: props.dateFrom,
    //    dateTo: props.dateTo
    //};

    const filterData = props.filterData;

    return (
        <Pagination className="pagination">
            <PaginationItem>
                <PaginationLink onClick={() => {
                    filterData.startIndex = 0;
                    dispatch(action(filterData))
                }}
                    first
                />
            </PaginationItem>
            <PaginationItem>
                <PaginationLink onClick={() => {
                    filterData.startIndex = currentPage == 1? 0 : (currentPage - 2) * linesPerPage;
                    dispatch(action(filterData))
                }}
                    previous
                />
            </PaginationItem>
            {pages.map((page) =>
                <PaginationItem key={"logPage_" + page} active={currentPage == page}>
                    <PaginationLink onClick={() => {
                        filterData.startIndex = (page - 1) * linesPerPage;
                        dispatch(action(filterData))
                    }}>
                        {page}
                    </PaginationLink>
                </PaginationItem>
            )}
            <PaginationItem>
                <PaginationLink onClick={() => {
                    filterData.startIndex = pagesCount == currentPage ? (pagesCount - 1) * linesPerPage: (currentPage) * linesPerPage;
                    dispatch(action(filterData))
                }}
                    next
                />
            </PaginationItem>
            <PaginationItem>
                <PaginationLink onClick={() => {
                    filterData.startIndex = (pagesCount - 1) * linesPerPage;
                    dispatch(action(filterData))
                }}
                    last
                />
            </PaginationItem>
        </Pagination>
    );
}

export default Paging;