import { configureStore } from '@reduxjs/toolkit';
import projectsReducer from '../features/project/projectsSlice';
import userReducer from '../features/user/userSlice';
import taskReducer from '../features/task/tasksSlice';
import adminReducer from '../features/admin/adminSlice';
import commonReducer from '../features/common/commonSlice';
import articleReducer from '../features/article/articleSlice';
import weightReducer from '../features/weight/weightSlice';
import calendarReducer from '../features/calendar/calendarSlice';
import helpReducer from '../features/help/helpSlice';
import letterReducer from '../features/letter/lettersSlice';

export default configureStore({
    reducer: {
        project: projectsReducer,
        task: taskReducer,
        user: userReducer,
        admin: adminReducer,
        common: commonReducer,
        article: articleReducer,
        weight: weightReducer,
        calendar: calendarReducer,
        help: helpReducer,
        letter: letterReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredPaths: ["common.favicon.badge", "common.favicon.video", "common.favicon.image",
                    "common.favicon.rawImageSrc", "common.favicon.webcam", "common.favicon.setOpt", "common.favicon.reset"],
            },
        }),
},);