import { createSlice, current } from '@reduxjs/toolkit';
import Favico from '../../functions/favico.js-master/favico.js-master/favico';
import isEmpty from '../../functions/functions';

export interface messageType {
    message: string,
    isVisible: boolean,
    type: string
}

export const commonSlice = createSlice({
    name: "common",
    initialState: {
        isConfirmOpen: false,
        confirmText: "",
        action: {
            action: {},
            params: null
        },
        messageData: {
            message: "",
            type: "",
            isVisible: false
        } as messageType,
        favicon: {} as any,
    },
    reducers: {
        toggleConfirmWindow: (state, action) => {
            state.isConfirmOpen = !state.isConfirmOpen;
            state.confirmText = action.payload.confirmText;
            state.action.action = action.payload.action;
            state.action.params = action.payload.params;
        },
        setMessage: (state, action) => {
            if (action.payload == null) {
                state.messageData.message = "";
                state.messageData.type = "info";
                state.messageData.isVisible = false;
            }
            else {
                state.messageData.message = action.payload.message;
                state.messageData.type = action.payload.status == 0 || !action.payload.message ? "info" : action.payload.status == 1 ? "success"
                    : action.payload.status == 2 ? "warning" : "error";
                state.messageData.isVisible = action.payload.isVisible || action.payload.message ? true : false;
            }
        },
        setResultMessage: (state, action) => {
        },
        changeBadge: (state, action) => {
            if (action.payload > 0) {
                if (isEmpty(state.favicon)) {
                    state.favicon = Favico({
                        animation: 'none',
                        //bgColor: '#5CB85C',
                        //textColor: '#ff0',
                        //type: 'circle',
                        //animation: 'none',
                        //position: 'downright'
                    });
                }
                state.favicon.badge(action.payload);
            }
            else {
                if (!isEmpty(state.favicon)) {
                    state.favicon.reset();
                }
            }
        },
    }
});

export const { toggleConfirmWindow, setMessage, setResultMessage, changeBadge } = commonSlice.actions;
export const isConfirmOpen = (state) => state.common.isConfirmOpen;
export const actionConfirmed = (state) => state.common.action;
export const confirmText = (state) => state.common.confirmText;
export const message = (state) => state.common.messageData;
export const loadFavicon = (state) => state.common.favicon;

export default commonSlice.reducer;