import * as React from 'react';
import Icon from './icon';
import Tooltip from './toolTip';

const ButtonNoAction = (props) => {
    const guid = props.guid;
    const buttonType = props.type;
    const id = props.id ? props.id : buttonType + props.target + "_" + guid;
    const help = props.help;
    const text = props.text;
    var className = props.className || "small-button red-button";
    className += text == null ? "" : " button-with-text";

    return (
        <>
            <button key={props.pkey} id={id} className={className} >
                <Icon image={"/images/" + buttonType + ".svg"} guid={guid} imageName={buttonType} alt={help} />
                <div>{text}</div>
            </button>
            {help &&
                <Tooltip id={id} text={help} />
            }
        </>
    );
}

export default ButtonNoAction;