import { weekDaysNames } from "../features/calendar/calendarSlice";

export default function isEmpty(obj) {
    return obj == null || obj === undefined || Object.keys(obj).length === 0;
};


export function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

export function formatDateToShortDate(date) {
    var d = new Date(date);
    var day = "" + d.getDate(); day = day.length == 1 ? "0" + day : day;
    var month = "" + Number(d.getMonth() + 1); month = month.length == 1 ? "0" + month : month;
    var year = "" + d.getFullYear();
    var shortDate = day + "." + month + "." + year;
    return shortDate;
}

export function formatDateToShortDateWithoutYear(date) {
    var d = new Date(date);
    var day = "" + d.getDate(); day = day.length == 1 ? "0" + day : day;
    var month = "" + Number(d.getMonth() + 1); month = month.length == 1 ? "0" + month : month;
    var shortDate = day + "." + month;
    return shortDate;
}

export function getDayShortName(dayNum, daysNames) {
    return dayNum == 0 ? daysNames[6] : daysNames[dayNum-1];
}

export function formatDateToVeryShortDate(date) { //25.03.23
    var d = new Date(date);
    var day = "" + d.getDate(); day = day.length == 1 ? "0" + day : day;
    var month = "" + Number(d.getMonth() + 1); month = month.length == 1 ? "0" + month : month;
    var year = "" + d.getFullYear();
    year = year.substring(2);
    var shortDate = day + "." + month + "." + year;
    return shortDate;
}

export function formatDateToVeryShortDateWithName(date, daysNames) { //25.03.23 Пн
    var d = new Date(date);
    var day = "" + d.getDate(); day = day.length == 1 ? "0" + day : day;
    var month = "" + Number(d.getMonth() + 1); month = month.length == 1 ? "0" + month : month;
    var year = "" + d.getFullYear();
    year = year.substring(2);
    var shortDate = day + "." + month + " " + getDayShortName(d.getDay(), daysNames);
    return shortDate;
}

export function formatDateToShortDateWithNameAndTime(date, daysNames) { //25.03.23 Пн 12:20
    var d = new Date(date);
    var day = "" + d.getDate(); day = day.length == 1 ? "0" + day : day;
    var month = "" + Number(d.getMonth() + 1); month = month.length == 1 ? "0" + month : month;
    var year = "" + d.getFullYear();
    year = year.substring(2);
    var hour = d.getHours();
    var minute = "" + d.getMinutes();
    minute = minute.length == 1 ? "0" + minute : minute;
    var shortDate = day + "." + month + " " + getDayShortName(d.getDay(), daysNames) + " " + hour + ":" + minute;
    return shortDate;
}

export function formatDateFromShortDate(date) {
    if (!date) {
        return "";
    }
    var dateParts = date.split(".");
    var day = dateParts[1];
    var month = dateParts[0];
    var year = dateParts[2];

    return [year, day, month].join('-');
}


export function formatDateDefaultTime(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;
    var res = [year, month, day].join('-');
    res += "T00:00:00";
    return res;
}

export function formatDateInput(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
        year = year == 1 ? new Date().getFullYear() : year;

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;
    var res = [year, month, day].join('-');
    return res;
}

export function formatDateWeekInput(date) {
    var d = new Date(date);
    var weekNumber = getWeekNumber(d);
    weekNumber = weekNumber.length === 1 ? "0" + weekNumber : weekNumber;
    return d.getFullYear() + "-W" + weekNumber;
}

export function formatDateMonthInput(date) {
    var d = new Date(date);
    var monthNumber = (d.getMonth()+1).toString();
    monthNumber = monthNumber.length === 1 ? "0" + monthNumber : monthNumber;
    return d.getFullYear() + "-" + monthNumber;
}

export function getWeekNumber(date) {
    var d = new Date(date);
    var yearStart = +new Date(d.getFullYear(), 0, 1);
    var today = +new Date(d.getFullYear(), d.getMonth(), d.getDate());
    var dayOfYear = ((today - yearStart + 1) / 86400000);
    return Math.ceil(dayOfYear / 7).toString();
};

export function getDateOfIsoWeek(week, year) {
    week = parseFloat(week);
    year = parseFloat(year);

    if (week < 1 || week > 53) {
        throw new RangeError("ISO 8601 weeks are numbered from 1 to 53");
    } else if (!Number.isInteger(week)) {
        throw new TypeError("Week must be an integer");
    } else if (!Number.isInteger(year)) {
        throw new TypeError("Year must be an integer");
    }

    const simple = new Date(year, 0, 1 + (week - 1) * 7);
    const dayOfWeek = simple.getDay();
    const isoWeekStart = simple;

    // Get the Monday past, and add a week if the day was
    // Friday, Saturday or Sunday.

    isoWeekStart.setDate(simple.getDate() - dayOfWeek + 1);
    if (dayOfWeek > 4) {
        isoWeekStart.setDate(isoWeekStart.getDate() + 7);
    }

    // The latest possible ISO week starts on December 28 of the current year.
    if (isoWeekStart.getFullYear() > year ||
        (isoWeekStart.getFullYear() == year &&
            isoWeekStart.getMonth() == 11 &&
            isoWeekStart.getDate() > 28)) {
        throw new RangeError(`${year} has no ISO week ${week}`);
    }

    return isoWeekStart;
}


export function pickTextColorBasedOnBgColorSimple(bgColor, lightColor, darkColor) {
    var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
    var r = parseInt(color.substring(0, 2), 16); // hexToR
    var g = parseInt(color.substring(2, 4), 16); // hexToG
    var b = parseInt(color.substring(4, 6), 16); // hexToB
    return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 230) ?// 186) ?
        darkColor : lightColor;
}

export function randomColor() {
    var r = Math.floor(Math.random() * 245);
    var g = Math.floor(Math.random() * 245);
    var b = Math.floor(Math.random() * 245);
    return `rgb(${r},${g},${b})`;
}