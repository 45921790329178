import * as React from 'react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from '../../../../components/checkbox';
import { getSettings, projectsSettings, saveSettings } from '../../projectsSlice';

const MainSettings = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const loadedSettings = useSelector(projectsSettings);
    const [settings, setSettings] = useState(loadedSettings);

    //useEffect(() => {
    //    dispatch(getSettings());
    //}, []);

    useEffect(() => {
        setSettings(loadedSettings)
    }, [loadedSettings]);

    const save = (newSettings) => {
        setSettings(newSettings);
        dispatch(saveSettings(newSettings));
    }
    return (<>
        <div>
            {t('projects.mainSettings')}
            <div>
                <Checkbox key={"IsShowCalendarDays"} action={save}
                    params={{ ...settings, IsShowCalendarDays: settings && !settings.isShowCalendarDays }}
                    isChecked={settings && settings.isShowCalendarDays} />
                <div>{t('projects.daysAsProjects')}</div>
            </div>
        </div>
    </>);
}
export default MainSettings;