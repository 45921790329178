import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from 'reactstrap';
import { formatDate, formatDateMonthInput, formatDateWeekInput, getDateOfIsoWeek } from '../../functions/functions';
import { getDays, loadSelectedDateStart } from './calendarSlice';

const CalendarTransition = (props) => {
    const dispatch = useDispatch();
    const selectedPeriod = useSelector(loadSelectedDateStart);
   // const [selectedPeriod, setSelectedPeriod] = useState(formatDate(dateStart));
    const loadDays = (period) => {
        var startDate = props.mode === 2 ? formatDate(getDateOfIsoWeek(period.slice(-2), period.slice(0, 4)))
            : props.mode === 1 ? formatDate(new Date(period.slice(0, 4), period.slice(5, 7)-1, 1)) : period;
        dispatch(getDays({ mode: props.mode, startDate: startDate}));
    }
    const handleKeypress = () => {
        return false
    }
    return (<>
        <div className="calendar-transition">
            <Input
                type={props.mode === 2 ? "week" : props.mode === 1 ? "month" : "date"}
                value={props.mode === 2 ? formatDateWeekInput(selectedPeriod) : props.mode === 1 ? formatDateMonthInput(selectedPeriod) : formatDate(selectedPeriod)}
                id="calendar-transition"
                onChange={ev => loadDays(ev.target.value)}
                placeholder="sdfsdfsd"
                onKeyPress={() => { handleKeypress() }}
            />
        </div>
    </>);
}

export default CalendarTransition;