import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/button';
import {  deleteValue } from '../weightSlice';

const DeleteValue = (props: any) => {
    const { t } = useTranslation();
    return (<>
        <Button pkey={"delete_button_" + props.params.guid} type="delete" className="small-button" params={props.params} action={deleteValue}
            help={t('controls.button.delete')} />
    </>);
};

export default DeleteValue;