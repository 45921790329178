import * as signalR from "@microsoft/signalr";
//const URL = document.location.origin + "/hub";//or whatever your backend port is
const URL = "/hub";
class Connector {
    private connection: signalR.HubConnection;
    public events: (onLetterReceived: (username: string, message: {}) => void,
        onProject: (guid: string, type: "string") => void,
        onTask: (guid: string, type: "string") => void    ) => void;
    static instance: Connector;
    constructor() {
        this.connection = new signalR.HubConnectionBuilder()
            //.configureLogging(signalR.LogLevel.Debug)
            .withUrl(URL, {
                skipNegotiation: true,
                transport: signalR.HttpTransportType.WebSockets
                //transport: signalR.HttpTransportType.ServerSentEvents// 'longPolling'
            })
            .withAutomaticReconnect()
            .build();
        this.connection.start().catch(err => console.log(err));
        this.events = (onMessageReceived, onProjectUpdated, onTaskUpdated) => {
            this.connection.on("messageReceived", (username, message) => {
                onMessageReceived(username, message);
            });
            this.connection.on("projectUpdated", (guid, type) => {
                onProjectUpdated(guid, type);
            });
            this.connection.on("taskUpdated", (guid, type) => {
                onTaskUpdated(guid, type);
            });
        };
    }
    public newMessage = (messages: {}, user: string) => {
        this.connection.send("newMessage", user, messages).then(x => console.log("sent"))
    }
    public static getInstance(): Connector {
        if (!Connector.instance) {
            Connector.instance = new Connector();
        }
        return Connector.instance;
    }
}
export default Connector.getInstance;