import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../button';

const DeleteButton = (props) => {
    const { t } = useTranslation();
    return (
        <>
            <Button guid={props.guid} target={props.target} action={props.action} help={t('controls.button.delete')} type="delete" />
        </>
        );
}

export default DeleteButton;