import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button as RButton, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Button from '../../../components/button';
import SearchUserDropDown from '../../../components/searchUserDropDown';
import GroupUserItem from '../components/groupUserItem';
import { addUserToGroup, getGroupUsers, loadEditGroup, loadsIsOpenEditGroup, saveGroup, toggleOpenEditGroup } from '../userSlice';

const EditGroup = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isOpen = useSelector(loadsIsOpenEditGroup);
    const editGroup = useSelector(loadEditGroup);
    const [group, setGroup] = useState(editGroup);
    const [isOpenAddUser, setOpenAddUser] = useState(false);
  

    useEffect(() => {
        if (editGroup && editGroup.guid && (!editGroup.users || editGroup.users.length == 0)) {
            dispatch(getGroupUsers(editGroup.guid));
        }
        else if (!group.name || editGroup.guid != group.guid) {
            setGroup(editGroup);
        }
        else {
            setGroup({ ...group, users: editGroup.users });
        }
    }, [editGroup]);

    useEffect(() => {
        setOpenAddUser(false);
        setGroup(editGroup);
    }, [isOpen]);

    return (
        <div>
            <Modal autoFocus={true}
                centered
                toggle={() => dispatch(toggleOpenEditGroup(null))}
                backdrop="static"
                isOpen={isOpen}>
                <div className="white-background">
                    <ModalHeader toggle={function noRefCheck() { }}>
                        {t('groups.settings')}
                    </ModalHeader>
                    <ModalBody>
                        <Input autoFocus={true}
                            id={"userSaveName"}
                            placeholder={t('groups.name')}
                            value={group != null ? group.name : ""}
                            onChange={event => setGroup({ ...group, name: event.target.value })}
                        />
                        <div className="settings-header">{t('groups.groupMembers')}</div>
                        <div className="add-user-line">
                            {isOpenAddUser &&
                                <SearchUserDropDown action={addUserToGroup} />
                            }
                            <Button id="addGroupUserButton" type={isOpenAddUser ? "cancel" : "add"}
                                help={isOpenAddUser ? t('groups.cancel') : t('groups.addParticipant')} params={null} action={() => setOpenAddUser(!isOpenAddUser)} />
                        </div>
                        <div className="users-in-group">
                            {editGroup && editGroup.users && editGroup.users.map(x =>
                                <GroupUserItem user={x} groupGuid={group.guid} />
                            )}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <RButton
                            color="primary"
                            onClick={() => dispatch(saveGroup(group))}
                        >
                            {t('controls.modal.save')}
                        </RButton>
                        {' '}
                        <RButton onClick={() => dispatch(toggleOpenEditGroup(null))}>
                            {t('controls.modal.close')}
                        </RButton>
                    </ModalFooter>
                </div>
            </Modal>
        </div>);
}

export default EditGroup;