export function convertSVG(parentObject) {
    var object = parentObject? "#" + parentObject + " img.svg" : "img.svg";
    $(object).each(function () {
        var $img = jQuery(this);
   //     var attributes = $img.prop("attributes");
        var imgURL = $img.attr("src");
        $.get(imgURL, function (data) {
            var $svg = $(data).find('svg');
            //$svg = $svg.removeAttr('xmlns:a');
            //$.each(attributes, function () {
            //    $svg.attr(this.name, this.value);
            //});
            $img.replaceWith($svg);
        });
    });
}

export function convertSVGT(object) {
    var object = "#" + object;
    if (object) {
        var $img = jQuery($(object));
        var imgURL = $img.attr("src");
        $.get(imgURL, function (data) {
            var $svg = $(data).find('svg');
            $img.replaceWith($svg);
        });
    }
}


export function replaceSvg(id, src) {
    var $img = $("#" + id + " svg");
    $.get(src, function (data) {
        var $svg = $(data).find('svg');
        $img.replaceWith($svg);
    });
}

export function sortable(objectName, small, className, handleClass, reverse) {
    reverse = reverse == null || reverse == true ? true : false;
    $("." + className).sortable({
        handle: "." + handleClass,
        update: function (event, ui) {
            var order = $(this).sortable('toArray');
            if (order && order.length) {
                var order = order.map(function (x) { return x.replace(small + "_", "") });
                order = reverse ? order.reverse() : order;
            }
            fetch('/' + objectName + 's/Change' + objectName +'Position',
                {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8',
                        'Accept': 'application/json',
                    },
                    body: JSON.stringify(order)
                }).then(() => {
                    var i = 1;
                    $("." + small + "-list ul > li").each(function () {
                        $(this).attr('data-position', i);
                        i++;
                    });
                });
        }
    }).disableSelection();
}


function GetOrder(elem, reverse, small) {
    var order = $(elem).sortable('toArray');
    var parentGuid = $(elem).closest(".project-item").data("guid");
    if (order && order.length) {
        order = order.map(function (x) { return x.replace(small + "_", "") });
        order = reverse ? order.reverse() : order;
    }
    return { ParentGuid: parentGuid, Order: order };
}

export function sortableConnected(objectName, small, className, reverse, updateFunc, id) {
    reverse = reverse == null || reverse == true ? true : false;
    var isOnly = false;
    $("#" + id).sortable({
        connectWith: $("." + className),
        update: function (event, ui) {
            isOnly = this.id == $(ui.item).prop("parentNode").id;
            if (isOnly) {
                var obj = GetOrder(this, reverse, small);
                updateFunc(obj.ParentGuid, obj.Order);
            }
        },
        receive: function (event, ui) {
            isOnly = this.id == $(ui.item).prop("parentNode").id;

            $(ui.item).addClass("hidden");

            var obj = GetOrder(this, reverse, small);

            $("." + className).sortable("cancel");
            $("." + className).sortable("destroy");

            updateFunc(obj.ParentGuid, obj.Order);
        },
    }).disableSelection();
}

var PreviousParentId;
export function dnd(updateFunc, describeFunc,   onEnd) {
    $(".draggable").draggable(
        //{ containment: ".parent-droppable" }
        {
            revert: "invalid",
            start: function (event, ui) {
                PreviousParentId = $(this).closest(".kanban-column-content").attr("id");
            }
        }
    );
    //$(".droppable").droppable({
    //    //accept: ".kanban-board",
    //    tolerance: "intersect",
    //    drop: function (event, ui) {

    //        var taskGuid = ui.draggable.attr("id");
    //        var parentId = $(this).attr("id");

    //        $("#" + parentId).append(ui.draggable);
    //        $(ui.draggable).css({ left: 0, top: 0 });

    //        var newPositionName = describeFunc(parentId);
    //        var oldPositionName = describeFunc(PreviousParentId);
    //        if (oldPositionName != newPositionName) {
    //            updateFunc(taskGuid, newPositionName);
    //        }
    //    },
    //});


    $(".droppable").droppable({
        //accept: ".kanban-board",
        tolerance: "intersect",
        drop: function (event, ui) {

            var taskGuid = ui.draggable.attr("id");
            var parentId = $(this).attr("id");

            var result = {
                draggableId: taskGuid,
                source: {
                    index: ui.draggable.attr("index"),
                    droppableId: PreviousParentId
                },
                destination: {
                    index: ui.draggable.attr("index"),
                    droppableId: parentId
                }
            };
            onEnd(result);




            $("#" + parentId).append(ui.draggable);
            $(ui.draggable).css({ left: 0, top: 0 });

            //var newPositionName = describeFunc(parentId);
            //var oldPositionName = describeFunc(PreviousParentId);
            //if (oldPositionName != newPositionName) {
            //    updateFunc(taskGuid, newPositionName);
            //}
        },
    });
}