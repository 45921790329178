import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { stat } from 'fs';

export const getLetters = createAsyncThunk(
    'letters/getLetters',
    async (props: any) => {
        const data = await fetch("/Letter/GetLetters?" + new URLSearchParams({
            startIndex: props.startIndex,
            search: props.search,
        }));
        const json = await data.json();
        return json;
    }
);

export const sendLetter = createAsyncThunk(
    'letters/sendLetter',
    async (model: object) => {
        const data = await fetch('/Letter/SendLetter',
            {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    'Accept': 'application/json',
                },
                body: JSON.stringify(model)
            });
        const json = await data.json();
        return json;
    });

export const letterLink = createAsyncThunk(
    'letters/letterLink',
    async (link: string) => {
        const data = await fetch(link);
        const json = await data.json();
        return json;
    });

export const changeLetterStatus = createAsyncThunk(
    'letters/changeLetterStatus',
    async (letter: any) => {
        const data = await fetch('/Letter/ChangeLetterStatus',
            {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    'Accept': 'application/json',
                },
                body: JSON.stringify({ letterGuid: letter.letterGuid, newStatus: letter.letterStatus})
            });
        const json = await data.json();
        return json;
    }
);

export const getUnreadLettersCount = createAsyncThunk(
    'letters/getUnreadLettersCount',
    async () => {
        const data = await fetch("/Letter/GetUnreadLettersCount",
            {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    'Accept': 'application/json',
                },
            });
        const json = await data.json();
        return json;
    });

export const letterSlice = createSlice({
    name: "letters",
    initialState: {
        letters: [] as any[],
        isLetterEditOpen: false,
        letterRecipients: [] as any[],
        message: {},
        allowUser: false,
        isNeedUpdate: true,
        currentPage: 1,
        totalCount: 0,
        updatedLetter: {} as any,
        unreadLetters: 0,
    },
    reducers: {
        lClearMessage: (state, action) => {
            state.message = {};
        },
        toggleOpenNewLetter: (state, action) => {
            state.isLetterEditOpen = !state.isLetterEditOpen;
        },
        addRecipient: (state, action) => {
            if (state.letterRecipients.find(x => x.key == action.payload.key)) {
                state.message = { message: "Пользователь уже в списке!", isVisible: true, status: 2 };
            }
            else {
                state.letterRecipients.push(action.payload);
            }
        },
        setNeedUpdateLetters: (state, action) => {
            state.isNeedUpdate = true;
        }
    },
    extraReducers:
        (builder) => {
            builder
                .addCase(getLetters.fulfilled, (state, action) => {
                    if (action.payload.data) {
                        state.letters = action.payload.data.letters;
                        state.currentPage = action.payload.data.currentPage;
                        state.totalCount = action.payload.data.totalCount;
                        state.unreadLetters = action.payload.data.unreadLetters;
                        state.isNeedUpdate = false;
                    }
                    if (action.payload.message) {
                        state.message = { message: action.payload.message, isVisible: true, status: action.payload.status };
                    }
                })
                .addCase(sendLetter.fulfilled, (state, action) => {
                    if (action.payload.message) {
                        state.message = { message: action.payload.message, isVisible: true, status: action.payload.status };
                    }
                    if (action.payload.status == 1) {
                        state.isNeedUpdate = true;
                        state.isLetterEditOpen = false;
                        state.letterRecipients = [];
                    }
                })
                .addCase(letterLink.fulfilled, (state, action) => {
                    if (action.payload.message) {
                        state.message = { message: action.payload.message, isVisible: true, status: action.payload.status };
                    }
                })
                .addCase(changeLetterStatus.fulfilled, (state, action) => {
                    if (action.payload.data) {
                        var letter = action.payload.data.letter;
                        state.letters = state.letters.map(x => x.guid == letter.guid ?
                            { ...x, letterStatus: letter.letterStatus, letterStatusText: letter.letterStatusText } : x);
                        state.updatedLetter = state.letters.find(x => x.guid == letter.guid);
                        state.updatedLetter = {
                            ...state.updatedLetter,
                            letterStatus: letter.letterStatus,
                            letterStatusText: letter.letterStatusText
                        };
                        state.unreadLetters = action.payload.data.unreadCount;
                    }
                })
                .addCase(getUnreadLettersCount.fulfilled, (state, action) => {
                    if (action.payload.data) {
                        state.unreadLetters = action.payload.data;
                    }
                })
        }
});

export const lMessage = (state) => state.letter.message;
export const loadLetters = (state) => state.letter.letters;
export const loadLetterEditOpen = (state) => state.letter.isLetterEditOpen;
export const loadLetterRecipients = (state) => state.letter.letterRecipients;
export const loadNeedUpdate = (state) => state.letter.isNeedUpdate;
export const loadUnreadLetters = (state) => state.letter.unreadLetters;
export const loadLettersCurrentPage = (state) => state.letter.currentPage;
export const loadLettersTotalCount = (state) => state.letter.totalCount;
export const loadUpdatedLetter = (state) => state.letter.updatedLetter;

export const { lClearMessage, toggleOpenNewLetter, addRecipient, setNeedUpdateLetters } = letterSlice.actions;

export default letterSlice.reducer;