import * as React from 'react';
import { useLocation } from 'react-router-dom';
import Icon from '../icon';
import Tooltip from '../toolTip';

const NavMenuButton = (props) => {
    const buttonType = props.type;
    const id = buttonType;
    const help = props.help;
    const position = props.position ? props.position : "left";
    const location = useLocation();
    //const path = location.pathname.substring(1).toLowerCase() === props.type.toLowerCase();
    const path = location.pathname.substring(1).toLowerCase().startsWith(props.type.toLowerCase());
    var className = props.className + " " + position + "-button big-button " + (path ? "active-button " : " ");
    return (
        <>
            <button id={id} className={className}>
                <Icon image={"/images/" + buttonType + ".svg"} imageName={buttonType} />
            </button>
            <Tooltip id={id} text={help} />
        </>
    );
}

export default NavMenuButton;