import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/button';
import IconLabel from '../../../components/iconLabel';
import { deleteUserFromGroup } from '../userSlice';

const GroupUserItem = (props) => {
    const { t } = useTranslation();
    const user = props.user;
    const groupGuid = props.groupGuid;
    return (<>
        <div className="group-user">
            {user.groupUserStatus == 2 ?
                <IconLabel guid={user.userId} image="/images/ok_green.svg" className="group-user-status" help={t('groups.confirmed')} />
                :
                user.groupUserStatus == 1 ?
                    <IconLabel guid={user.userId} image="/images/time.svg" className="group-user-status" help={t('groups.awaitingConfirmation')} />
                    : <></>
            }
            {user.name}
            <div className="action-button-group inline-button-group">
                <Button key={"delete_" + user.userId} type="delete" className="small-button"
                    params={{ groupGuid: groupGuid, userId: user.userId }} action={deleteUserFromGroup}
                    help={t('groups.deleteFromGroup')}
                    confirmText={t('groups.deleteParticipantConfirmation')} />
            </div>
        </div>
    </>);
}

export default GroupUserItem;