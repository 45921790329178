import 'bootstrap/dist/css/bootstrap.css';
import * as React from 'react';
import { useEffect } from 'react';
import { DragDropContext } from "react-beautiful-dnd";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ShowHideButton from '../../components/actionButtons/ShowHideButton';
import Button from '../../components/button';
import { formatDateFromShortDate } from '../../functions/functions';
import isEmpty from '../../functions/functions';
import { sortable } from '../../js/controls';
import { changeEventPosition, changeMode, clearCalendar, getDays, loadCalendar, saveEvent, setDaysEvents } from '../calendar/calendarSlice';
import EditEvent from '../calendar/modal/editEventModal';
import { setMessage } from '../common/commonSlice';
import EditTaskModal from '../task/components/editExtendedTaskModal';
import { changePosition, loadProjectTasks, setProjectTasks } from '../task/tasksSlice';
import { getUserGroups } from '../user/userSlice';
import CalendarProject from './components/CalendarProject';
import EditProjectButton from './components/EditProjectButton';
import GroupBlock from './components/groupBlock';
import LabelBlock from './components/labelBlock';
import Project from './components/Project';
import EditProject from './modal/editProjectModal';
import ProjectsSettings from './modal/projectsSettingsModal';
import {
    getLabels, getProjects, getSettings, loadNeedReload, loadProjects, loadSelectedGroup,
    loadSelectedLabels, projectsSettings, setSelectedProjectsGuids, toggleProjectSettings, loadCalendarStep
} from './projectsSlice';
import CalendarBackForward from '../calendar/calendarBackForward';
import ProjectCalendarBackForward from './components/projectCalendarBackForward';


const Projects = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const projectsList = useSelector(loadProjects);
    const projectTasks = useSelector(loadProjectTasks);
    const calendar = useSelector(loadCalendar);
    const isNeedReload = useSelector(loadNeedReload);
    const settings = useSelector(projectsSettings);
    const selectedLabelGuids = useSelector(loadSelectedLabels);
    const selectedGroupGuid = useSelector(loadSelectedGroup);
    const calendarStep = useSelector(loadCalendarStep);

    useEffect(() => {
        dispatch(setSelectedProjectsGuids([]));
        dispatch(getProjects({ labelGuids: selectedLabelGuids, groupGuid: selectedGroupGuid}));
        dispatch(getLabels());
        dispatch(getUserGroups());
        console.log(settings.isShowCalendarDays);
        if (settings && settings.isShowCalendarDays && (isEmpty(selectedLabelGuids) || selectedLabelGuids.includes("A42EABE1-56E5-4ED5-B879-0C31EE1065A9".toLowerCase()))) {
            dispatch(changeMode(3));
            dispatch(getDays({ mode: 3, step: calendarStep, isDayNames: true }));
        }
        else {
            dispatch(clearCalendar(null));
        }
    }, [selectedLabelGuids, isNeedReload, settings, selectedGroupGuid]);

    useEffect(() => {
        dispatch(getSettings());
    }, [])

    useEffect(() => {
        if (calendarStep != 0) {
            dispatch(getDays({ mode: 3, step: calendarStep, isDayNames: true }));
        }
    }, [calendarStep]);

    //function refreshAt(hours, minutes, seconds) {
    //    console.log("refresh");
    //    var now = new Date();
    //    var then = new Date();

    //    if (now.getHours() > hours ||
    //        (now.getHours() == hours && now.getMinutes() > minutes) ||
    //        now.getHours() == hours && now.getMinutes() == minutes && now.getSeconds() >= seconds) {
    //        then.setDate(now.getDate() + 1);
    //    }
    //    then.setHours(hours);
    //    then.setMinutes(minutes);
    //    then.setSeconds(seconds);

    //    var timeout = (then.getTime() - now.getTime());
    //    setTimeout(function () { dispatch(getDays({ mode: 3, step: 0, isDayNames: true })); }, timeout);
    //}

    //refreshAt(0, 1, 0);

    //useEffect(() => {
    //    if (settings && settings.isShowCalendarDays) {
    //        dispatch(changeMode(3));
    //        dispatch(getDays({ mode: 3, step: 0, isDayNames: true }));
    //    }
    //    else {
    //        dispatch(clearCalendar(null));
    //    }
    //}, [settings]);

    useEffect(() => {
        sortable("Project", "project", "project-sortable", "project-title", false);
    });

    const removeFromList = (list, index) => {
        const result = [...list];
        const [removed] = result.splice(index, 1);
        return [removed, result];
    }

    const addToList = (list, index, element) => {
        const result = [...list];
        result.splice(index, 0, element);
        return result;
    }

    const onDragEnd = async (result) => {
        if (!result.destination) {
            return;
        }

        if (result.destination.droppableId.includes('.') > 0) { //Перенос в календарный день
            if (result.source.droppableId.includes('.')) { //Перенос из календарного дня
                var eventSourceList = calendar.days.find(x => x.date == result.source.droppableId);
                var eventsSource = eventSourceList.events;
                const [eventRemovedElement, eventNewSourceList] = removeFromList(eventsSource, result.source.index);

                var eventsDestination = result.source.droppableId != result.destination.droppableId ?
                    calendar.days.find(x => x.date == result.destination.droppableId).events : eventNewSourceList;
                var eventsNew = addToList(eventsDestination, result.destination.index, eventRemovedElement);

                if (result.source.droppableId != result.destination.droppableId) {
                    await dispatch(setDaysEvents({ date: result.source.droppableId, events: eventNewSourceList }));
                }
                await dispatch(setDaysEvents({ date: result.destination.droppableId, events: eventsNew }));

                var destOrder = eventsNew.map(x => x.guid).reverse();
                await dispatch(changeEventPosition({ parentDate: result.destination.droppableId, order: destOrder }));

                if (result.source.droppableId != result.destination.droppableId) {
                    var sourceOrder = eventNewSourceList.map(x => x.guid).reverse();
                    await dispatch(changeEventPosition({ parentDate: result.source.droppableId, order: sourceOrder }));
                }
            }
            else { //Перенос из проекта
                var date = formatDateFromShortDate(result.destination.droppableId);

                var sourceList = projectTasks.find(x => x.projectGuid == result.source.droppableId);
                var tasksSource = sourceList.tasksList;
                var element = tasksSource[result.source.index];

                var eventsDestination = calendar.days.find(x => x.date == result.destination.droppableId).events;
                var eventsNew = addToList(eventsDestination, result.destination.index, element);
                var position = eventsDestination.length - result.destination.index + 1;
                var event = {
                    guid: null, name: "", date: date, taskGuid: result.draggableId, pattern: null, position: position
                };

                await dispatch(setDaysEvents({ date: result.destination.droppableId, events: eventsNew }));

                debugger;
                await dispatch(saveEvent(event));

                eventsDestination = calendar.days.find(x => x.date == result.destination.droppableId).events;
                var destOrder2 = eventsDestination.map(x => x.guid).reverse();
                //var destOrder = eventsNew.map(x => x.guid).reverse();
                await dispatch(changeEventPosition({ parentDate: result.destination.droppableId, order: destOrder2 }));
            }
        }
        else { //Перенос в проект
            if (result.source.droppableId.indexOf('.') > 0) { //Перенос из календарного дня
                //var eventSourceList = calendar.days.find(x => x.date == result.source.droppableId);
                //var eventsSource = eventSourceList.events;
                //var element = eventsSource[result.source.index];
                ////const [eventRemovedElement, eventNewSourceList] = removeFromList(eventsSource, result.source.index);

                //var tasksDestination = projectTasks.find(x => x.projectGuid == result.destination.droppableId).tasksList;
                //var tasksNew = addToList(tasksDestination, result.destination.index, element);

                //await dispatch(setProjectTasks({ projectGuid: result.destination.droppableId, tasks: tasksNew }));

                //var task = {
                //    name: element.name,
                //    projectGuid: result.destination.droppableId,
                //    guid: null,
                //};
                //await dispatch(saveTask(task));

                ////var destOrder = tasksNew.map(x => x.guid).reverse();
                //tasksDestination = projectTasks.find(x => x.projectGuid == result.destination.droppableId).tasksList;
                //var destOrder2 = eventsDestination.map(x => x.guid).reverse();
                //await dispatch(changePosition({ parentGuid: result.destination.droppableId, order: destOrder2 }));

                //debugger;
                //task = tasksDestination.find(x => x.name == task.name);
                //if (task) {
                //    element = { ...element, taskGuid: task.guid };
                //    await dispatch(saveEvent(element));
                //}

                await dispatch(setMessage({ message: "Нельзя создать задачу из события!", status: 2 }));
            }
            else { //Перенос из проекта

                var sourceList = projectTasks.find(x => x.projectGuid == result.source.droppableId);
                var tasksSource = sourceList.tasksList;
                const [removedElement, newSourceList] = removeFromList(tasksSource, result.source.index);

                var tasksDestination = result.source.droppableId != result.destination.droppableId ?
                    projectTasks.find(x => x.projectGuid == result.destination.droppableId).tasksList : newSourceList;
                var tasksNew = addToList(tasksDestination, result.destination.index, removedElement);

                await dispatch(setProjectTasks({ projectGuid: result.destination.droppableId, tasks: tasksNew }));
                if (result.source.droppableId != result.destination.droppableId) {
                    await dispatch(setProjectTasks({ projectGuid: result.source.droppableId, tasks: newSourceList }));
                }

                var destOrder = tasksNew.map(x => x.guid).reverse();
                await dispatch(changePosition({ parentGuid: result.destination.droppableId, order: destOrder }));

                if (result.source.droppableId != result.destination.droppableId) {
                    var sourceOrder = newSourceList.map(x => x.guid).reverse();
                    await dispatch(changePosition({ parentGuid: result.source.droppableId, order: sourceOrder }));
                }
            }
        }
    };

    return (
        <>
            <div>
                <div className="projects-top-panel">
                    <div className="project-action-buttons buttons-group">
                        <EditProjectButton guid={null} isEdit={false} class="big-button" />
                        <Button id="settingsButton" type="settings" className="big-button" action={() => toggleProjectSettings(null)}
                            help={t('projects.settings')}
                        />
                        <ShowHideButton />
                    </div>
                    <LabelBlock />
                    <GroupBlock />
                </div>

                <DragDropContext onDragEnd={onDragEnd} key={"projects-dragdrop-context"}>
                    {((!settings || settings.isShowCalendarDays) && (isEmpty(selectedLabelGuids) || selectedLabelGuids.includes("A42EABE1-56E5-4ED5-B879-0C31EE1065A9".toLowerCase()))) &&
                        <div id="projectCalendarList" className="project-list">
                            <ProjectCalendarBackForward />
                            <ul>
                                {
                                    calendar.mode == 3 &&
                                    calendar.days.map(d =>
                                        <CalendarProject day={d} />
                                    )}
                            </ul>
                        </div>
                    }

                    <div id="projectList" className="project-list">
                        <ul className="project-sortable">
                            {
                                projectsList.map((project: any) =>
                                    <Project project={project} key={project.guid} />
                                )
                            }
                        </ul>
                    </div>
                </DragDropContext>
                <EditProject />
                <ProjectsSettings />
                {/*<TaskSettingsModal />*/}
                <EditEvent />
                <EditTaskModal isCalendar={false} />
            </div>
        </>
    );
};

export default Projects;