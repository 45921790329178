import * as React from 'react';
import { useRef, useState } from 'react';
import { Button } from 'reactstrap';

const ButtonReactstrap = (props) => {
    const onClickAction = props.onClick;
    const color = props.color;
    const value = props.value;
    const [isDisabled, setDisabled] = useState(false);
    const ref = useRef(null);
 
    return (
        <>
            <Button ref={ref} color={color} onClick={async (e) => {
                setDisabled(true);
                await onClickAction();
                setDisabled(false);
            }}
                disabled={isDisabled}
            >
                {value}
            </Button>
        </>
    );
};
export default ButtonReactstrap;