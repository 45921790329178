import * as React from 'react';
import { useEffect, useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import isEmpty from '../functions/functions';

const DropDown = (props) => {

    var items = props.items;
    if (props.defaultValue && !items.find(x => x.key == props.defaultValue.key)) {
        items.unshift(props.defaultValue);
    }
    const [selected, setSelected] = useState(props.selected || props.defaultValue);
    
    const [isOpen, setOpen] = useState(false);

    useEffect(() => {
        setSelected(props.selected || props.defaultValue);
    }, [props.selected]);
    const toggleOpen = (item) => {
        setOpen(!isOpen);
        if (item) {
            setSelected(item);
            props.action(item);
        }
    }

    return (<>
        <Dropdown className="drop-down" toggle={() => toggleOpen(null)} isOpen={isOpen}>
            <DropdownToggle caret>
                {!isEmpty(selected)?selected.value : ""}
            </DropdownToggle>
            <DropdownMenu container="body">
                {items.map(x =>
                    <div key={"dropDownItems_" + x.key}
                        className={"drop-down-item" + (!isEmpty(selected) && x.key == selected.key ? " drop-down-item-selected" : "")}
                        onClick={() => toggleOpen(x)}
                        data-key={ x.key }>
                        {x.value}
                    </div>
                )}

            </DropdownMenu>
        </Dropdown>
    </>);
}

export default DropDown;