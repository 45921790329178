import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../button';

const EditButton = (props) => {
    const { t } = useTranslation();
    return (
        <>
            <Button guid={props.guid} target={props.target} action={props.action} help={t('controls.button.edit')} type="edit" />
        </>
    );
}

export default EditButton;