import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Button from '../../../components/button';
import { toggleEditProject }  from '../projectsSlice';

export default function EditProjectButton(props) {
    const { t } = useTranslation();
    const location = useLocation();
    const id = props.isEdit ? "editProject_" + props.guid : "addProject";
    const tooltipText = props.isEdit ? t('projects.edit') : t('projects.create');
    const path = location.pathname == "/";
    return (
        <>
            {path &&
                <Button className={props.class + " left-button"} params={props.project}
                    type={props.isEdit ? "edit" : "add"} action={toggleEditProject} help={tooltipText} />
            }
        </>
    );
}