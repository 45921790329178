import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import DropDown from '../../../components/dropDown';
import CheckEnter from '../../../functions/controls';
import { setMessage } from '../../common/commonSlice';
import { loadEvent, loadPatterns, openEdit, saveEvent, toggleEditEvent } from '../calendarSlice';

const EditEvent = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isOpenEdit = useSelector(openEdit);
    const loadedEvent = useSelector(loadEvent);
    const [event, setEvent] = useState(loadedEvent);
    const patterns = useSelector(loadPatterns);
    useEffect(() => {
       // loadedEvent = { ...loadedEvent, date:  }
        setEvent(loadedEvent);
    }, [loadedEvent]);

    const saveEditEvent = () => {
        debugger;
        if (!event.name) {
            dispatch(setMessage({ message: t('calendar.modal.edit.emptyWarning') , status: 2, isVisible: true }));
            return;
        }
        console.log("saveEditEvent");
        debugger;
        dispatch(saveEvent(event));
    }

    const handleKeypress = (e: Event) => {
        return false
    }

    const setPattern = (pattern) => {
        setEvent({ ...event, pattern: pattern })
    }

    //  var dateParts = loadedEvent.date.split(".");
    //   var date = [dateParts[1], dateParts[0], [dateParts[2]]].join('/');
    //   loadedEvent.date = [dateParts[2], dateParts[1] - 1, +dateParts[0]].join('-');
    return (<div>
        <Modal autoFocus={false}
            centered
            isOpen={isOpenEdit}
            className="edit-event-modal">
            <div className="white-background">
                <ModalHeader toggle={function noRefCheck() { }}>
                    {event == null || event.guid == null ? t('calendar.modal.edit.create') : t('calendar.modal.edit.edit')}
                </ModalHeader>
                <ModalBody>
                    <div className="edit-event-modal-content white-background">
                        <Input autoFocus={true}
                            id={"event-save-name"}
                            placeholder={t('calendar.modal.edit.name')}
                            onChange={ev => setEvent({ ...event, name: ev.target.value })}
                            onKeyPress={event => CheckEnter(event, saveEditEvent)}
                            defaultValue={event != null ? event.name : ""}
                        />
                        <Input
                            id={"event-save-date"}
                            value={event.date}
                            onChange={ev => setEvent({ ...event, date: ev.target.value })}
                            type="date"
                            onKeyPress={() => { handleKeypress(event) }}
                        />

                        <div className="event-save-patterns">
                            <Label>{t('calendar.modal.edit.repeatEvent')}</Label>
                            <DropDown items={patterns} selected={event.pattern ? event.pattern : patterns[0]} action={setPattern} />
                        </div>


                        {/*<select id="exampleSelect" size={size} onFocus={(e) => { e.target.size = 5; e.target.style.position = "absolute"; }}*/}
                        {/*    onBlur={(e) => { e.target.size = 1; e.target.style.position = "relative"; }}*/}
                        {/*    onChange={(e) => { e.target.size = 1; e.target.blur(); e.target.style.position = "relative"; }}>*/}
                        {/*    <option>*/}
                        {/*        1*/}
                        {/*    </option>*/}
                        {/*    <option>*/}
                        {/*        2*/}
                        {/*    </option>*/}
                        {/*    <option>*/}
                        {/*        3*/}
                        {/*    </option>*/}
                        {/*    <option>*/}
                        {/*        4*/}
                        {/*    </option>*/}
                        {/*    <option>*/}
                        {/*        5*/}
                        {/*    </option>*/}
                        {/*</select>*/}

                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={saveEditEvent}
                    >
                        {t('controls.modal.save')}
                    </Button>
                    {' '}
                    <Button onClick={() => dispatch(toggleEditEvent({}))}>
                        {t('controls.modal.cancel')}
                    </Button>
                </ModalFooter>
            </div>
        </Modal>
    </div>);
}

export default EditEvent;