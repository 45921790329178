import * as React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, ButtonGroup } from 'reactstrap';

const Switcher = (props) => {
    const dispatch = useDispatch();
    var items = props.items; // {name, action}
    var active = props.active || items[0].Name; //name of active
    const [selected, setSelected] = useState(active);
    const switchButton = (item) => {
        setSelected(item.Name);
        dispatch(item.Action);
    }

    return (<div className="switcher">
        <ButtonGroup>
            {items.map(x =>
                <Button
                    onClick={() => switchButton(x)}
                    active={selected === x.Name}
                >
                    {x.Name}
                </Button>
            )}
        </ButtonGroup>
    </div>);
}

export default Switcher;