import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ScrollableText from '../../../components/scrollableText';
import { setHiddenAllCalendarFalse } from '../../calendar/calendarSlice';
import Tasks from '../../task/Tasks';
import { loadProjectTasks } from '../../task/tasksSlice';
import { loadHidden, toggleShowHide } from '../projectsSlice';
import DeleteProjectButton from './DeleteProjectButton';
import EditProjectButton from './EditProjectButton';
import ShowHideButton from '../../../components/ShowHideButton';

export default function Project(project) {
    const dispatch = useDispatch();
    var tasksList = useSelector(loadProjectTasks);
    const hiddenAll = useSelector(loadHidden);
    project = project.project;
    const [titleText, setTitleText] = useState(project.name);
    useEffect(() => {
        var tasks = tasksList.find(x => x.projectGuid == project.guid);//.tasksList.length;
        if (tasks && tasks.tasksList.length) {
            var totalCount = tasks.tasksList.length;
            var doneCount = tasks.tasksList.filter(x => x.status == 4 || x.taskStatus == 4).length;
            var countString = doneCount > 0 ? doneCount + "/" + totalCount : totalCount;
            setTitleText("(" + countString + ") " + project.name);
        }
        else {
            setTitleText(project.name);
        }
    }, [tasksList, project.name]);
    const toggleHidden = () => {
        dispatch(setHiddenAllCalendarFalse(null));
        dispatch(toggleShowHide(project.guid));
    }
    var className = hiddenAll || project.isHidden ? "project-minimized" : "";
    className += " project-item";
    var styleBorder = project.color ? { borderLeft: `4px solid ${project.color}`, paddingLeft: "0" } : { };
    return (
        <li id={"project_" + project.guid} className={className} key={project.guid} data-guid={project.guid} data-position={project.position}>
            <div className="project-content" style={styleBorder }>
                <div className="project-title white-background noselect" >
                    <ScrollableText className="project-title-text" text={titleText} width="270px" maxLength={18} />
                    <div className="action-button-group project-button-group">
                        <ShowHideButton guid={project.guid} hidden={project.isHidden} action={toggleHidden } />
                        <EditProjectButton project={project} isEdit={true} class="small-button" />
                        <DeleteProjectButton guid={project.guid} />
                    </div>
                </div>
                <Tasks projectGuid={project.guid} />
            </div>
        </li>
    );
}