import * as React from 'react';
import { Droppable } from "react-beautiful-dnd";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Button from '../../components/button';
import { formatDateFromShortDate, formatDateToShortDate } from '../../functions/functions';
import { loadSelectedET, toggleEditEvent } from '../calendar/calendarSlice';
import Event from './event';
import EditEvent from './modal/editEventModal';

const Days = (props) => {
    const { t } = useTranslation();
    const selectedET = useSelector(loadSelectedET);
    var calendar = props.calendar;
    var currentDate = formatDateToShortDate(new Date());

    return (
        <div className="days-panel">
            {
                calendar.days.map(d =>
                    <div key={"date-block_" + d.date} className={"day-block " + (currentDate == d.date? "current-date" : "")}>
                        <div className="days-day-block">
                            <div className="date-header">
                                <div className="date-name-block">{d.date + " " + d.dayName}</div>
                                <div className="action-button-group calendar-button-group">
                                    <Button type="add" className="smallest-button"
                                        params={{ date: formatDateFromShortDate(d.date) }}
                                        action={toggleEditEvent} help={t('calendar.addEvent')} />
                                </div>
                            </div>
                            <Droppable droppableId={d.date}>
                                {(provided, snapshot) => (
                                    <div className="droppable-tasks"
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        <div className="calendar-events">
                                            {
                                                d.events.map(e =>
                                                    (selectedET == "events" && e.taskGuid || selectedET == "tasks" && !e.taskGuid) ? <></> :
                                                    <Event event={e} key={e.guid} />
                                                )
                                            }
                                        </div>
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </div>
                    </div>
                )
            }
            <EditEvent />
        </div>
    );
}

export default Days;