import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import SearchUserDropDown from '../../components/searchUserDropDown';
import { currentUser, loadAllowUser } from '../user/userSlice';
import { addRecipient, loadLetterEditOpen, loadLetterRecipients, sendLetter, setNeedUpdateLetters, toggleOpenNewLetter } from './lettersSlice';
import Connector from '../../signalr-connection';
import { useTranslation } from 'react-i18next';

const NewLetter = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const user = useSelector(currentUser);
    const isOpen = useSelector(loadLetterEditOpen);
    const users = useSelector(loadLetterRecipients);
    const allowUser = useSelector(loadAllowUser);
    const [letter, setLetter] = useState({ header: "", content: "" });

    const send = async() => {
        var model = { letter: letter, recipientIds: users.map(x => x.key) };
        await dispatch(sendLetter(model));
        newMessage(model, user);
        setLetter({ header: "", content: "" });
    }

    /*SignalR*/
    const { newMessage, events } = Connector();
   // const [message, setMessage] = useState({});
    //useEffect(() => {
    //    events((_, message) => dispatch(setNeedUpdateLetters(null)));
    //});

    return (
        <div>
            <Modal autoFocus={false}
                centered
                toggle={() => dispatch(toggleOpenNewLetter(null))}
                backdrop="static"
                className="create-letter-modal"
                isOpen={isOpen}>
                <div className="white-background">
                    <ModalHeader toggle={function noRefCheck() { }}>
                        {t('letters.create')}
                    </ModalHeader>
                    <ModalBody>
                        {t('letters.definition')}
                        <div className="input-line">
                            <SearchUserDropDown action={addRecipient} />
                            {
                                users && users.map(x =>
                                    <div>{x.value}</div>
                                )
                            }
                        </div>
                        <div className="input-line">
                            <Input type="text" placeholder={t('letters.header')} onChange={(e) => setLetter({ ...letter, header: e.target.value })} />
                        </div>
                        <div className="input-line">
                            <Input type="textarea" placeholder={t('letters.text')} onChange={(e) => setLetter({ ...letter, content: e.target.value })} />
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            onClick={() => send()}
                        >
                            {t('letters.send')}
                        </Button>
                        {' '}
                        <Button onClick={() => dispatch(toggleOpenNewLetter(null))}>
                            {t('controls.modal.close')}
                        </Button>
                    </ModalFooter>
                </div>
            </Modal>
        </div>);
}

export default NewLetter;