import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Icon from '../../../components/icon';
import Tooltip from '../../../components/toolTip';
import { toggleEditEvent } from '../../calendar/calendarSlice';
import { toggleEditTask } from '../tasksSlice';


export default function EditTaskButton(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const id = "editTask_" + props.task.guid;
    return (
        <>
            <button id={id} className="small-button" onClick={() => dispatch(toggleEditTask(props.task))}>
                <Icon image="/images/edit.svg" guid={props.task.guid} imageName="edit" />
            </button>
            <Tooltip id={id} text={t('tasks.teskEditing')} />
        </>
    );
}