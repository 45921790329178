import * as React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../components/button';
import GroupItem from '../components/groupItem';
import EditGroup from '../modal/editGroupModal';
import { getUserGroups, loadedGroups, loadIsNeedUpdateGroups, toggleOpenEditGroup } from '../userSlice';

const UserGroupSettings = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const groups = useSelector(loadedGroups);
    const isNeedUpdateGroups = useSelector(loadIsNeedUpdateGroups);
    useEffect(() => {
        dispatch(getUserGroups());
    }, [isNeedUpdateGroups]);

    return (<>
        <div className="user-panel">
            <div className="user-panel-title">{t('groups.userGroups')}</div>
            <div className="user-settings-panel">
                <Button id="addGroupButton" type="add" help={t('groups.addGroup')} params={ null} action={toggleOpenEditGroup} />
                {groups.map(x => 
                    <GroupItem group={x} />
                    )}
            </div>
        </div>
        <EditGroup />
    </>);
}

export default UserGroupSettings;