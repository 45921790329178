export const ru = {
    translation: {
        account: {
            cookie: "russian test",
        },
        nav: {
            help: {
                calendar: 'Календарь',
                projects: 'Проекты',
                kanban: 'Канбан доска',
                weight: 'Контроль веса',
                wiki: 'База знаний',
                user: 'Пользователь',
                help: 'Помощь',
                letters: 'Сообщения',
                exit: 'Выйти',
            }
        },
        controls: {
            button: {
                add: 'Добавить',
                delete: 'Удалить',
                edit: 'Редактировать',
                showall: 'Показать всё',
                hideall: 'Свернуть всё',
                show: 'Показать',
                hide: 'Скрыть',
                cancel: 'Отмена',
                save: 'Сохранить',
            },
            modal: {
                cancel: 'Отмена',
                yes: 'Да',
                confirmation: 'Подтверждение', 
                save: 'Сохранить',
                saveandclose: 'Сохранить и закрыть', 
                close: 'Закрыть',
            }
        },
        errors: {
            404: 'Страница не найдена!',
        },
        cookie: 'Продолжая использовать сайт, вы даете согласие на обработку файлов cookie',
        articles: {
            select: 'Нажмите на иконку раздела на схем ниже, чтобы узнать информацию о нём:',
        },
        calendar: {
            month: 'Месяц',
            week: 'Неделя',
            day: 'День',
            taskSwitcher: {
                all: 'Всё',
                tasks: 'События',
                events: 'Задачи',
            },
            events: 'Событий: ',
            back: 'Назад',
            forward: 'Вперёд',
            modal: {
                delete: {
                    deleteRepeatQuestion: 'Это событие с повторением, Вы хотите удалить всю настройку или только конкретное событие?',
                    answerDeleteSetting: 'Удалить настройку',
                    answerDeleteCurrent: 'Удалить только одно событие',
                },
                edit: {
                    emptyWarning: 'Нельзя сохранить пустое событие!',
                    edit: 'Редактирование события',
                    create: 'Создание события',
                    name: 'Название',
                    repeatEvent: 'Потворение события:',
                },
            },
            addEvent: 'Добавить событие',
            editEvent: 'Редактировать событие',
            deleteEvent: 'Удалить событие',
            confirmDelete: 'Вы уверены, что хотите удалить событие?',
        },
        help: {
            freeText: 'Проект абсолютно бесплатный и находится в стадии разработки. Здесь можно помочь в его развитии.',
            yandexMoney: 'Яндекс деньги (yoomoney) - ',
            form: 'или через форму: ',
            suggestions: 'Пожелания и идеи по улучшению, а также сообщения об ошибках можно отправить по адресу: ',
        },
        letters: {
            create: 'Создать письмо',
            definition: 'Создание письма (Внутренняя почта - только на сайте)',
            header: 'Заголовок',
            text: 'Текст письма',
            send: 'Отправить',
            to: 'кому: ',
            from: 'от: ',
            acceptInvitation: 'Принять приглашение',
            search: 'Поиск',
        },
        projects: {
            deleteConfirmation: 'Вы уверены, что хотите удалить проект?',
            edit: 'Редактировать проект',
            create: 'Создать проект',
            group: {
                withoutGroup: 'Все',
                projectGroup: 'Группа проекта:',
                projectParticipants: 'Участники проекта:',
            },
            creatingProject: 'Создание проекта',
            editingProject: 'Редактирование проекта',
            projectName: 'Название проекта',
            projectShortName: 'Короткое название',
            projectLabel: 'Метка проекта:',
            projectsLabels: 'Метки проектов:',
            addLabel: 'Добавить метку',
            mainSettings: 'Основные настройки:',
            daysAsProjects: 'Отображать дни из календаря как проекты',
            warningEmpty: 'Нельзя сохранить пустой проект!',
            mainEdit: 'Основные',
            groupEdit: 'Группа',
            labelEdit: 'Метки', 
            kanban: {
                unassigned: 'Неназначенные',
                participants: 'Участники',
                created: 'Создано',
                inProgress: 'В работе',
                done: 'Готово',
                taskExtendedEdit: 'Расширенная настройка задачи',
            },
            settings: 'Настройки',
            labelMaxError: 'Превышено максимальное число меток',
        },
        tasks: {
            deleteEvent: 'Удалить событие',
            confirmDeleteEvent: 'Вы уверены, что хотите удалить событие?',
            deleteTask: 'Удалить задачу',
            confirmDeleteTask: 'Вы уверены, что хотите удалить задачу?',
            editEvent: 'Редактировать событие',
            taskCreation: 'Создание задачи',
            teskEditing: 'Редактирование задачи',
            name: 'Название',
            executor: 'Исполнитель',
            warningEmtyTask: 'Нельзя сохранить пустую задачу!',
            warningEmptyEvent: 'Нельзя сохранить пустое событие!',
            taskExtendedEdit: 'Расширенная настройка задачи',
        },
        groups: {
            editGroup: 'Редактировать группу',
            deleteGroup: 'Удалить группу',
            confirmDeleteGroup: 'Вы уверены, что хотите удалить группу?',
            confirmed: 'Подтвержден',
            awaitingConfirmation: 'Ожидает подтверждения',
            deleteFromGroup: 'Удалить из группы',
            deleteParticipantConfirmation: 'Вы уверены, что хотите удалить участника?',
            settings: 'Настройки группы',
            name: 'Название',
            groupMembers: 'Участники группы',
            cancel: 'Отмена',
            addParticipant: 'Добавить участника',
            userGroups: 'Группы пользователя',
            addGroup: 'Добавить группу',
        },
        users: {
            userSettings: 'Настройки пользователя',
            userName: 'Имя пользователя',
            name: 'Имя',
            currentPassword: 'Текущий пароль',
            password: 'Пароль',
            repeatPassword: 'Подтверждение пароля',
            sendEventNotificationsEmail: 'Отправлять уведомления о событиях из календаря на Email',
            settings: {

                main: 'Основные',
                groups: 'Группы',
            }
        },
        weight: {
            deleteColumnConfirmation: 'Вы уверены, что хотите удалить весь стобец данных?',
            table: 'Таблица',
            chart: 'График',
            actions: 'Действия',
            addQuantity: 'Добавить количественный показатель',
            addLine: 'Добавить строку',
            cleanTable: 'Очистить таблицу',
            cleanTableConfirmation: 'Вы уверены, что хотите удалить все данные?',
            date: 'Дата',
            dateEdit: 'Изменить дату',
        },
        constances: {
            all: "Все",
            errorMessage: "Произошла ошибка при обращении к сервису! Попробуйте повторить операцию позже.",
            weekDays: ["Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота", "Воскресенье"],
            weekDaysShort: ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"],
        }
    }
};
export default ru;