import * as React from 'react';
import DeleteButton from '../../../components/actionButtons/DeleteButton';
import EditButton from '../../../components/actionButtons/EditButton';
import { deleteArticle, toggleEditArticle } from '../adminSlice';

const ArticleLine = (props: any) => {
    var article = props.article;
    return (
        <>
            <li className="article-line">
                <span>{article.name}</span>
                <div className="action-button-group">
                    <EditButton guid={article.guid} target="Article" action={toggleEditArticle} />
                    <DeleteButton guid={article.guid} target="Article" action={deleteArticle} />
                </div>
            </li>

        </>);
}

export default ArticleLine;