import * as React from 'react';
import { useTranslation } from 'react-i18next';

const NotFound = () => {
    const { t } = useTranslation();
    return (<>
        <div className="error-page">
            <div className="error-page-num">404</div>
            <div className="error-page-text">{ t('errors.404')}</div>
        </div>
    </>);
}

export default NotFound;