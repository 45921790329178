import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Button from '../../../components/button';
import DeleteTaskButton from '../../task/components/DeleteTaskButton';
import EditTask from '../../task/components/editTask';
import EditTaskButton from '../../task/components/EditTaskButton';
import { loadTask, toggleExtendedEditTask } from '../../task/tasksSlice';

const Task = (props: any) => {
    const { t } = useTranslation();
    //  var task = props.task;
    const loadedTask = useSelector(loadTask);
    const [task, setTask] = useState(props.task);
    const [isEditMode, toogleIsEdit] = useState(false);
    useEffect(() => {
        if (loadedTask && loadedTask.guid == task.guid) {
            toogleIsEdit(loadedTask.isEditMode);
            setTask(loadedTask);
        }
    }, [loadedTask, isEditMode]);

    if (isEditMode) {
        return (<>
            <EditTask task={task} isModal={true} />
        </>);
    }
    else
        return (
            <>
                <div id={task.guid} className="kanban-task draggable">
                    <div className="action-button-group task-button-group">
                        {/*<EditTaskButton task={task} />*/}
                        {/*<Button key={"extendedTaskSettings_" + task.guid} id={"extendedTaskSettings_" + task.guid} params={task} type="settings"*/}
                        {/*    action={toggleExtendedEditTask} help={t('projects.kanban.taskExtendedEdit')} />*/}
                        <DeleteTaskButton guid={task.guid} projectGuid={task.projectGuid} />
                    </div>
                    <div>
                        {task.name}
                    </div>
                </div>
            </>);
}

export default Task;