import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty, { pickTextColorBasedOnBgColorSimple } from '../../../functions/functions';
import { loadLabels, loadSelectedLabels, setSelectedLabels } from '../projectsSlice';

const LabelBlock = () => {
    const dispatch = useDispatch();
    var labels = useSelector(loadLabels);
    var selectedGuids = useSelector(loadSelectedLabels);

    const changeSelectedLabel = (guid) => {
        dispatch(setSelectedLabels(guid != null ? (selectedGuids ? (selectedGuids.indexOf(guid) >= 0 ? selectedGuids.filter(x => x != guid)
            : selectedGuids.concat(guid)) : new Array(guid)) : null));
    }

    return (<>
        <div className="labels-block">
            {
                labels && labels.map(x =>
                    <div key={"label_" + x.guid}
                        className={"noselect label-link" + ((isEmpty(selectedGuids) && x.guid == null)
                            || (!isEmpty(selectedGuids) && selectedGuids.indexOf(x.guid) >= 0) ? " selected" : "")}
                        style={{ backgroundColor: x.color, color: pickTextColorBasedOnBgColorSimple(x.color, "white", "black") }}
                        onClick={() => changeSelectedLabel(x.guid)}>
                        {x.name}
                    </div>
                )}
        </div>
    </>);
}

export default LabelBlock;