import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const getArticle = createAsyncThunk("article/getArticle",
    async (guid: string) => {
        const data = await fetch("/Article/GetArticle/?" + new URLSearchParams({ pathName: guid }));
        return data.json();
    });

export const articleSlice = createSlice({
    name: "article",
    initialState: {
        article: {},
        isLoading: false
    },
    reducers: {},
    extraReducers:
    (builder) => 
    {
        builder
            .addCase(getArticle.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(getArticle.fulfilled, (state, action) => {
                state.article = action.payload.data;
                state.isLoading = false;
            })
            .addCase(getArticle.rejected, (state) => {
                state.article = {};
                state.isLoading = false;
            })
    }
});

export const loadArticle = (state) => state.article.article;
export const articlesIsLoading = (state) => state.article.isLoading;

export default articleSlice.reducer;