import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/button';
import { deleteHeader } from '../weightSlice';

const DeleteHeader = (props: any) => {
    const { t } = useTranslation();
    return (<>
        <Button type="delete" className="small-button" guid={props.guid} action={deleteHeader} help={t('controls.button.delete')}
            needConfirm={true} confirmText={t('weight.deleteColumnConfirmation')}  />
    </>);
};

export default DeleteHeader;