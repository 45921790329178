import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getArticle, loadArticle } from '../article/articleSlice';

const Article = (props) => {
    const dispatch = useDispatch();
    const article = useSelector(loadArticle);

    useEffect(() => {
        dispatch(getArticle(props.guid));
    }, [props.guid]);

    if (article?.guid) {
        return (<div className="article-info">
            <span className="article-name">{article.name + ": "}</span>
            <div dangerouslySetInnerHTML={{ __html: article.content }}></div>
        </div>);
    }
    else {
        return <div className="article-info"></div>;
    }
}

export default Article;