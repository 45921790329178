import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Button from '../../../components/button';
import { deleteProject } from '../projectsSlice';

export default function DeleteProjectButton(props) {
    const { t } = useTranslation();
    const tooltipText = "Удалить";
    return (
        <>
            <Button guid={props.guid} className="small-button"
                type="delete" action={deleteProject} help={tooltipText} needConfirm={true}
                confirmText={t('projects.deleteConfirmation')}
            />
        </>
    );
}