import * as React from 'react';
import { useDispatch } from 'react-redux';
import { switchAdmin } from '../../../features/admin/adminSlice';
import Icon from '../../icon';
import Tooltip from '../../toolTip';

const AdminButton = () => {
    const dispatch = useDispatch();

    const _switchAdmin = () => {
        dispatch(switchAdmin());
    };

    const id = "adminButton";
    return (
        <>
            <button id={id} className="right-button big-button " onClick={() => _switchAdmin()}>
                <Icon image={"/images/admin.svg"} guid={"admin"} imageName="admin" />
            </button>
            <Tooltip id={id} text="Админка" />
        </>
        );
}

export default AdminButton;