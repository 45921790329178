import * as React from 'react';
import { Droppable } from "react-beautiful-dnd";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Button from '../../components/button';
import { formatDateFromShortDate, formatDateToShortDate } from '../../functions/functions';
import { loadSelectedET, toggleEditEvent, weekDaysNames } from '../calendar/calendarSlice';
import Event from './event';
import EventDescription from './eventDescription';
import MobileEvent from './mobileEvent';
import EditEvent from './modal/editEventModal';

const Month = (props) => {
    const { t } = useTranslation();
    const weekDaysShort = useSelector(weekDaysNames);
    const selectedET = useSelector(loadSelectedET);
    var calendar = props.calendar;
    var currentDate = formatDateToShortDate(new Date());
    const isMobile = props.isMobile;
    return (
        <div className="month-panel">
            {
                weekDaysShort.map(d =>
                    <div key={"date_" + d} className="day-name">{d}</div>
                )
            }
            {
                calendar.days.map((d,index) =>
                    <div key={"date-block_" + d.date}
                        className={"day-block " + (d.isCurrentMonth ? "current-month" : "not-current-month")
                            + (currentDate == d.date ? " current-date" : "")}>
                        <div className="month-day-block">
                            <div className="date-header">
                                {isMobile && <div className="date-name-block">{d.date.substring(0, 6) + d.date.substring(8, 10)}</div>
                                 || <div className="date-name-block">{d.date}</div>}
                               
                            </div>
                           
                            <Droppable droppableId={d.date}>
                                    {(provided, snapshot) => (
                                    <div className="droppable-tasks events-list" data-date={d.date}
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                        >
                                        <div className="calendar-events">
                                            <div className="action-button-group calendar-button-group month-action-buttons">
                                                <Button type="add" className="smallest-button"
                                                    params={{ date: formatDateFromShortDate(d.date) }}
                                                    action={toggleEditEvent} help={t('calendar.addEvent')} />
                                            </div>
                                                {
                                                ///*d.events.length > 1 && !isMobile || d.events.length > 3 ?*/
                                                //        {/*<div className="event-block">{"Событий: " + d.events.length}</div> :*/}
                                                        d.events.map(e =>
                                                            (selectedET == "events" && e.taskGuid || selectedET == "tasks" && !e.taskGuid) ? <></> :
                                                            (isMobile || d.events.length > 1) && <MobileEvent event={e} key={e.guid} />
                                                            || <Event event={e} key={e.guid} />
                                                        )
                                                }
                                            </div>
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                        </div>
                    </div>
                )
            }
            <EditEvent />
            {/*<EventDescription />*/}
        </div>
    );
}

export default Month;