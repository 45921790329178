import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '../components/alert';
import ConfirmModal from '../components/confirmModal';
import Cookie from '../components/cookie';
import Loading from '../components/loading';
import NavMenu from '../components/navMenu';
import Articles from '../features/article/Articles';
import { cClearMessage, cMessage } from '../features/calendar/calendarSlice';
import { changeBadge, setMessage } from '../features/common/commonSlice';
import { getUnreadLettersCount, lClearMessage, lMessage, loadUnreadLetters, setNeedUpdateLetters } from '../features/letter/lettersSlice';
import { getProject, pClearMessage, pMessage, setNeedReload } from '../features/project/projectsSlice';
import { getProjectTasks, getTask, tClearMessage, tMessage } from '../features/task/tasksSlice';
import { currentUser, getCurrentUserData, uClearMessage, uMessage } from '../features/user/userSlice';
import { wClearMessage, wMessage } from '../features/weight/weightSlice';
import isEmpty from '../functions/functions';
//import { }   from '../functions/favico.js-master/favico.js-master/favico-0.3.10.min.js';
import Connector from '../signalr-connection';

export function App() {
    const dispatch = useDispatch();
    var user = useSelector(currentUser);
    const notReadLetters = useSelector(loadUnreadLetters);
    //const favicon = useSelector(loadFavicon);
  //  dispatch(changeBadge(notReadLetters));
    useEffect(() => {
        dispatch(changeBadge(notReadLetters));
    }, [notReadLetters])

    /*SignalR*/
    const { events } = Connector();
    useEffect(() => {
        //events((_, message) => {
        //    dispatch(setNeedUpdateLetters(null));
        //    dispatch(getUnreadLettersCount());
        //});
        const handleMessageReceived = (_, message) => {
            dispatch(setNeedUpdateLetters(null));
            dispatch(getUnreadLettersCount());
        };
        const handleProjectUpdated = (guid, type) => {
            switch (type) {
                case "delete":
                    dispatch(setNeedReload(null));
                    break;
                default: case "update":
                    dispatch(getProject(guid));
                    break;
            }
        };
        const handleTaskUpdated = (guid, type) => {
            switch (type) {
                case "delete":
                    dispatch(getProjectTasks(guid));
                    break;
                case "added":
                    dispatch(getProjectTasks(guid));
                    break;
                default: case "update":
                    dispatch(getTask(guid));
                    break;
            }
        };
        events(handleMessageReceived, handleProjectUpdated, handleTaskUpdated);
    },[]);

    useEffect(() => {
        dispatch(getUnreadLettersCount());
    }, []);

    var taskMessage = useSelector(tMessage);
    var projectMessage = useSelector(pMessage);
    var weightMessage = useSelector(wMessage);
    var calendarMessage = useSelector(cMessage);
    var userMessage = useSelector(uMessage);
    var letterMessage = useSelector(lMessage);
    useEffect(() => {
        var arr = [taskMessage, projectMessage, weightMessage, calendarMessage, userMessage, letterMessage];
        var message = arr.find(x => !isEmpty(x));
        if (message) {
            dispatch(setMessage(message));
            dispatch(tClearMessage({}));
            dispatch(pClearMessage({}));
            dispatch(wClearMessage({}));
            dispatch(cClearMessage({}));
            dispatch(uClearMessage({}));
            dispatch(lClearMessage({}));
        }
    }, [taskMessage, projectMessage, weightMessage, calendarMessage, userMessage, letterMessage]);


    useEffect(() => {
        if (window.location.href != "https://localhost:44365/Articles" && window.location.href != "https://plannado.com/Articles") {
            dispatch(getCurrentUserData());
        }
    }, [dispatch]);

    //function refreshAt(hours, minutes, seconds) {
    //    var now = new Date();
    //    var then = new Date();

    //    if (now.getHours() > hours ||
    //        (now.getHours() == hours && now.getMinutes() > minutes) ||
    //        now.getHours() == hours && now.getMinutes() == minutes && now.getSeconds() >= seconds) {
    //        then.setDate(now.getDate() + 1);
    //    }
    //    then.setHours(hours);
    //    then.setMinutes(minutes);
    //    then.setSeconds(seconds);

    //    var timeout = (then.getTime() - now.getTime());
    //    setTimeout(function () { window.location.reload(); }, timeout);
    //}

    //refreshAt(24,0,0);

    //useEffect(() => {
    //    const interval = setInterval(() => {
    //       // window.location.reload();
    //    }, 5000000);
    //    return () => clearInterval(interval);
    //}, []);

    if (user) {
        return (
            <div className='App'>
                <Alert />
                <NavMenu />
                <Loading />
                <ConfirmModal />
            </div>
        )
    }
    else if (window.location.href == "https://localhost:44365/Articles" || window.location.href == "https://plannado.com/Articles") {
        return (<div className='App'><Articles /> <Cookie /> </div>);
    }
    else {
        return (<Loading />);
    };
}