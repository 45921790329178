import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { toggleDeleteWindow, loadIsOpenDelete, deleteEvent, loadOpenedEvent } from '../calendarSlice';

export default function DeleteVariantsModal() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    var isOpen = useSelector(loadIsOpenDelete);
    var event = useSelector(loadOpenedEvent);

    const DeleteEvent = async (isAll) => {
        var guid = event.guid;
        await dispatch(deleteEvent({ guid, isAll }));
        dispatch(toggleDeleteWindow(null));
    }
    return (
        <Modal animation={false} isOpen={isOpen}>
            <div className="white-background">
                <ModalHeader>
                    {t('controls.modal.confirmation')}
                </ModalHeader>
                <ModalBody>
                    {t('calendar.modal.delete.deleteRepeatQuestion')}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => DeleteEvent(true)}>
                        {t('calendar.modal.delete.answerDeleteSetting')}
                    </Button>
                    {' '}
                    <Button color="primary" onClick={() => DeleteEvent(false)}>
                        {t('calendar.modal.delete.answerDeleteCurrent')}
                    </Button>
                    {' '}
                    <Button onClick={() => dispatch(toggleDeleteWindow(null))}>
                        {t('controls.modal.cancel')}
                    </Button>
                </ModalFooter>
            </div>
        </Modal>
    );
}