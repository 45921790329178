import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, Label } from 'reactstrap';
import Switcher from '../../../components/switcher';
import { changeUserPassword, getUserSettings, loadUser, saveUser, saveUserLanguage } from './../userSlice';

const lngs = [
    { name: "en", nativeName: 'English' },
    { name: "ru", nativeName: 'Русский' }
];

const UserMainSettings = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const loadedUser = useSelector(loadUser)
    const [user, setUser] = useState(loadedUser);
    const [passwordModel, setPasswordModel] = useState({ oldPassword: "", newPassword: "", repeatNewPassword: "" });

    useEffect(() => {
        dispatch(getUserSettings());
    }, []);

    useEffect(() => {
        setUser(loadedUser);
    }, [loadedUser]);

    const saveNotificationPreferences = async (isSend) => {
        await setUser({ ...user, isSendEventNotificationByEmail: isSend });
        dispatch(saveUser({ ...user, isSendEventNotificationByEmail: isSend }));
    }

    return (<>
        <div className="user-panel">
            <div className="user-panel-title">{t('users.userSettings')}</div>
            <div className="user-settings-panel">
                <div className="user-setting">
                    <div id="language" style={{ color: "red" }}>
                        <div>
                            <Switcher items={lngs.map((lng) => ({ Name: lng.nativeName, Action: () => { i18n.changeLanguage(lng.name); dispatch(saveUserLanguage(lng.name)); }}))}
                                active={lngs.find(x => x.name == i18n.resolvedLanguage)?.nativeName}
                            />
                            {/*{Object.keys(lngs).map((lng) => (*/}
                               
                            {/*    <Button key={lng} style={{ fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal' }} type="submit" onClick={() => i18n.changeLanguage(lng)}>*/}
                            {/*        {lngs[lng].nativeName}*/}
                            {/*    </Button>*/}
                            {/*))}*/}
                        </div>
                    </div>
                </div>
                <div className="user-setting">
                    <Label for="userSaveName">{t('users.userName')}</Label>
                    <Input autoFocus={false}
                        id={"userSaveName"}
                        placeholder={t('users.name')}
                        value={user != null ? user.name : ""}
                        onChange={event => setUser({ ...user, name: event.target.value })}
                    />
                </div>
                <div className="user-setting">
                    <div>Email</div>
                    <div className="disabled-value"> {user != null ? user.email : ""}</div>
                </div>

                {/*<div className="user-setting">*/}
                {/*    <div>Находить меня в системе</div>*/}
                {/*    <DropDown items={[{ key: 1, value: "Только по email" }, { key: 2, value: "Только по имени" }]}*/}
                {/*        selected={{ key: 2, value: "Только по имени" }} />*/}
                {/*</div>*/}
                <Button
                    color="primary"
                    onClick={() => dispatch(saveUser(user))}
                    className="user-save-button"
                >
                    {t('controls.modal.save')}
                </Button>

                <div className="user-setting">
                    <Label for="userSaveName">{t('users.currentPassword')}</Label>
                    <Input autoFocus={false}
                        id={"userOldPassword"}
                        placeholder={t('users.currentPassword')}
                        onChange={event => setPasswordModel({ ...passwordModel, oldPassword: event.target.value })}
                        type="password"
                    />
                </div>
                <div className="user-setting">
                    <Label for="userSaveName">{t('users.password')}</Label>
                    <Input autoFocus={false}
                        id={"userNewPassword"}
                        placeholder={t('users.password')}
                        onChange={event => setPasswordModel({ ...passwordModel, newPassword: event.target.value })}
                        type="password"
                    />
                </div>
                <div className="user-setting">
                    <Label for="userSaveName">{t('users.repeatPassword')}</Label>
                    <Input autoFocus={false}
                        id={"userNewPasswordRepeat"}
                        placeholder={t('users.repeatPassword')}
                        onChange={event => setPasswordModel({ ...passwordModel, repeatNewPassword: event.target.value })}
                        type="password"
                    />
                </div>
                <Button
                    color="primary"
                    onClick={() => dispatch(changeUserPassword(passwordModel))}
                    className="user-save-button"
                >
                    {t('controls.modal.save')}
                </Button>




                <div className="user-setting">
                    <Label for="userSaveName" className="checkbox-label">{t('users.sendEventNotificationsEmail')}</Label>
                    <Input
                        id={"userEmailEventSetting"}
                        checked={user.isSendEventNotificationByEmail }
                        onChange={event => saveNotificationPreferences(event.target.checked)}
                        type="checkbox"
                    />
                </div>




                {/*onChange={event => setName(event.target.value)}*/}
                {/*onKeyPress={event => CheckEnter(event, saveEditProject)}*/}
            </div>
        </div>
    </>);
}

export default UserMainSettings;