import * as React from 'react';
import { useEffect } from 'react';
import { Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from 'react-redux';
import EditTask from './components/editTask';
import Task from './components/Task';
import { getProjectTasks, loadProjectTasks } from './tasksSlice';

const Tasks = (props) => {
    const dispatch = useDispatch();
    var tasksList = useSelector(loadProjectTasks);
    useEffect(() => {
        dispatch(getProjectTasks(props.projectGuid));
    }, []);

    var index = tasksList.findIndex(t => t.projectGuid == props.projectGuid);
    var tasksList = index>= 0 ? tasksList[index].tasksList : null;
        //tasksList.length >= 0 && tasksList.findIndex(t => t.projectGuid == props.projectGuid) ?
        //tasksList[tasksList.findIndex(t => t.projectGuid == props.projectGuid)].tasksList : null;
  
    return (
        <div className="project-tasks-list">
            <EditTask task={{ projectGuid: props.projectGuid }}  />
            <div id={props.projectGuid} className="project-task-list">
                <div>
                    <ul key={"ul_" + props.projectGuid} id={"ul_" + props.projectGuid} className="task-list task-sortable">
                        <Droppable droppableId={props.projectGuid} >
                            {(provided, snapshot) => (
                                <div className="droppable-tasks"
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {tasksList && tasksList.map((task, index) => (
                                        <Draggable key={task.guid} draggableId={task.guid} index={index} >
                                            {(provided, snapshot) => (
                                                <div className="draggable-free"
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <Task key={"task_" + task.guid} task={task} />
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Tasks;