import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import ButtonNoAction from '../../components/buttonNoAction';
import Icon from '../../components/icon';
import Loading from '../../components/loading';
import Article from './Article';
import { loadArticle } from './articleSlice';
import Tooltip from '../../components/toolTip';
import { currentUser } from '../user/userSlice';
import { useTranslation } from 'react-i18next';

const Articles = () => {
    const { t } = useTranslation();
    const article = useSelector(loadArticle);
    var user = useSelector(currentUser);
    const [isLoading, setLoading] = useState(false);

    const exit = () => {
        setLoading(true);
        window.location.href = window.location.origin;
    };
    if (isLoading) {
        return (<Loading />);
    }

    return (<>
        <Router>
            <div className="article-panel">
                { }
                {t('articles.select')}
                <header id="mainPanel" className="article-top-panel white-background">
                    <Link to="/Articles/Projects"><ButtonNoAction className={"left-button" + (article.pathName == "Projects" ? " active-article" : "")} guid="Projects" type="projects" help={t('nav.help.projects')} /></Link>
                    <Link to="/Articles/Calendar"><ButtonNoAction className={"left-button" + (article.pathName == "Calendar" ? " active-article" : "")} guid="Calendar" type="calendar" help={t('nav.help.calendar')} /></Link>
                    <Link to="/Articles/Kanban"><ButtonNoAction className={"left-button" + (article.pathName == "Kanban" ? " active-article" : "")} guid="Kanban" type="kanban" help={t('nav.help.kanban')} /></Link>
                    <Link to="/Articles/Weight"><ButtonNoAction className={"left-button" + (article.pathName == "Weight" ? " active-article" : "")} guid="Weight" type="weight" help={t('nav.help.weight')} /></Link>
                    {/*{user && <Link to="/Articles/Exit"><ButtonNoAction className={"right-button" + (article.pathName == "Exit" ? " active-article" : "")} guid="Exit" type="exit" help="Выход" /></Link>}*/}
                    {
                        <>
                            <button id={"Exit"} className="right-button " onClick={() => { window.location.href = window.location.origin; }}>
                                <Icon image={"/images/exit.svg"} guid={"logout"} imageName="exit" />
                            </button>
                            <Tooltip id={"Exit"} text={t('nav.help.exit')} />
                        </>
                    }
                    <Link to="/Articles/Articles"><ButtonNoAction className={"right-button" + (article.pathName == "Articles" ? " active-article" : "")} guid="Articles" type="wiki" help={t('nav.help.wiki')} /></Link>
                    <Link to="/Articles/Help"> <ButtonNoAction className={"right-button" + (article.pathName == "Help" ? " active-article" : "")} guid="Help" type="help" help={t('nav.help.help')} /></Link>
                    <Link to="/Articles/Letters"><ButtonNoAction className={"right-button" + (article.pathName == "Letters" ? " active-article" : "")} guid="Letters" type="letters" help={t('nav.help.letters')} /></Link>
                    <Link to="/Articles/User"><ButtonNoAction className={"right-button" + (article.pathName == "User" ? " active-article" : "")} guid="User" type="user" help={t('nav.help.user')} /></Link>
                </header>
                <div>
                    <Switch>
                        <Route exact path="/Articles/Projects"><Article guid="Projects" /></Route>
                        <Route exact path="/Articles/Kanban"><Article guid="Kanban" /></Route>
                        <Route exact path="/Articles/Weight"><Article guid="Weight" /></Route>
                        <Route exact path="/Articles/Articles"><Article guid="Articles" /></Route>
                        <Route exact path="/Articles/Help"><Article guid="Help" /></Route>
                        <Route exact path="/Articles/User"><Article guid="User" /></Route>
                        <Route exact path="/Articles/Letters"><Article guid="Letters" /></Route>
                        <Route exact path="/Articles/Calendar"><Article guid="Calendar" /></Route>
                    </Switch>
                </div>
            </div>
        </Router>
    </>);
}
export default Articles;