import * as React from 'react';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '../../components/button';
import { toggleEditArticle, loadArticles, getArticles } from './adminSlice';
import ArticleLine from './WikiPage/articleLine';
import EditArticle from './WikiPage/editArticleModal';


const Wiki = () => {
    const dispatch = useDispatch();
    const articles = useSelector(loadArticles);

    useEffect(() => {
        dispatch(getArticles());
    }, [dispatch]);
    return (
        <>
            <Button type="add" help="Добавить статью" className="big-button" action={toggleEditArticle} />
            <ul>
                {articles.map((article: any) =>
                    <ArticleLine article={article } />
            )}
            </ul>
            <EditArticle />
        </>);
}

export default Wiki;