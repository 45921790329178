import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from 'reactstrap';
import Button from '../../components/button';
import Paging from '../../components/pagination';
import NewLetter from './createLetterModal';
import Letter from './letterItem';
import { getLetters, loadLetters, loadLettersCurrentPage, loadLettersTotalCount, loadNeedUpdate, toggleOpenNewLetter } from './lettersSlice';

const Letters = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const letters = useSelector(loadLetters);
    const isNeedUpdate = useSelector(loadNeedUpdate);
    const currentPage = useSelector(loadLettersCurrentPage);
    const totalCount = useSelector(loadLettersTotalCount);
    const [filterData, setFilterData] = useState({ startIndex: 0, search: ""});
    useEffect(() => {
        if (isNeedUpdate) {
            dispatch(getLetters(filterData));
        }
    }, [isNeedUpdate, filterData]);

    useEffect(() => {
        //if (!filterData.isInitial)
        {
            const delayDebounceFn = setTimeout(() => {
                dispatch(getLetters(filterData));
            }, 1000)
            return () => clearTimeout(delayDebounceFn)
        }
    }, [filterData.search])
  
    return (<>
        <div className="letters-action-block">
            <Button id="createNewLetter" type="add" help={t('letters.create')} action={toggleOpenNewLetter} guid={null} />
            <Input id="letterActionSearch" type="text" className="letter-search"
                placeholder={t('letters.search')}
                onChange={(ev) =>
                    setFilterData({ ...filterData, search: ev.target.value, startIndex: 0 })} />
            {letters && totalCount > 10 &&
                <Paging filterData={filterData} action={getLetters} currentPage={currentPage} totalCount={totalCount} />
            }

        </div>
        <div className="letters-panel">
            {
                letters && letters.map(x =>
                    <Letter key={x.letterGuid} letter={x} />
                    )
            }
            <NewLetter />
        </div>
    </>);
}

export default Letters;