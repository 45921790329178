import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Icon from '../../../components/icon';
import Tooltip from '../../../components/toolTip';
import { toggleEditEvent } from '../../calendar/calendarSlice';

export default function EditCalendarTaskButton(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const id = "editEvent_" + props.event.guid;
    return (
        <>
            <button id={id} className="small-button" onClick={() => dispatch(toggleEditEvent(props.event))}>
                <Icon image="/images/edit.svg" guid={props.event.guid} imageName="edit" />
            </button>
            <Tooltip id={id} text={t('tasks.editEvent')} />
        </>
    );
}