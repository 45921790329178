import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Input } from 'reactstrap';
import Button from '../../../components/button';
import CheckEnter from '../../../functions/controls';
import { formatDate } from '../../../functions/functions';
import { getData, saveDate, setEditableDate } from '../weightSlice';

const EditDate = (props: any) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const oldDate = props.date != null? formatDate(props.date) : "";
    const [date, setDate] = useState(oldDate);
    const saveEditDate = async () => {
        await dispatch(saveDate({ oldDate: oldDate, newDate: date }));
      //  await dispatch(setEditableDate({}));
       // dispatch(getData());
    }

    const cancelEditDate = async () => {
        await dispatch(setEditableDate({}));
        dispatch(getData());
    }

    const setDateInput = (event: any) => {
        setDate(event.value);
    }
    return (<>
        <div className="weight-edit">
            <Input value={date} className="weight-edit-name" type="date"
                onChange={event => setDateInput(event.target) } 
                max={new Date().toISOString().split("T")[0]}
                onKeyPress={event => CheckEnter(event, saveEditDate)} />
            <div className="action-button-group weight-button-group">
                <Button props={date} type="ok" action={saveEditDate} help={t('controls.button.save')}
                    className="small-button" />
                <Button props={date} type="cancel" action={cancelEditDate} help={t('controls.button.cancel')}
                    className="small-button" />
            </div>
        </div>
    </>);
};

export default EditDate;