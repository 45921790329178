import * as React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { helpLoad } from './helpSlice';

const HelpProject = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(helpLoad(null));
    }, []);

    return (
        <div className="help-panel">
            {t('help.freeText')}
            <br />{t('help.yandexMoney')}<a href="https://yoomoney.ru/to/4100117821890983">https://yoomoney.ru/to/4100117821890983</a>
            <br />{t('help.form')}<br />
            <iframe src="https://yoomoney.ru/quickpay/shop-widget?writer=seller&default-sum=100&button-text=12&payment-type-choice=on&successURL=plannado.com&quickpay=shop&account=4100117821890983&targets=%D0%9F%D0%B5%D1%80%D0%B5%D0%B2%D0%BE%D0%B4%20%D0%BF%D0%BE%20%D0%BA%D0%BD%D0%BE%D0%BF%D0%BA%D0%B5&" width="422" height="220" scrolling="no"></iframe>
            <br />
            <br />{t('help.suggestions')}<u>admin@plannado.com</u>
        </div>
        );
}

export default HelpProject;