import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { formatDateDefaultTime, formatDateFromShortDate, formatDateToShortDate } from '../../functions/functions';
import i18n from '../../i18n'

export const getDays = createAsyncThunk(
    'calendar/getDays',
    async (obj: any) => {
        const data = await fetch("/CalendarControll/GetDays?" + new URLSearchParams(obj));
        const json = await data.json();
        return json;
    }
);

export const getDayEvents = createAsyncThunk(
    'calendar/getDayEvents',
    async (date: any) => {
        const data = await fetch("/CalendarControll/GetDayEvents?" + new URLSearchParams({
            date: formatDateFromShortDate(date),
        }));
        const json = await data.json();
        return json;
    }
);

export const saveEvent = createAsyncThunk(
    'calendar/saveEvent',
    async (event: any) => {
        var obj = {
            ...event,
            date: formatDateDefaultTime(event.date),
            name: event.name ? event.name : "",
            pattern: { key: event.pattern ? event.pattern.key : "0", value: event.pattern ? event.pattern.value : "None" },
            position: event.position

        };
        const data = await fetch('/CalendarControll/SaveEvent',
            {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    'Accept': 'application/json',
                },
                body: JSON.stringify(obj)
            });

        const json = await data.json();
        return json;
    });


export const changeStatus = createAsyncThunk(
    'calendar/changeStatus',
    async (event: { guid: string, status: number }) => {
        const data = await fetch("/CalendarControll/ChangeStatus?"
            + new URLSearchParams({ guid: event.guid, newStatus: event.status.toString() }));
        return await data.json();
    }
);

export const deleteEvent = createAsyncThunk(
    'calendar/deleteEvent',
    async (obj: any) => { // {guid, isAll}
        console.log(obj);
        const data = await fetch("/CalendarControll/DeleteEvent?" + new URLSearchParams({
            guid: obj.guid,
            isAll: obj.isAll,
        }));
        const json = await data.json();
        return json;
    }
);

export const getPatterns = createAsyncThunk(
    'calendar/getPatterns',
    async () => {
        const data = await fetch("/CalendarControll/GetPatterns");
        const json = data.json();
        return json;
    }
);

export const changeEventPosition = createAsyncThunk(
    'calendar/changeEventPosition',
    async (model: { parentDate: string, order: any }) => {
        const data = await fetch("/CalendarControll/ChangeEventPosition", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Accept': 'application/json',
            },
            body: JSON.stringify({ parentDate: formatDateFromShortDate(model.parentDate), order: model.order })
        });
        return await data.json();
    }
);

const DefaultEvent = {
    guid: null,
    name: "",
    date: "",
    pattern: 0,
    status: 0,
};

export const calendarSlice = createSlice({
    name: "calendar",
    initialState: {
        daysBefore: 0,
        daysAfter: 7,
        daysCount: 31,
        days: [] as any[],
        daysCopy: [] as any[],
        daysTitles: [] as any[],
        isOpenEdit: false,
        dayEvents: {},
        event: DefaultEvent,
        isNeedUpdate: true,
        messageData: {},
        mode: 1,
        weekDays: i18n.t('constances.weekDays', { returnObjects: true }),
        weekDaysShort: i18n.t('constances.weekDaysShort', { returnObjects: true }),
        isLoading: false,
        step: 0,
        openedEvent: DefaultEvent,
        patterns: [] as any[],
        hiddenAll: window.screen.width <= 500,
        selectedET: "all", //"all","events","tasks"
        isOpenDelete: false,
        selectedDateStart: (new Date()).toString(),
    },
    reducers: {
        updateEvent: (state, action) => {
            state.event = action.payload;
        },
        toggleEditEvent: (state, action) => {
            state.isOpenEdit = !state.isOpenEdit;
            state.event = action.payload;
            state.openedEvent = DefaultEvent;
        },
        cClearMessage: (state, action) => {
            state.messageData = {};
        },
        changeMode: (state, action) => {
            if (action.payload != state.mode) {
                state.mode = action.payload;
                state.step = 0;
                state.isNeedUpdate = true;
                state.days = [];
            }
            // state.openedEvent = DefaultEvent;
        },
        toggleIsLoadding: (state, action) => {
            state.isLoading = action.payload;
        },
        back: (state, action) => {
            state.step = state.step - 1;
            state.isNeedUpdate = true;
            state.openedEvent = DefaultEvent;
        },
        forward: (state, action) => {
            state.step = state.step + 1;
            state.isNeedUpdate = true;
            state.openedEvent = DefaultEvent;
        },
        toggleEventDescription: (state, action) => {
            state.openedEvent = action.payload ? action.payload : DefaultEvent;
        },
        toggleSelectedET: (state, action) => {
            state.selectedET = action.payload;
        },
        toggleShowHideCalendarAll: (state, action) => {
            state.hiddenAll = !state.hiddenAll;
            state.days = current(state.days).map(pr => ({ ...pr, isHidden: state.hiddenAll }));
        },
        toggleShowHideCalendar: (state, action) => {
            state.hiddenAll = false;
            var date = action.payload;
            state.days = current(state.days).map(pr => pr.date == date ? { ...pr, isHidden: !pr.isHidden } : pr);
        },
        setHiddenAllCalendarFalse: (state, action) => {
            state.hiddenAll = false;
        },
        clearCalendar: (state, action) => {
            state.days = [];
            state.isNeedUpdate = true;
        },
        updateCounts: (state, action) => {
            state.days = state.days.map(x => ({
                ...x,
                totalCount: x.events?.length ?? 0, doneCount: x.events?.filter(y => y.status == 4)?.length ?? 0
            }));
            state.daysTitles = state.days.map(d => ({
                date: d.date,
                title: ((d.doneCount > 0 ? `(${d.doneCount}/${d.totalCount})` : d.totalCount > 0 ? `(${d.totalCount})` : "") + d.dayName)
            }));
        },
        setDaysEvents: (state, action) => {
            var index = current(state.days).map(e => e.date).indexOf(action.payload.date);
            if (index >= 0) {
                state.days[index].events = action.payload.events;
            }
            state.dayEvents = { date: action.payload.date, events: action.payload.events };
        },
        toggleDeleteWindow: (state, action) => {
            state.isOpenDelete = !state.isOpenDelete;
            state.openedEvent = action.payload == null ? DefaultEvent : action.payload.event;
        }
    },
    extraReducers:
        (builder) => {
            builder
                .addCase(getDays.fulfilled, (state, action) => {
                    state.isLoading = true;
                    state.isNeedUpdate = false;
                    state.daysCount = action.payload.data.daysCount;
                    state.daysBefore = action.payload.data.daysBefore;
                    state.daysAfter = action.payload.data.daysAfter;
                    state.days = action.payload.data.days;
                    if (state.hiddenAll) {
                        state.days = state.days.map(x => ({ ...x, isHidden: true }));
                    }
                    console.log(action.payload.data.dateStart);
                    state.selectedDateStart = action.payload.data.dateStart; 
                    state.isLoading = false;
                })
                .addCase(getDayEvents.fulfilled, (state, action) => {
                    state.isLoading = true;
                    state.isNeedUpdate = false;
                    var date = formatDateToShortDate(action.payload.data.date);
                    state.days = current(state.days).map(x => x.date == date ? { ...x, events: action.payload.data.events } : x);
                    //state.dayEvents = { date: date, events: action.payload.data.events };
                    calendarSlice.caseReducers.updateCounts(state, action);
                    state.isLoading = false;
                })
                .addCase(saveEvent.fulfilled, (state, action) => {
                    var event = action.payload.data.eventData;
                    var isUpdate = action.payload.data.isUpdate;
                    if (action.payload.message) {
                        state.messageData = { message: action.payload.message, isVisible: true, status: action.payload.status };
                    }
                    if (event) {
                        state.event = event;
                        state.openedEvent = event;
                        state.isOpenEdit = false;
                    }
                    var date = formatDateToShortDate(event.date);
                    var index = state.days.findIndex(x => x.date == date);
                    if (index >= 0 && isUpdate) {
                        if (current(state.days[index]).events.find(x => x.guid == event.guid)) {
                            state.days[index].events = current(state.days[index].events).map(x => x.guid == event.guid ? event : x);
                        }
                        else {
                            state.days[index].events.unshift(event);
                        }
                        //state.dayEvents = { date: formatDateToShortDate(event.date), events: state.days[index].events };
                    }
                    for (var i = 0; i < state.days.length; i++) {
                        state.days[i].events = current(state.days[i]).events.filter(x => x.date == event.date || x.guid != event.guid);
                        //state.dayEvents = { date: state.days[i].date, events: state.days[i].events };
                    }
                    calendarSlice.caseReducers.updateCounts(state, action);
                    state.isNeedUpdate = true;
                })
                .addCase(deleteEvent.fulfilled, (state, action) => {
                    state.isNeedUpdate = true;
                    state.openedEvent = DefaultEvent;
                    var index = current(state.days).map(e => e.date).indexOf(formatDateToShortDate(action.payload.data.date));
                    if (index >= 0) {
                        state.days[index].events = current(state.days[index]).events.filter(t => t.guid != action.payload.data.guid);
                        //state.dayEvents = { date: formatDateToShortDate(action.payload.data.date), events: state.days[index].events };
                       // state.days[index].taskCompletedPercent = action.payload.data.taskCompletedPercent;
                    }
                    else {
                        console.log("error");
                        //error
                    }
                    if (action.payload.message) {
                        state.messageData = { message: action.payload.message, isVisible: true, status: action.payload.status };
                    }
                    calendarSlice.caseReducers.updateCounts(state, action);
                //    state.tasksList = current(state.tasksList).filter(t => t.guid != action.payload.data.guid);
                })
                .addCase(getPatterns.fulfilled, (state, action) => {
                    state.patterns = action.payload.data;
                })
                .addCase(changeStatus.fulfilled, (state, action) => {
                    var index = current(state.days).map(e => e.date).indexOf(formatDateToShortDate(action.payload.data.event.date));
                    state.event = {
                        guid: action.payload.data.event.guid,
                        date: action.payload.data.event.date,
                        name: action.payload.data.event.name,
                        status: action.payload.data.event.status,
                        pattern: action.payload.data.event.pattern,
                    };
                    if (index >= 0) {
                        state.days[index].events = current(state.days[index].events).map(t => t.guid == action.payload.data.event.guid ? action.payload.data.event : t);
                        //state.dayEvents = { date: formatDateToShortDate(action.payload.data.event.date), events: state.days[index].events };
                    }
                    else {
                        console.log("error");
                        //error
                    }
                    if (action.payload.message) {
                        state.messageData = { message: action.payload.message, isVisible: true, status: action.payload.status };
                    }
                    calendarSlice.caseReducers.updateCounts(state, action);
                })
                .addCase(changeEventPosition.fulfilled, (state, action) => {
                    if (action.payload.message) {
                        state.messageData = { message: action.payload.message, isVisible: true, status: action.payload.status };
                    }
                    var date = formatDateToShortDate(action.payload.data.recipientDate);
                    state.days = current(state.days).map(x => x.date == date ? { ...x, events: action.payload.data.recipientEvents } : x);
                    //for (var i = 0; i < state.days.length; i++) {
                    //    debugger;
                    //    state.dayEvents = { date: state.days[i].date, events: state.days[i].events };
                    //    console.log(state.dayEvents);
                    //}
                    //state.dayEvents = { date: date, events: action.payload.data.recipientEvents };
                    calendarSlice.caseReducers.updateCounts(state, action);
                })
        }
});

export const loadCalendar = (state) => state.calendar;
export const openEdit = (state) => state.calendar.isOpenEdit;
export const loadEvent = (state) => state.calendar.event;
export const loadOpenedEvent = (state) => state.calendar.openedEvent;
export const cMessage = (state) => state.calendar.messageData;
export const needUpdate = (state) => state.calendar.isNeedUpdate;
export const calendarMode = (state) => state.calendar.mode;
export const weekDaysNames = (state) => state.calendar.weekDaysShort;
export const cIsLoading = (state) => state.calendar.isLoading;
export const stepLoad = (state) => state.calendar.step;
export const loadPatterns = (state) => state.calendar.patterns;
/*export const loadDayEvents = (state) => state.calendar.dayEvents;*/
export const loadDaysTitles = (state) => state.calendar.daysTitles;
export const loadSelectedET = (state) => state.calendar.selectedET;
export const loadIsOpenDelete = (state) => state.calendar.isOpenDelete;
export const loadSelectedDateStart = (state) => state.calendar.selectedDateStart;
 
export const { toggleEditEvent, cClearMessage, changeMode, toggleIsLoadding, back, forward,
    toggleEventDescription, updateEvent, toggleShowHideCalendar, toggleShowHideCalendarAll,
    setHiddenAllCalendarFalse, clearCalendar, setDaysEvents, toggleSelectedET, toggleDeleteWindow } = calendarSlice.actions;

export default calendarSlice.reducer;