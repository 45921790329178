import * as React from 'react';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../components/button';
import { formatDateInput } from '../../functions/functions';
import { toggleEditEvent, deleteEvent, loadEvent } from './calendarSlice';
import EventActionsBlock from './eventActionsBlock';


const Event = (props) => {
    const loadedEvent = useSelector(loadEvent);
    const [event, setEvent] = useState(props.event);
    useEffect(() => {
        if (loadedEvent.guid == event.guid) {
            setEvent(loadedEvent);
        }
    }, [loadedEvent]);

    return (<>
        <div className="event-block" key={"key_" + event.guid}>
            <div className="event-name">
                {event.name}
            </div>
            {/*<div className="action-button-group calendar-button-group event-button-group">*/}
            {/*    <Button type="edit" className="smallest-button" params={{ ...event, date: formatDateInput(event.date)}}*/}
            {/*        action={toggleEditEvent} help="Редактировать событие" />*/}
            {/*    <Button type="delete" className="smallest-button" guid={event.guid}*/}
            {/*        action={deleteEvent} help="Удалить событие" />*/}
            {/*</div>*/}
            <EventActionsBlock event={event } />
        </div>
    </>);
}

export default Event;