import * as React from 'react';
import { useSelector } from 'react-redux';
import { formatDateToShortDate, formatDateToShortDateWithoutYear } from '../../functions/functions';
import { loadOpenedEvent } from "./calendarSlice";
import EventActionsBlock from './eventActionsBlock';

const EventDescription = () => {
    const event = useSelector(loadOpenedEvent);
    React.useEffect(() => {
        console.log(event);
    }, [event]);
    return (<>
        {event.name &&
            <div className="event-description">
                <div className="event-description-text"> {formatDateToShortDateWithoutYear(event.date) + ": " + event.name}</div>
                <EventActionsBlock event={event} isDesc={ true} />
            </div>
        }
    </>);
}

export default EventDescription;