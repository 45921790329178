import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ScrollableText from '../../../components/scrollableText';
import { loadDaysTitles, toggleShowHideCalendar } from '../../calendar/calendarSlice';
import CalendarTasks from '../../task/CalendarTasks';
import { loadHidden, setHiddenAllFalse } from '../projectsSlice';
import ShowHideButton from '../../../components/ShowHideButton';

export default function CalendarProject(props) {
    const dispatch = useDispatch();
    const hiddenAll = useSelector(loadHidden);
    const daysTitles = useSelector(loadDaysTitles);
    const day = props.day;
    const [titleText, setTitleText] = useState(day.title);
    useEffect(() => {
        var find = daysTitles.find(x => x.date == day.date);
        setTitleText(find ? find.title : day.dayName);
    }, [daysTitles]);

    const toggleHidden = () => {
        dispatch(setHiddenAllFalse(null));
        dispatch(toggleShowHideCalendar(day.date));
    }
    var className = hiddenAll || day.isHidden ? "project-minimized" : "";
    className += " project-item";
    var styleBorder = day.color ? { borderLeft: `4px solid ${day.color}`, paddingLeft: "0" } : { paddingLeft: "4px" };
    return (
        <li className={className} key={day.date}>
            <div className="project-content calendar-project-content" style={styleBorder }>
                <div className="project-title white-background noselect" >
                    <ScrollableText className="project-title-text project-title-text" text={titleText} width="270px" maxLength={18} />
                    <div className="action-button-group project-button-group">
                        <ShowHideButton id={day.dayName} guid={day.date} hidden={day.isHidden} action={toggleHidden} />
                    </div>
                </div>
                <CalendarTasks events={day.events} date={day.date} />
                {/*<CalendarTasks */}{/*events={day.events}*/}{/* date={ day.date} />*/}
            </div>
        </li>
    );
}