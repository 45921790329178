import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import { stat } from 'fs';

export const saveTask = createAsyncThunk(
    'tasks/saveTask',
    async (task: any) => {
        const data = await fetch('/Tasks/SaveTask', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Accept': 'application/json',
            },
            body: JSON.stringify(task)
        });

        const json = await data.json();
        return json;
    }
);

export const getTask = createAsyncThunk(
    'tasks/getTask',
    async (guid: string) => {
        const data = await fetch("/Tasks/GetTask?" + new URLSearchParams({ guid: guid }));
        return await data.json();
    }
);

export const getProjectTasks = createAsyncThunk(
    'tasks/getProjectTasks',
    async (guid: string) => {
        const data = await fetch("/Tasks/GetProjectTasks?" + new URLSearchParams({guid: guid}));
        const json = await data.json();
        return json;
    });

export const getTasks = createAsyncThunk(
    'tasks/getTasks',
    async (props: any) => {
        console.log("/Tasks/GetTasks?" + new URLSearchParams(JSON.stringify({
            labelGuids: props && props.labelGuids,
            groupGuid: props && props.groupGuid,
            projectGuids: props && props.projectGuids
        })));
        const data = await fetch("/Tasks/GetTasks?" + new URLSearchParams({
                labelGuids: props && props.labelGuids,
                groupGuid: props && props.groupGuid,
               projectGuids: props && props.projectGuids
            }));
        const json = await data.json();
        return json;
    });

export const deleteTask = createAsyncThunk(
    'tasks/deleteTask',
    async (task: { guid: string, projectGuid: string }) => {
        const data = await fetch("/Tasks/DeleteTask?" + new URLSearchParams({ guid: task.guid, projectGuid: task.projectGuid }));
        return await data.json();
    }
);

export const changeStatus = createAsyncThunk(
    'tasks/changeStatus',
    async (task: { guid: string, status: number, parentGuid: string }) => {
        const data = await fetch("/Tasks/ChangeStatus?"
            + new URLSearchParams({ guid: task.guid, newStatus: task.status.toString(), projectGuid: task.parentGuid }));
        return await data.json();
    }
);

export const changePosition = createAsyncThunk(
    'tasks/changePosition',
    async (model: { parentGuid: string, order: any }) => {
        const data = await fetch("/Tasks/ChangeTaskPosition", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Accept': 'application/json',
            },
            body: JSON.stringify({ parentGuid: model.parentGuid, order: model.order })
        });
        return await data.json();
    }
);

export const tasksSlice = createSlice({
    name: "tasks",
    initialState: {
        isLoading: false,
        projectTasks: new Array(),
        task: {} as any,
        taskCompletedPercent: 0,
        message: {},
        tasksList: new Array(),
        taskSettingsOpen: false,
    },
    reducers: {
        toggleEditTask: (state, action) => {
           // state.task = { ...action.payload, isEditMode: state.task || !state.task.isEditMode };
            if (action.payload) {
                state.task = {
                    guid: action.payload.guid,
                    name: action.payload.name,
                    projectGuid: action.payload.projectGuid,
                    isEditMode: !action.payload.isEditMode,
                    status: action.payload.status,
                    executorId: action.payload.executorId,
                }
            }
        },
        toggleExtendedEditTask: (state, action) => {
            state.task = action.payload;
        //    tasksSlice.caseReducers.toggleEditTask(state, action);
            state.taskSettingsOpen = !state.taskSettingsOpen;
        },
        setProjectTasks: (state, action) => {
            var index = current(state.projectTasks).map(e => e.projectGuid).indexOf(action.payload.projectGuid);
            if (index >= 0) {
                state.projectTasks[index].tasksList = action.payload.tasks;
            }
        },
        tClearMessage: (state, action) => {
            state.message = {};
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(saveTask.pending, (state, action) => {
              //  state.isLoading = true;
            })
            .addCase(saveTask.fulfilled, (state, action) => {
                if (action.payload.message) {
                    state.message = { message: action.payload.message, isVisible: true, status: action.payload.status };
                }
                if (action.payload.data) {
                    state.task = {
                        guid: action.payload.data.task.guid,
                        projectGuid: action.payload.data.task.projectGuid,
                        name: action.payload.data.task.name,
                        status: action.payload.data.task.taskStatus,
                        isEditMode: false,
                        executorId: action.payload.executorId,
                    }
                    var index = current(state.projectTasks).findIndex(x => x.projectGuid == action.payload.data.task.projectGuid);
                    if (index >= 0) {
                        if (current(state.projectTasks[index].tasksList).some(x => x.guid == action.payload.data.task.guid)) {
                            state.projectTasks[index].tasksList = state.projectTasks[index].tasksList.map(t => t.guid == action.payload.data.task.guid ? action.payload.data.task : t);
                            state.projectTasks[index].taskCompletedPercent = action.payload.data.task.taskCompletedPercent;
                        }
                        else {
                            state.projectTasks[index].tasksList.unshift(action.payload.data.task);
                            state.projectTasks[index].taskCompletedPercent = action.payload.data.task.taskCompletedPercent;
                        }
                    }
                    if (state.tasksList) {
                        state.tasksList = state.tasksList.map(x => x.guid == action.payload.data.task.guid ?
                            { ...action.payload.data.task, status: action.payload.data.task.taskStatus } : x);
                    }
                    state.isLoading = false;
                }
            })
            .addCase(saveTask.rejected, (state, action) => {
                state.isLoading = false;
            })

            .addCase(getProjectTasks.pending, (state, action) => {
            //    state.isLoading = true;
            })
            .addCase(getProjectTasks.fulfilled, (state, action) => {
                const obj = {
                    projectGuid: action.payload.data.projectGuid,
                    tasksList: action.payload.data.tasks,
                    taskCompletedPercent: action.payload.data.taskCompletedPercent
                };
                var index = state.projectTasks.map(e => e.projectGuid).indexOf(action.payload.data.projectGuid);
                if (index >= 0) {
                    state.projectTasks[index] = obj;
                }
                else {
                    state.projectTasks = [...state.projectTasks, obj];
                }
                state.isLoading = false;
            })
            .addCase(getProjectTasks.rejected, (state, action) => {
                state.isLoading = false;
            })

            .addCase(getTask.pending, (state, action) => {
            })
            .addCase(getTask.fulfilled, (state, action) => {
                state.task = action.payload.data;
            })
            .addCase(getTask.rejected, (state, action) => {
            })

            .addCase(getTasks.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(getTasks.fulfilled, (state, action) => {
                state.tasksList = action.payload.data;
                if (action.payload.message) {
                    state.message = { message: action.payload.message, isVisible: true, status: action.payload.status };
                }
                state.isLoading = false;
            })
            .addCase(getTasks.rejected, (state, action) => {
                state.isLoading = false;
            })


            .addCase(deleteTask.pending, (state, action) => {
            })
            .addCase(deleteTask.fulfilled, (state, action) => {
                var index = current(state.projectTasks).map(e => e.projectGuid).indexOf(action.payload.data.projectGuid);
                if (index >= 0) {
                    state.projectTasks[index].tasksList = current(state.projectTasks[index]).tasksList.filter(t => t.guid != action.payload.data.guid);
                    state.projectTasks[index].taskCompletedPercent = action.payload.data.taskCompletedPercent;
                }
                else {
                    //error
                }
                if (action.payload.message) {
                    state.message = { message: action.payload.message, isVisible: true, status: action.payload.status };
                }
                state.tasksList = current(state.tasksList).filter(t => t.guid != action.payload.data.guid);
            })
            .addCase(deleteTask.rejected, (state, action) => {
            })
            .addCase(changeStatus.pending, (state, action) => {
            })
            .addCase(changeStatus.fulfilled, (state, action) => {
                var index = current(state.projectTasks).map(e => e.projectGuid).indexOf(action.payload.data.task.projectGuid);
                state.task = {
                    guid: action.payload.data.task.guid,
                    projectGuid: action.payload.data.task.projectGuid,
                    name: action.payload.data.task.name,
                    status: action.payload.data.task.taskStatus,
                    isEditMode: false,
                    executorId: action.payload.executorId,
                };
                if (index >= 0) {
                    state.projectTasks[index].tasksList = current(state.projectTasks[index].tasksList).map(t => t.guid == action.payload.data.task.guid ? action.payload.data.task : t);
                }
                else {
                    //error
                }
                if (state.tasksList) {
                    state.tasksList = state.tasksList.map(x => x.guid == action.payload.data.task.guid ?
                        { ...action.payload.data.task, status: action.payload.data.task.taskStatus } : x);
                }
                if (action.payload.message) {
                    state.message = { message: action.payload.message, isVisible: true, status: action.payload.status };
                }
            })
            .addCase(changeStatus.rejected, (state, action) => {
            })

            .addCase(changePosition.fulfilled, (state, action) => {
                if (action.payload.message) {
                    state.message = { message: action.payload.message, isVisible: true, status: action.payload.status };
                }
            })
    }
});

export const loadTask = (state) => state.task.task;
export const loadProjectTasks = (state) => state.task.projectTasks;
export const loadTasksList = (state) => state.task.tasksList;
export const completedPercent = (state) => state.task.taskCompletedPercent;
export const tMessage = (state) => state.task.message;
export const noUpdate = (state) => state.task.isNoUpdate;
export const loadSettingsOpen = (state) => state.task.taskSettingsOpen;
export const tIsLoading = (state) => state.task.isLoading;

export const { toggleEditTask, toggleExtendedEditTask, setProjectTasks, tClearMessage } = tasksSlice.actions;

export default tasksSlice.reducer;