import * as React from 'react';
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import { fileURLToPath } from 'url';
import Button from '../../components/button';
import Paging from '../../components/pagination';
import { getLogs, logsList, logsTotal, currentPage, deleteLogs } from './adminSlice';

const Logs = () => {
    const dispatch = useDispatch();
    const logs = useSelector(logsList);
    var logsTotalCount = useSelector(logsTotal);
    var logsCurrentPage = useSelector(currentPage);
    const [filterData, setFilterData] = useState({
        startIndex: 0,
        take: 30,
        dateFrom: (new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)).toISOString().substring(0, 10),
        dateTo: (new Date()).toISOString().substring(0, 10),
        action: "",
        user: "",
        hideAdmin: false,
        hideEmpty: false,
        isInitial: true
    });

    useEffect(() => {
        dispatch(getLogs(filterData));
    }, []);

    useEffect(() => {
        if (!filterData.isInitial) {
            dispatch(getLogs(filterData));
        }
    }, [filterData.dateFrom, filterData.dateTo, filterData.hideAdmin, , filterData.hideEmpty]);

    useEffect(() => {
        if (!filterData.isInitial) {
            const delayDebounceFn = setTimeout(() => {
                dispatch(getLogs(filterData));
            }, 1000)
            return () => clearTimeout(delayDebounceFn)
        }
    }, [filterData.action, filterData.user])

    const cleanLogs = async() => {
        await dispatch(deleteLogs());
        dispatch(getLogs(filterData));
    }

    return (
        <div>
            <div className="admin-filter-panel">
                <div className="admin-filter-item">
                    <Label for="adminLogsDateFrom">Дата с </Label>
                    <Input id="adminLogsDateFrom" type="date"
                        defaultValue={filterData.dateFrom} min="2022-07-01" max={new Date().toISOString().split("T")[0]}
                        onChange={ev => {
                            var date = new Date(ev.target.value).toISOString();
                            setFilterData({ ...filterData, dateFrom: date, startIndex: 0, isInitial: false }); //date.getDate() + "." + date.getMonth() + "." + date.getFullYear();
                            //onChangeInput();
                        }} />
                </div>
                <div className="hyphen"></div>
                <div className="admin-filter-item">
                    <Label for="adminLogsDateTo">Дата по </Label>
                    <Input id="adminLogsDateTo" type="date" defaultValue={filterData.dateTo} min="2022-07-01" max={new Date().toISOString().split("T")[0]}
                        onChange={ev => {
                            var date = new Date(ev.target.value).toISOString();
                            setFilterData({ ...filterData, dateTo: date, startIndex: 0, isInitial: false });
                            //filterData.dateTo = date;
                            // onChangeInput();
                        }} />
                </div>

                <div className="admin-filter-item admin-filter-item-action">
                    <Label for="adminActionSearch">Действие</Label>
                    <Input id="adminActionSearch" type="text"
                        onChange={(ev) =>
                            setFilterData({ ...filterData, action: ev.target.value, startIndex: 0, isInitial: false })} />
                </div>

                <div className="admin-filter-item admin-filter-item-user">
                    <Label for="adminActionSearch">Пользователь</Label>
                    <Input id="adminActionSearch" type="text"
                        onChange={(ev) =>
                            setFilterData({ ...filterData, user: ev.target.value, startIndex: 0, isInitial: false })} />
                </div>

                <div className="admin-filter-item admin-filter-item-hide-admin">
                    <Label for="adminActionSearch">Скрыть админа</Label>
                    <Input id="adminActionSearch" type="checkbox"
                        onChange={(ev) =>
                            setFilterData({ ...filterData, hideAdmin: ev.target.checked, startIndex: 0, isInitial: false })} />
                </div>
                <div className="admin-filter-item admin-filter-item-hide-empty">
                    <Label for="adminActionSearch">Скрыть пустых пользователей</Label>
                    <Input id="adminActionSearch" type="checkbox"
                        onChange={(ev) =>
                            setFilterData({ ...filterData, hideEmpty: ev.target.checked, startIndex: 0, isInitial: false })} />
                </div>
                <div className="admin-filter-item admin-filter-delete-logs">
                    <div>Удалить логи</div>
                    <Button id="adminDeleteLogs" type="delete" action={cleanLogs} guid={null } />
                </div>
            </div>
            <table className="admin-table">
                <thead>
                    <tr key="logsTable">
                        <th style={{ "minWidth": "150px" }}>Дата</th>
                        <th>Пользователь</th>
                        <th>Роли</th>
                        <th>Ip</th>
                        <th>Результат</th>
                        {/*<th>Исключение</th>*/}
                        <th>Действие</th>
                        <th>Сообщение</th>
                    </tr>
                </thead>
                <tbody>
                    {logs.map((line: any) =>
                        <tr key={line.guid}>
                            <td>{line.timeStamp}</td>
                            <td>{line.user}</td>
                            <td>{line.userRoles}</td>
                            <td>{line.ip}</td>
                            <td>{line.level}</td>
                            {/*  <td>{line.exception}</td>*/}
                            <td>{line.action}</td>
                            <td>{line.message}</td>
                        </tr>
                    )}
                </tbody>
            </table>

            {/* <Paging currentPage={ } totalCount={logsTotalCount} action={getLogs}  />*/}
            <Paging totalCount={logsTotalCount} action={getLogs} currentPage={logsCurrentPage} linesPerPage={ 30}
                dateFrom={filterData.dateFrom} dateTo={filterData.dateTo} actionName={filterData.action} filterData={filterData} />
        </div>
    );
};

export default Logs;