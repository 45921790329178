import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import DropDown from '../../../../components/dropDown';
import isEmpty from '../../../../functions/functions';
import { getGroupUsers, getUserGroups, loadedGroups, loadEditGroup, unsetEditGroup } from '../../../user/userSlice';
import { loadEditingProject, setEditProject } from '../../projectsSlice';

const EditProjectGroup = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const groups = useSelector(loadedGroups);
    const group = useSelector(loadEditGroup);
    var editProject = useSelector(loadEditingProject);
    var [selected, setSelected] = useState({}); 

    useEffect(() => {
        dispatch(getUserGroups());
    }, []);

    const loadGroupUsers = async (item) => {
        var groupGuid = item.key;
        if (groupGuid) {
            await dispatch(getGroupUsers(groupGuid));
        }
        else {
            await dispatch(unsetEditGroup(null));
        }
        if (groupGuid != editProject.groupGuid) {
            dispatch(setEditProject({ ...editProject, groupGuid: groupGuid }));
        }
    }

    useEffect(() => {
        var item = {};
        if (editProject.groupGuid) {
            var find = groups.find(x => x.guid == editProject.groupGuid);
            if (find) {
                item = ({ key: find.guid, value: find.name });
                setSelected(item);
              //  loadGroupUsers(item);
            }
            else {
                dispatch(getUserGroups());
            }
        }
        loadGroupUsers({ key: editProject.groupGuid });
    }, [editProject, groups]);

    return (<>
        <div className="subheader">
            {t('projects.group.projectGroup')}
        </div>
        <DropDown items={groups.map(x => ({ key: x.guid, value: x.name }))} defaultValue={{ key: null, value: "Нет" }}
            action={loadGroupUsers} selected={!isEmpty(selected) ? selected: null }
        />

        {
            group && group.users ?
                <>
                    <div className="subheader">
                        {t('projects.group.projectParticipants')}
                    </div>
                    {group.users.map(x => <div>{x.name}</div>)}
                </>
                : ""
        }
    </>);
}

export default EditProjectGroup;