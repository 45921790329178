import * as React from 'react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { LineChart, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Line } from 'recharts';
import { formatDateToShortDate, randomColor } from '../../../functions/functions';
import { loadData } from '../weightSlice';

const WeightChart = () => {
    const loadedData = useSelector(loadData);
    const [data, setData] = useState(new Array());
    const [headers, setHeaders] = useState(new Array());
    useEffect(() => {
        if (loadedData && loadedData.data) {
            if (headers && (loadedData.headers.some(x => headers.findIndex(h => h.Name == x.name) == -1)
                || headers.some(x => loadedData.headers.findIndex(h => h.name == x.Name) == -1))) {
                setHeaders(loadedData.headers.map(x => ({ Name: x.name, Color: randomColor() })));
            }
            var newData = new Array();
            for (var i = 0; i < loadedData.data.length; i++) {
                var newObj = { Date: formatDateToShortDate(loadedData.data[i].date) };
                for (var j = 0; j < loadedData.headers.length; j++) {
                    var header = loadedData.headers[j];
                    var cell = loadedData.data[i].columns.find(x => x.guid == header.guid);
                    if (cell && cell.value != 0) {
                        newObj[header.name] = cell.value;
                    }
                }
                newData.push(newObj);
            }
            setData(newData);
        }
    }, [loadedData]);
    const isMobile = window.screen.width <= 500
    return (<>
        <LineChart className="weight-chart" width={isMobile ? 300 : 400} height={isMobile ? 300 : 400} data={data}>
            {headers.map(x =>
                (<Line key={x.Name} type="monotone" dataKey={x.Name} stroke={x.Color} connectNulls />)
            )}
            <XAxis dataKey="Date" stroke="rgb(27,145,177)" angle={-45} style={{ fontSize: 12 }}  />
            <YAxis type="number" stroke="rgb(27,145,177)" domain={['dataMin-0', 'dataMax+0']} />
            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
            <Tooltip />
            <Legend verticalAlign="top" />
        </LineChart>
    </>);
}

export default WeightChart;