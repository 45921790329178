import * as React from 'react';
import { useTranslation } from 'react-i18next';

const Cookie = () => {
    const { t } = useTranslation();
    return (<>
        <div className="cookie-info">{t('cookies')}</div>
    </>);
}

export default Cookie;