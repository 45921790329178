import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { setMessage } from '../../common/commonSlice';
import { getUserGroups, unsetEditGroup } from '../../user/userSlice';
import { getProject, isOpenEditProject, loadEditingProject, loadProject, saveProject, setEditProject, setNeedReload, toggleEditProject } from '../projectsSlice';
import EditProjectGroup from './edit/editProjectGroup';
import EditProjectMain from './edit/editProjectMain';
import ButtonReactstrap from '../../../components/buttonReactstrap';

export default function EditProject() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    var isOpen = useSelector(isOpenEditProject);
    var project = useSelector(loadProject);
    var editProject = useSelector(loadEditingProject);
    const [activeTab, setActiveTab] = useState(1);
    //var [isCopy, setCopy] = useState(project?.isCopyTasksWhenMovingInto);

    useEffect(() => {
        if (isOpen) {
            if (project?.guid != null) {
                dispatch(getProject(project.guid));
            }
        }
        else {
            setActiveTab(1);
        }
    }, [isOpen]);

    useEffect(() => {
        if (project) {
            dispatch(setEditProject({ guid: project.guid, name: project.name, label: project.labelGuid, groupGuid: project.groupGuid }));
        }
        else {
            dispatch(setEditProject({ }));
        }
    }, [project]);

    const saveEditProject = async () => {
        if (!editProject || !editProject.name) {
            dispatch(setMessage({ message: t('projects.warningEmpty'), status: 2, isVisible: true }));
            return;
        }
        await dispatch(saveProject(editProject));
        await dispatch(toggleEditProject(null));
        await dispatch(unsetEditGroup(null));
        await dispatch(setEditProject({}));
        if (editProject.label.key != project.labelGuid) {
            dispatch(setNeedReload(null));
        }

    }

    //const toggleCopySetting = () => {
    //    setCopy(!isCopy);
    //}

    return (
        <div>
            <Modal autoFocus={false}
                centered
                toggle={() => dispatch(toggleEditProject(null))}
                backdrop="static"
                isOpen={isOpen}
            >
                <div className="white-background">
                    <ModalHeader toggle={function noRefCheck() { }}>
                        {project == null || project.guid == null ? t('projects.creatingProject') : t('projects.editingProject')}
                    </ModalHeader>
                    <ModalBody>
                        <div className="project-settings-panel">
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        className={activeTab == 1 ? "active" : ""}
                                        onClick={() => setActiveTab(1)}
                                    >
                                        {t('projects.mainEdit') }
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={activeTab == 2 ? "active" : ""}
                                        onClick={() => setActiveTab(2)}
                                    >
                                        {t('projects.groupEdit')}
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId={1}>
                                    <Row>
                                        <Col sm="12">
                                            <div className="edit-project-modal">
                                                <EditProjectMain  />
                                            </div>
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId={2}>
                                    <Row>
                                        <Col sm="12">
                                            <div className="edit-project-modal">
                                                <EditProjectGroup />
                                            </div>
                                        </Col>
                                    </Row>
                                </TabPane>
                            </TabContent>
                        </div>

                    </ModalBody>
                    <ModalFooter>
                        <ButtonReactstrap
                            color="primary"
                            onClick={saveEditProject}
                            value={t('controls.modal.save')}
                        />                  
                        {' '}
                        <Button onClick={() => dispatch(toggleEditProject(null))}>
                            {t('controls.modal.cancel')}
                        </Button>
                    </ModalFooter>
                </div>
            </Modal>
        </div>);
};