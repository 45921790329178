import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DeleteButton from '../../components/actionButtons/DeleteButton';
import { getUsers, deleteUser, loginAsUser, usersList } from './adminSlice';
import LoginAsUserButton from './UserPage/LoginAsUserButton';

const Users = (props) => {
    const dispatch = useDispatch();
    const users = useSelector(usersList);

    useEffect(() => {
        dispatch(getUsers());
    }, []);

    return (
        <table className="admin-table">
            <thead>
                <tr key="usersTable">
                    <th></th>
                    <th>Email</th>
                    <th>Дата регистрации</th>
                    <th>Аккаунт подтвержден?</th>
                    <th>Дата последнего входа</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {users.map((user: any, index) =>
                    <tr key={user.id}>
                        <td>{ index}</td>
                        <td>{user.email}</td>
                        <td>{user.registerDate}</td>
                        <td>{user.isEmailConfirmed ? "Да" : <span className="red-text">Нет</span>}</td>
                        <td>{user.lastLoginDate}</td>
                        <td>
                            <DeleteButton guid={user.id} target="User" action={deleteUser} />
                            <LoginAsUserButton guid={user.id} target="User" action={loginAsUser} />
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    );
};

export default Users;