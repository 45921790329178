import * as React from 'react';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CalendarSwitcher from './calendarSwitcher';
import Month from './month';
import { needUpdate, getDays, changeMode, toggleIsLoadding, loadCalendar, saveEvent, toggleEditEvent, loadPatterns, getPatterns, loadSelectedET, toggleEventDescription } from './calendarSlice';
import { getProjects, loadProjects } from '../project/projectsSlice';
import Week from './week';
import Days from './days';
import CalendarBackForward from './calendarBackForward';
import Projects from './projects';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import isEmpty, { formatDate, formatDateFromShortDate } from '../../functions/functions';
import Button from '../../components/button';
import EditEvent from './modal/editEventModal';
import EventDescription from './eventDescription';
import EventTaskSwitcher from './eventTaskSwitcher';
import DeleteVariantsModal from './modal/deleteVariantsModal';
import { useTranslation } from 'react-i18next';
import CalendarTransition from './calendarTransition';

const Calendar = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const calendar = useSelector(loadCalendar);
    const projects = useSelector(loadProjects);
    const isNeedUpdate = useSelector(needUpdate);
    const selectedET = useSelector(loadSelectedET);
    if (props.mode) {
        dispatch(changeMode(props.mode));
    }
    useEffect(() => {
        dispatch(getPatterns());
    }, []);

    const mode = props.mode;

    useEffect(() => {
        if (isNeedUpdate) {
            loadDays();
        }
    }, [isNeedUpdate]);

    const loadDays = async () => {
        dispatch(toggleIsLoadding(true));
        await dispatch(getDays({ mode: mode, step: calendar.step }));
        await dispatch(getProjects(null));
        dispatch(toggleIsLoadding(false));
    }

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        var date = formatDateFromShortDate(result.destination.droppableId);
        var event = {
            guid: null, name: "", date: date, taskGuid: result.draggableId, pattern: null
        };
        console.log("onDragEnd");
        dispatch(saveEvent(event));
    };
    const isMobile = window.screen.width <= 500

    return (
        <>
            <CalendarSwitcher />
            <div className="calendar-action-buttons">
                <Button type="add" className="small-button calendar-add-button"
                    params={{ date: formatDate(new Date()) }}
                    action={toggleEditEvent} help={ t('calendar.addEvent')} />
                <CalendarBackForward />
                <CalendarTransition mode={mode} />
                <EventTaskSwitcher />
            </div>
            {calendar.mode == mode &&
                <div className="calendar-panel">
                    <DragDropContext onDragEnd={onDragEnd} key={"calendar-dragdrop-context"}>
                        {
                            mode == 1 && <Month calendar={calendar} isMobile={isMobile} /> ||
                            mode == 2 && <Week calendar={calendar} isMobile={isMobile} /> ||
                            mode == 3 && <Days calendar={calendar} />
                        }
                        {!isMobile && <Projects projects={projects} />}
                    </DragDropContext>
                    <EditEvent />
                </div>
            }
            <EventDescription />
            <DeleteVariantsModal />
        </>
    )
}

export default Calendar;