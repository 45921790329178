import * as React from 'react';
import Project from '../project/components/Project';

const Projects = (props) => {
    var projectsList = props.projects;

    return (<>
        <div className="calendar-block">
            <div id="projectList" className="project-list">
                <ul className="project-sortable">
                    {
                        projectsList.map((project: any) =>
                            <Project project={project} key={project.guid} />
                        )
                    }
                </ul>
            </div>
        </div>
    </>);
}

export default Projects;