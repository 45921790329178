import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Switcher from '../../components/switcher';
import { loadSelectedET, toggleSelectedET } from './calendarSlice';

const EventTaskSwitcher = () => {
    const { t } = useTranslation();
    const items = [
        { Name: t('calendar.taskSwitcher.all'), Action: toggleSelectedET("all") },
        { Name: t('calendar.taskSwitcher.events'), Action: toggleSelectedET("events") },
        { Name: t('calendar.taskSwitcher.tasks'), Action: toggleSelectedET("tasks") }];
    return (<>
        <div className="event-task-switcher">
            <Switcher items={items} />
        </div>
    </>);
}

export default EventTaskSwitcher;