import { createSlice } from "@reduxjs/toolkit";
import { debug } from "console";

//export const userLogout = ()=> {
//        const data = fetch('/Help/Load',
//            {
//                method: "GET",
//                headers: {
//                    'Content-Type': 'application/json;charset=utf-8',
//                    'Accept': 'application/json',
//                },
//            });
//    };

export const helpSlice = createSlice({
    name: "help",
    initialState: {
    },
    reducers: {
        helpLoad: (state, action) => {
            const data = fetch('/HelpPage/Load',
                {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8',
                        'Accept': 'application/json',
                    },
                });
        },
    },
});

export const { helpLoad } = helpSlice.actions

export default helpSlice.reducer;