import * as React from 'react';
import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toggleEditArticle, isArticleEditOpen, loadArticle, getArticle, saveArticle } from '../adminSlice';

export default function EditArticle(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isOpen = useSelector(isArticleEditOpen);
    var article = useSelector(loadArticle);
    const [Article, setArticle] = useState(article);
    useEffect(() => {
        if (isOpen) {
            if (article.guid != null) {
                dispatch(getArticle(article.guid));
                setArticle(article);
            }
        }
    }, [isOpen]);


    useEffect(() => {
        setArticle(article);
    }, [article]);

    const saveEditArticle = (close: boolean) => {
        dispatch(saveArticle(Article));
        if (close) {
            dispatch(toggleEditArticle(null));
        }
    }

    return (
        <div>
            <Modal autoFocus={false}
                centered
                isOpen={isOpen}
                toggle={() => dispatch(toggleEditArticle(null))}
                className="article-edit-modal"
                backdrop="static"
            >
                <div className="white-background">
                    <ModalHeader toggle={function noRefCheck() { }}>
                        Редактирование статьи
                    </ModalHeader>
                    <ModalBody>
                        <div>
                            <Input autoFocus={true}
                                id={"articleSaveName"}
                                placeholder="Название"
                                onChange={(event) => setArticle({ ...Article, name: event.target.value })}
                                defaultValue={article != null ? article.name : ""} />
                            <Input autoFocus={true}
                                id={"articleSavePathName"}
                                className="article-save-pathName"
                                placeholder="Путь"
                                onChange={(event) => setArticle({ ...Article, pathName: event.target.value })}
                                defaultValue={article != null ? article.pathName : ""} />
                            <Input autoFocus={true}
                                id={"articleSaveContent"}
                                className="article-save-content"
                                type="textarea"
                                placeholder="Текст статьи"
                                onChange={(event) => setArticle({ ...Article, content: event.target.value })}
                                defaultValue={article != null ? article.content : ""} />

                            <div className="article-preview-content" dangerouslySetInnerHTML={{ __html: Article.content }}>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            onClick={() => saveEditArticle(false)}>
                            {t('controls.modal.save')}
                        </Button>
                        <Button
                            color="primary"
                            onClick={() => saveEditArticle(true)}>
                            {t('controls.modal.saveandclose')}
                        </Button>
                        {' '}
                        <Button onClick={() => dispatch(toggleEditArticle(null))}>
                            {t('controls.modal.cancel')}
                        </Button>
                    </ModalFooter>
                </div>
            </Modal>
        </div>);
}