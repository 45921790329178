import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Draggable, Droppable } from "react-beautiful-dnd";
import { getDayEvents, loadCalendar } from '../calendar/calendarSlice';
import CalendarTask from './components/CalendarTask';
import EditTask from './components/editTask';

const CalendarTasks = (props) => {
    const dispatch = useDispatch();
    var calendar = useSelector(loadCalendar);

    //const [events, setEvents] = useState([] as any[]);
    //useEffect(() => {
    //    if (props.date) {
    //        dispatch(getDayEvents(props.date));
    //    }
    //}, []);


    var events = calendar.days.length >= 0 && calendar.days.find(t => t.date == props.date) ?
        calendar.days.find(t => t.date == props.date).events : null;
    //var events = useSelector(loadDayEvents);

    //useEffect(() => {
    //    var day = calendar.days.find(x => x.date == props.date);
    //    //if (day) {
    //    if (levents.date == props.date) {
    //        debugger;
    //        //setEvents(day && day.events?.length >= 0 ? day.events : null);
    //        setEvents(levents.events);
    //    }
    //}, [levents]);
    //console.log(events);
    return (
        <div className="project-tasks-list">
            <EditTask task={{ date: props.date }} isCalendar={true} />
            <div id={props.date} className="project-task-list">
                <div>
                    <ul key={"ul_" + props.date} id={"ul_" + props.date} className="task-list task-sortable">
                        <Droppable droppableId={props.date} >
                            {(provided, snapshot) => (
                                <div className="droppable-tasks"
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {events && events.length>0 && events.map((event, index) => (
                                        <Draggable key={event.guid} draggableId={event.guid} index={index} >
                                            {(provided, snapshot) => (
                                                <div className="draggable-free"
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                        <CalendarTask key={"event_" + event.guid} event={event} />
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}               
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default CalendarTasks;