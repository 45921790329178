import * as React from 'react';
import Button from '../../../components/button';

const LoginAsUserButton = (props) => {
    return (
        <>
            <Button guid={props.guid} target={props.target} action={props.action} help="Вход под пользователем" type="userlogin" />
        </>
    );
}

export default LoginAsUserButton;