import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { replaceSvg } from '../js/controls';
import Icon from './icon';

export default function Checkbox(props) {
    const dispatch = useDispatch();
    const [name, setName] = useState("");
    const [checked, toggleChecked] = useState(props.isChecked);
    const uncheckedImage = "/images/not checked checkbox.svg";
    const checkedImage = "/images/checked checkbox.svg";

    const nameChecked = "checkbox-checked";
    const nameUnchecked = "checkbox-unchecked";
    const [image, setImage] = useState(props.isChecked ? checkedImage : uncheckedImage);

    const id = "checkbox_" + props.guid;


    useEffect(() => {
        setImage(props.isChecked ? checkedImage : uncheckedImage);
        toggleChecked(props.isChecked);
    },[props.isChecked]);


    const toggle = () => {
        toggleChecked(!checked);
        setImage(checked ? uncheckedImage : checkedImage);
        setName(checked ? nameUnchecked : nameChecked);
        replaceSvg(id, checked ? uncheckedImage : checkedImage);
        if (props.action) {
            if (props.isTask) {
                dispatch(props.action({ guid: props.guid, status: checked ? 1 : 4, parentGuid: props.parentGuid }));
            } else {
                dispatch(props.action(props.params ?? {}));
            }

        }
    };
    useEffect(() => {
        setTimeout(
            () => setName(""),
            500
        );
    },[checked]);

    return (
        <div id={id} className={"animated-checkbox " + name} onClick={ toggle } key={ props.guid }>
            <Icon image={image} guid={props.guid} imageName="checkbox"  />
        </div>
    );
}