import * as React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Badge } from 'reactstrap';
import { loadUnreadLetters } from '../../features/letter/lettersSlice';
import Icon from '../icon';
import Tooltip from '../toolTip';

const LettersButton = (props) => {
    const notReadLetters = useSelector(loadUnreadLetters);
    const buttonType = props.type;
    const id = buttonType;
    const help = props.help;
    const position = props.position ? props.position : "left";
    const location = useLocation();
    const path = location.pathname.substring(1).toLowerCase().startsWith(props.type.toLowerCase());
    var className = props.className + " " + position + "-button big-button " + (path ? "active-button " : " ");
    return (
        <>
            <button id={id} className={className}>
                <Icon image={"/images/" + buttonType + ".svg"} imageName={buttonType} />
                {notReadLetters > 0 &&
                    <Badge className="letters-badge">{notReadLetters}</Badge>
                }
            </button>
            <Tooltip id={id} text={help} />
        </>
    );
}

export default LettersButton;