import * as React from 'react';
import { useLocation } from 'react-router-dom';
import NavMenuButton from './navMenuButton';

const ProjectButton = (props) => {
    const location = useLocation();
    const path = location.pathname == "/";
    //return (<>
    //    {!path &&
    //        <NavMenuButton id="projectsButton" help="Проекты" type="projects" />
    //    }
    //</>);
    return (<>
        <NavMenuButton id="projectsButton" help={props.help} type="projects" className={path? "active-button" : ""} />
    </>);
}

export default ProjectButton;