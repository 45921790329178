import * as React from 'react';
import { useEffect } from 'react';
import { convertSVGT } from '../js/controls';

export default function Icon(props) {
    const id = "icon_" + props.imageName + "_" + props.guid;

    return (
        //<img id={id} src={props.image + "?" + new Date().getTime()} className="svg" />
         <img id={id} src={props.image + "?v=1"} className="svg" />
        //<img id={id} src={props.image} className="svg" alt={props.alt ? props.alt : ""} />
        );
}